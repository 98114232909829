
export default {
  name: 'SummaryComponent',
  props: {
    isCheckout: { type: Boolean, default: false },
    isUseBudget: { type: Boolean, default: false },
    summary: { type: Object, default: Object },
    checkoutText: { type: String, default: String },
    budget: { type: Object, default: Object },
    isEprocuerment: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isAlertCreditCardPromotionOnTop: { type: Boolean, default: false },
    isPrinting: { type: Boolean, default: false },
  },
  watch: {
    summary: {
      handler: function (val) {
        if (Object.keys(val).length) this.getHeight()
      },
      immediate: true,
    },
  },
  mounted() {
    const _this = this
    window.addEventListener('resize', function () {
      _this.getHeight()
    })
  },
  computed: {
    alertVoucher() {
      if (Object.keys(this.summary).length) {
        return this.$utils.isValid(this.summary.voucher_no) &&
          this.summary.is_use_coupon == false
          ? true
          : false
      } else return false
    },
    isPaymentDiscAmtExcvat() {
      return (
        this.$utils.isObjectValid(this.summary) &&
        this.$utils.isValid(this.summary.payment_disc_amt_excvat) &&
        (this.summary.payment_disc_amt_excvat > 0 ||
          this.isAlertCreditCardPromotionOnTop)
      )
    },
    summaryPaymentDiscAmtExcvat() {
      return this.isPaymentDiscAmtExcvat &&
        this.summary.payment_disc_amt_excvat > 0
        ? this.summary.payment_disc_amt_excvat * -1
        : 0
    },
  },
  methods: {
    getHeight() {
      setTimeout(() => {
        console.log('getHeight')
        let height = this.$refs['summary-checkout']
          ? this.$refs['summary-checkout'].clientHeight
          : 0
        if (height > 0) this.$emit('collapseSummary', height)
      }, 50)
    },
  },
}
