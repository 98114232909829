
import { mapGetters } from 'vuex'
import { TriggerInsightsAlgoliaEnum } from '@/enum/event-insights-algolia.enum.js'
export default {
  name: 'FilterPanel',
  props: {
    // defaultValue: {
    //   type: Array,
    //   default: () => {
    //     return []
    //   },
    // },
    condition: { type: Array, default: Array },
  },
  data() {
    return {
      // range: [],
      select: [],
      attribute: [],
      filter: { attribute: [], price: [] },
      min: '',
      max: '',
      defaultMin: '',
      defaultMax: '',
      algolia: {
        attributeFilters: [],
      },
      paramsFilter: [],
      reload: false,
      sort: '',
      // brandLength: 9,
    }
  },
  watch: {
    condition: {
      immediate: true,
      handler: function (val) {
        if (val.length) {
          console.log('aside-condition', val)
          this.attribute = Object.assign([], [...new Set(val)])
          this.onFilterByUrl()
        }
      },
      // deep: true,
    },
    select: {
      handler: function (val) {
        if (this.$utils.anyArrayObjectValid(val)) {
          let newFilter = val.find(
            (q) => !this.algolia.attributeFilters.includes(`${q.by}:${q.value}`)
          )
          if (this.$utils.isObjectValid(newFilter)) {
            let valNewFilter = `${newFilter.by}:${newFilter.value}`
            this.algolia.attributeFilters.push(valNewFilter)

            this.$eventInsightsAlgolia.sendEvent(
              TriggerInsightsAlgoliaEnum.CLICKFILTERS,
              this.isMyGuest ? this.guestId : this.openId,
              '',
              [valNewFilter]
            )
          }
        }
        // this.updateParam()
        this.filter.attribute = val
        setTimeout(() => {
          this.applyFilter()
          console.log('applyFilterSelect')
        }, 100)
      },
      deep: true,
    },
    // attribute: {
    //   handler: function (val) {
    //     if (val.length) {
    //       this.onFilterByUrl()
    //     }
    //   },
    //   deep: false,
    // },

    reload: {
      handler: function (val) {
        if (val) {
          this.onFilterByUrl()
        }
      },
      deep: false,
    },
    min: {
      immediate: true,
      handler: function (val) {
        this.filter.price = [Number(val), Number(this.max)]
      },
    },
    max: {
      immediate: true,
      handler: function (val) {
        this.filter.price = [Number(this.min), Number(val)]
      },
    },
  },
  computed: {
    ...mapGetters('authen', [
      'openId',
      'isAuthenticated',
      'isGuest',
      'guestId',
    ]),
    isMyGuest() {
      return this.isAuthenticated && this.isGuest
    },
    // attribute() {
    //   console.log('aside-condition', this.condition)
    //   this.reload = true
    //   return [...new Set(this.condition)]
    // },
  },
  mounted() {
    this.lang = this.$store.state.locale
    // Object.assign([], [...new Set(this.condition)])
    console.log('flashsale-attribute', this.attribute)
  },
  created() {
    // if (this.defaultValue) this.range = this.defaultValue
    // this.reload = false
    this.$bus.$on('onResetFilter', () => {
      this.select = []
      this.min = ''
      this.max = ''
    })
  },
  methods: {
    onHidePanel() {
      document.getElementsByTagName('body')[0].classList.remove('filter-panel')
    },
    clearSelected(target) {
      console.log('clear', this.select, target)
      if (
        this.$utils.isObjectValid(target) &&
        this.$utils.anyArrayObjectValid(target.list) &&
        this.$utils.isObjectValid(target.list[0].value) &&
        this.$utils.isValid(target.list[0].value.by)
      ) {
        console.log('key', target.list[0].value.by)
        this.select = this.select.filter(function (val) {
          return val.by !== target.list[0].value.by
        })
      }
    },
    async applyFilter() {
      await this.updateParam()
      this.$emit('emitAttribute', this.filter)
      console.log('emitAttribute', this.filter)
    },
    emitAttribute() {
      this.$emit('emitAttribute', this.filter)
    },
    updateParam() {
      let params = new URLSearchParams(window.location.search)
      let brands = []
      let category_id = []
      let attributes = []
      let sort = params.get('sort')

      let select = this.select
      // add params
      select.forEach((data) => {
        if (data.by === 'brand') {
          brands.push(data.value)
        } else if (data.by === 'category') {
          category_id.push(data.value)
        } else {
          // attributes.push(data.id + '-' + data.value)
          attributes.push(data.id + '-' + data.index)
        }
        console.log('data select', data)
      })
      brands.length > 0 ? params.set('brands', brands) : params.delete('brands')
      category_id.length > 0
        ? params.set('category_id', category_id)
        : params.delete('category_id')
      attributes.length > 0
        ? params.set('attributes', attributes)
        : params.delete('attributes')
      this.min ? params.set('pricemin', this.min) : params.delete('pricemin')
      this.max ? params.set('pricemax', this.max) : params.delete('pricemax')
      sort ? params.set('sort', sort) : params.delete('sort')

      // update url
      setTimeout(() => {
        this.$router.push({
          path:
            this.$route.fullPath.split('?')[0] +
            `?${new URLSearchParams(
              Object.fromEntries(new URLSearchParams(params))
            ).toString()}`,
        })
        // window.history.pushState(
        //   // window.history.state,
        //   null,
        //   null,
        //   params.toString() ? `?${params.toString()}` : '?'
        // )
      }, 100)
    },
    onFilterByUrl() {
      let url = new URL(window.location.href)
      console.log('get', url.toString())
      this.paramsFilter.brands = url.searchParams
        .getAll('brands')
        .toString()
        .split(',')
      this.paramsFilter.category_id = url.searchParams
        .getAll('category_id')
        .toString()
        .split(',')
      this.paramsFilter.attributes = url.searchParams
        .getAll('attributes')
        .toString()
        .split(',')
      this.paramsFilter.pricemin = url.searchParams.get('pricemin')
      this.paramsFilter.pricemax = url.searchParams.get('pricemax')

      this.min = url.searchParams.get('pricemin')
      this.max = url.searchParams.get('pricemax')
      setTimeout(() => {
        // // let attribute = [...new Set(this.condition)]
        // this.attribute.forEach((att) => {
        //   att.list.filter((a) => {
        //     if (att.title === this.$t('filterPanelComponent.brand')) {
        //       if (
        //         this.paramsFilter.brands.includes(
        //           a.value.value.replace(/\+/g, ' ')
        //         )
        //       )
        //         this.select.push(a.value)
        //     } else if (att.title === this.$t('filterPanelComponent.category')) {
        //       if (
        //         this.paramsFilter.category_id.includes(
        //           a.value.value.replace(/\+/g, ' ')
        //         )
        //       )
        //         this.select.push(a.value)
        //     } else {
        //       if (
        //         this.paramsFilter.attributes.includes(
        //           // a.value.id + '-' + a.value.value.replace(/\+/g, ' ')
        //           a.value.id + '-' + a.value.index
        //         )
        //       )
        //         this.select.push(a.value)
        //     }
        //   })
        // })
        // this.applyFilter()
      }, 500)
      console.log('this.select', this.attribute)
    },
  },
  beforeDestroy() {
    this.$bus.$off('onResetFilter')
  },
}
