import { render, staticRenderFns } from "./404.vue?vue&type=template&id=74f48984"
import script from "./404.vue?vue&type=script&lang=js"
export * from "./404.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainNavbar: require('/usr/src/nuxt-app/components/template/algolia/main-navbar.vue').default,SecondNavbar: require('/usr/src/nuxt-app/components/template/second-navbar.vue').default,SwiperItem: require('/usr/src/nuxt-app/components/share/swiper-item.vue').default,Loading: require('/usr/src/nuxt-app/components/share/loading.vue').default})
