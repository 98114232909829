
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      // cartCount: 0,
    }
  },
  computed: {
    ...mapGetters('authen', ['user', 'isAuthenticated']),
    ...mapGetters('cart', ['cartCount']),
  },
  created() {
    // if (process.client) {
    //   this.$bus.$on('addCart', (num) => {
    //     this.cartCount += num
    //   })
    // }
  },
  beforeDestroy() {
    // this.$bus.$off('addCart')
  },
  methods: {
    ...mapActions('authen', ['signout']),
    signout() {
      this.$store.dispatch('authen/signout')
      // this.$router.go()
      this.$router.push(this.$i18n.path('biz'))
      // this.$router.push('/login')
    },
    onChangeLanguage(language) {
      // console.log('Language', language)
      this.$router.push(
        `${
          language === 'th'
            ? this.$route.fullPath.replace(/^\/[^\\/]+/, '')
            : this.$route.fullPath.includes('/en')
            ? this.$route.fullPath
            : `/en${this.$route.fullPath}`
        }`
      )
    },
    checkoutCart() {
      // console.log(this.cartCount)
      // console.log(this.$i18n)
      // if (this.cartCount !== undefined && this.cartCount !== 0)
      this.$router.push(this.$i18n.path('product/checkout'))
    },
  },
}
