import { render, staticRenderFns } from "./_url.vue?vue&type=template&id=57989f1f"
import script from "./_url.vue?vue&type=script&lang=js"
export * from "./_url.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FilterPanel: require('/usr/src/nuxt-app/components/template/filter-panel.vue').default,FilterController: require('/usr/src/nuxt-app/components/share/filter-controller.vue').default,ProductResult: require('/usr/src/nuxt-app/components/skeleton/product-result.vue').default,ProductPagination: require('/usr/src/nuxt-app/components/template/product-pagination.vue').default,SwiperItem: require('/usr/src/nuxt-app/components/share/swiper-item.vue').default})
