
export default {
  data() {
    return {
      modalShow: false,
      exceptList: ['duplicate user'],
      arrError: [],
      currentPage: 1,
      perPage: 1,
    }
  },
  created() {
    if (process.client) {
      this.$bus.$on('responseFailed', (error) => {
        if (!this.exceptList.includes(error.data.message)) {
          this.modalShow = true
          this.arrError.push({
            message: error.data.message[this.$i18n.locale],
            code: error.data.code || '500',
            trace_identifier_id: error.data.trace_identifier_id || '-',
          })
        }
      })
    }
    // if (process.client) {
    //   this.$bus.$on('responseFailed', (error) => {
    //     if (!this.exceptList.includes(error.data.message)) {
    //       this.modalShow = true
    //       const errorMessages =
    //         error.data.error_info[`message_${this.$i18n.locale}`]
    //       this.arrError.push({
    //         message: errorMessages,
    //         code: error.data.error_code || '500',
    //         trace_identifier_id: error.data.trace_id || '-',
    //       })
    //     }
    //   })
    // }
  },
  methods: {
    onClose() {
      this.modalShow = false
      setTimeout(() => {
        this.arrError = []
      }, 500)
    },
  },
  beforeDestroy() {
    if (process.client) {
      this.$bus.$off('responseFailed')
      this.arrError = []
    }
  },
}
