import { render, staticRenderFns } from "./newpassword.vue?vue&type=template&id=16ba86ae"
import script from "./newpassword.vue?vue&type=script&lang=js"
export * from "./newpassword.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputText: require('/usr/src/nuxt-app/components/share/input-text.vue').default,Consent: require('/usr/src/nuxt-app/components/share/consent.vue').default})
