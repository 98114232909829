import { render, staticRenderFns } from "./_url.vue?vue&type=template&id=1ef28266"
import script from "./_url.vue?vue&type=script&lang=js"
export * from "./_url.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notfound: require('/usr/src/nuxt-app/components/share/notfound.vue').default})
