import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0cdddb36"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=0cdddb36&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartItems: require('/usr/src/nuxt-app/components/cart/cart-items.vue').default,InputText: require('/usr/src/nuxt-app/components/share/input-text.vue').default,SearchSelect: require('/usr/src/nuxt-app/components/share/search-select.vue').default,SwiperItem: require('/usr/src/nuxt-app/components/share/swiper-item.vue').default})
