
const Cookie = process.client ? require('js-cookie') : undefined

import SliderBanners from '@/components/share/slider-banners.vue'
import TabProductCategories from '@/components/share/tab-product-categories.vue'
import TabProductBrand from '@/components/share/tab-product-brand.vue'
import CardProductSelect from '@/components/share/card-product-select.vue'
import BlogItemsComponent from '@/components/share/blog-items.vue'
import SwiperItem from '@/components/share/swiper-item.vue'
import SwiperImage from '@/components/share/swiper-image.vue'
import SwiperItemSkeleton from '@/components/skeleton/swiper-skeleton-item.vue'
import countdown from '@/components/flashsale/countdown.vue'
import { mapGetters } from 'vuex'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'

export default {
  layout: 'main',
  // middleware: ['authentication'],
  components: {
    SliderBanners,
    'card-product-select': CardProductSelect,
    TabProductCategories,
    TabProductBrand,
    'swiper-item': SwiperItem,
    'swiper-image': SwiperImage,
    'blog-items': BlogItemsComponent,
    SwiperItemSkeleton,
    countdown,
    // popupImage: async () => await import('@/components/modal/popupImage.vue'),
    'instory-banner-component': async () =>
      await import('@/components/share/instory-banner.vue'),
    'special-deal': async () =>
      await import('@/components/share/special-deal.vue'),
    'strip-lo': async () => await import('@/components/share/strip-lo.vue'),
  },
  // middleware(ctx) {
  //   ctx.$gtm.push({
  //     event: 'pageInfo',
  //     page: {
  //       type: 'home',
  //       path: ctx.route.path,
  //       title: ctx.app.head.title,
  //     },
  //     session: {
  //       isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
  //     },
  //     user: ctx.store.getters['user/userInfoTrack'],
  //   })
  // },
  // head() {
  //   return {
  //     meta: [
  //       {
  //         hid: 'description',
  //         name: 'description',
  //         content: this.$t('meta.description'),
  //       },
  //     ],
  //   }
  // },
  data() {
    return {
      imageTemp:
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
      imageBannerTemp:
        'https://ofm-cdn0.ofm.co.th/images/default-brand-logo.png',
      images: [],
      banner_sub1: {
        src: '',
        url: '',
        title: '',
      },
      banner_sub2: {
        src: '',
        url: '',
        title: '',
      },
      // images: [
      //   {
      //     alt: 1,
      //     url: '',
      //     path: '/images/banners/main_1.png',
      //     asset: true,
      //   },
      // ],
      itemsRecommended: [],
      promotionBanner: [],
      shopByStore: [],
      brandItems: [],
      privilegeItems: [],
      itemsNew: [],
      brandSelected: {
        index: 0,
        data: {},
      },
      footer1: {
        src: '',
        title: '',
      },
      footer2: [],
      highlight: [],
      special: [],
      brandHomeImage: [],
      activeBrand: '',
      activeDeptId: '',
      items: [],
      categoryitems: [],
      categories: [],
      feeds: [],
      itemsWidget: [],
      // itemsArrivals: [],
      // itemsFeatured: [],
      // itemsBestSeller: [],
      brandloading: false,
      temp_categories: [],
      // isLoadWidget: false,
      isLoadCategories: false,
      categoriesProductHome: [],
      isLoadItemsRecommended: false,
      isLoadBrandItems: false,
      isLoadBanner_2: false,
      isLoadBanner_3: false,
      isLoadBanner_4: false,
      isLoadBlog: false,
      stateLoad: {
        widget: false,
        categories: false,
        itemsFlashSale: false,
        itemsRecommended: false,
        brandItems: false,
        banner_2: false,
        banner_3: false,
        banner_4: false,
        blog: false,
        itemsSpecialDeal: false,
      },
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      skeletonPage: true,
      shiftHeight: 0,
      itemsFlashSale: [],
      isLoadItemsFlashSale: false,
      flashSaleEndDate: '',
      isFlashSale: false,
      currentDate: '',
      // loading
      isLoadingWidget: true,
      isLoadingFlashsale: true,
      isLoadingPromotionBanner: true,
      // replace insider
      isPopupImage: false,
      mallBanner: [],
      strip_lo_1: {},
      strip_lo_2: {},
      strip_lo_3: {},
      strip_lo_4: {},
      isSkeletonBanner: true,
      isSkeletonInstory: true,
      instoryBanner: [],
      popupImageObj: {},
      isExistingPopup: 'disabled',
      isLoadingSpecialDeal: true,
      itemsSpecialDeal: [],
      isLoadStripLo_2: true,
      isLoadStripLo_3: true,
      isLoadStripLo_4: true,
      bannerSpecialDeal: [],
      urlParameter: '',
      updateInfo: '',
      // popup
      isPopupVisible: false,
      popupImageComponent: null,
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    isCategories() {
      return (
        this.$utils.anyArrayObjectValid(this.categories) &&
        this.$utils.anyArrayObjectValid(this.categoriesProductHome)
      )
    },
    isItemsArrivals() {
      // console.log('this.itemsWidget', this.itemsWidget)
      return (
        this.$utils.anyArrayObjectValid(this.itemsWidget) &&
        this.itemsWidget.some((q) => q.type.toLowerCase() === 'newarrivals')
      )
    },
    isItemsFeatured() {
      return (
        this.$utils.anyArrayObjectValid(this.itemsWidget) &&
        this.itemsWidget.some(
          (q) => q.type.toLowerCase() === 'featuredproducts'
        )
      )
    },
    isItemsBestSeller() {
      return (
        this.$utils.anyArrayObjectValid(this.itemsWidget) &&
        this.itemsWidget.some((q) => q.type.toLowerCase() === 'bestseller')
      )
    },
    isItemsWidget() {
      return (
        this.isItemsArrivals || this.isItemsFeatured || this.isItemsBestSeller
      )
    },
    itemsArrivals() {
      return (
        this.$utils.anyArrayObjectValid(this.itemsWidget) &&
        this.itemsWidget.filter((q) => q.type.toLowerCase() === 'newarrivals')
      )
    },
    itemsFeatured() {
      return (
        this.$utils.anyArrayObjectValid(this.itemsWidget) &&
        this.itemsWidget.filter(
          (q) => q.type.toLowerCase() === 'featuredproducts'
        )
      )
    },
    itemsBestSeller() {
      return (
        this.$utils.anyArrayObjectValid(this.itemsWidget) &&
        this.itemsWidget.filter((q) => q.type.toLowerCase() === 'bestseller')
      )
    },
    isImagesBanner() {
      return this.$utils.anyArrayObjectValid(this.images)
    },
    hasFeedBlog() {
      return this.$utils.anyArrayObjectValid(this.feeds)
    },
    hasItemsRecommended() {
      return this.$utils.anyArrayObjectValid(this.itemsRecommended)
    },
    hasBrandItems() {
      return this.$utils.anyArrayObjectValid(this.brandItems)
    },
    hasPromotionBanner() {
      return this.$utils.anyArrayObjectValid(this.promotionBanner)
    },
    isShowBannerPromotion() {
      return (
        this.profileType === 'standard' ||
        (this.profileType === 'eprocurement' &&
          this.userProfile.is_show_banner_promotion)
      )
    },
    hasItemsSpecialDeal() {
      return this.$utils.anyArrayObjectValid(this.itemsSpecialDeal)
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.onBoundingLoad)
  },
  beforeDestroy() {
    this.promotionBanner = []
    window.removeEventListener('scroll', this.onBoundingLoad)
  },
  async created() {
    if (this.isShowBannerPromotion) {
      // if (!this.isLoadWidget) {
      //   this.getWidget()
      //   this.isLoadWidget = false
      // }
      this.showPopup()
      this.getWidget()
      this.getฺBannerHome()
      if (process.client) this.getInstoryHome()
    } else {
      this.defaultImage()
      this.onLoadBrandItems()
      this.isLoadingWidget = false
      this.isSkeletonBanner = false
    }
    // this.shiftHeight = window.innerHeight - 200
  },
  mounted() {
    this.skeletonPage = false
    this.$pageInfo('home')
    // this.$_onGetInsider({
    //   page: {
    //     type: 'Home',
    //     custom: {
    //       category_name: null,
    //     },
    //   },
    // })
    if (this.$braze) {
      // const userId = 'unique_user_id' // Replace with your unique user ID
      // const attributes = {
      //   firstName: 'John',
      //   lastName: 'Doe',
      //   email: 'john.doe@example.com',
      //   gender: 'm',
      //   country: 'USA',
      //   dateOfBirth: [1990, 7, 15], // year, month (1-12), day
      //   customAttributes: {
      //     custom_attribute_key: 'custom_attribute_value',
      //   },
      // }
      // this.$setBrazeUser(userId, attributes)
      this.$braze.logCustomEvent('Visited Homepage')
      // this.$brazeCheckNotificationPermission()
    }
  },
  updated() {
    // this.skeletonPage = false
    // remove listing
    // this.$_onInsiderResult({
    //   lists: [
    //     ...(this.itemsWidget || []),
    //     ...(this.items || []),
    //     ...(this.itemsRecommended || []),
    //   ],
    // })
  },
  watch: {
    skeletonPage: {
      handler: function (val) {
        if (!val) {
          this.$nextTick(() => {
            window.scroll({
              // behavior: 'smooth',
              top: 0,
            })
            this.onBoundingLoad()
          })
        }
      },
      deep: true,
    },
    strip_lo_1: {
      handler: function (val) {
        console.log('strip_lo_1', val)
        if (val && process.client)
          setTimeout(() => {
            this.$nuxt.$emit('loadingBanner', {
              src: val.image.url,
              url: val.path_url,
              title: val.title_tag?.[this.$i18n.locale] || val.title,
              alt: val.alt_tag?.[this.$i18n.locale] || val.title,
              gtmSeq: val.sequence,
              active_from: val.active_from,
              active_to: val.active_to,
              update_info: val.update_info.timestamp,
              banner_name: val.banner_name,
            })
          }, 10)
      },
      deep: true,
    },
  },
  methods: {
    async onBoundingLoad() {
      // brands
      // if (this.brandItems !== undefined && this.brandItems.length) {
      if (!this.skeletonPage) {
        this.shiftHeight = 0
        let height = 150
        if (
          this.$refs['brandItems'].getBoundingClientRect().top <
          window.innerHeight - height
        ) {
          this.onLoadBrandItems()
        }
        // }
        // flashsale
        if (
          this.$refs['flashsale'].getBoundingClientRect().top <
          window.innerHeight - height
        )
          if (!this.stateLoad.itemsFlashSale) {
            if (this.$store.state.user.profileType !== 'eprocurement') {
              this.stateLoad.itemsFlashSale = true
              this.getFlashSaleExpired()
            } else {
              this.isLoadingFlashsale = false
            }

            // this.getFlashSaleProductHome()
          }
        // }
        // promotion banner
        if (
          this.$refs['promotionBanner'].getBoundingClientRect().top <
          window.innerHeight - height
        )
          this.isLoadingPromotionBanner = false

        // special-deal
        if (
          this.$refs['itemsSpecialDeal'].getBoundingClientRect().top <
          window.innerHeight - height
        )
          if (this.isShowBannerPromotion) {
            // console.log('segment', this.userProfile.segment)
            if (!this.stateLoad.itemsSpecialDeal) {
              this.stateLoad.itemsSpecialDeal = true
              this.getSpecialDealHome()
            }
          } else {
            this.isLoadingSpecialDeal = false
          }

        // recomnded
        if (
          this.$refs['itemsRecommended'].getBoundingClientRect().top <
          window.innerHeight - height
        )
          if (!this.stateLoad.itemsRecommended) {
            this.stateLoad.itemsRecommended = true
            this.getRecommendedProductHome()
          }
        // }

        // top categories
        // if (this.isCategories) {
        if (
          this.$refs['TopCategories'].getBoundingClientRect().top <
          window.innerHeight - height
        ) {
          // console.log('isCategories')
          if (!this.stateLoad.categories) {
            this.stateLoad.categories = true
            this.getฺBannerCategory()
          }
        }
        // }
        // banner_2
        if (
          (this.highlight !== undefined && this.highlight.length) ||
          (this.special !== undefined && this.special.length)
        ) {
          if (
            this.$refs['banner_2'].getBoundingClientRect().top <
            window.innerHeight - height
          )
            this.isLoadBanner_2 = true
        }
        // blog
        if (
          this.$refs['blog'].getBoundingClientRect().top <
          window.innerHeight - height
        ) {
          // console.log('isBlog')
          if (!this.stateLoad.blog) {
            this.stateLoad.blog = true
            this.getBlogs()
          }
        }
        // banner_3
        if (this.footer1.src) {
          if (
            this.$refs['banner_3'].getBoundingClientRect().top <
            window.innerHeight - height
          )
            this.isLoadBanner_3 = true
        }
        // banner_4
        if (this.footer2.length > 0) {
          if (
            this.$refs['banner_4'].getBoundingClientRect().top <
            window.innerHeight - height
          )
            this.isLoadBanner_4 = true
        }
        // strip 2 3 4
        if (
          this.$refs['strip_lo_2'].getBoundingClientRect().top <
          window.innerHeight - height
        )
          this.isLoadStripLo_2 = false

        if (
          this.$refs['strip_lo_3'].getBoundingClientRect().top <
          window.innerHeight - height
        )
          this.isLoadStripLo_3 = false

        if (
          this.$refs['strip_lo_4'].getBoundingClientRect().top <
          window.innerHeight - height
        )
          this.isLoadStripLo_4 = false
      }
    },
    onLoadBrandItems() {
      if (!this.stateLoad.brandItems) {
        // console.log('brandItems')
        this.stateLoad.brandItems = true
        this.getBrandsHome()
      } else {
        this.skeletonPage = false
      }
    },
    defaultImage() {
      this.images.push({
        path: 'https://ofm-cdn0.ofm.co.th/images/default-hero-banner_01.jpg',
      })
      this.banner_sub1.src =
        'https://ofm-cdn0.ofm.co.th/images/default-sub-banner_01.jpg'
      this.banner_sub2.src =
        'https://ofm-cdn0.ofm.co.th/images/default-sub-banner_02.jpg'
    },
    createProfileParams() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('segment', this.userProfile.segment)

      if (this.profileType === 'eprocurement') {
        params.append('price_type', this.userProfile.price_type)
        params.append('categories_type', this.userProfile.categories_type ?? '')
      }
      return params
    },
    createProductParams() {
      let params = new URLSearchParams()
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('accountId', this.userProfile.id ?? '')
      params.append('accountChannel', this.profileType)
      params.append('isFree', false)
      params.append('pageNo', 1)
      params.append('pageSize', 20)
      return params
    },
    createWidgetParams() {
      let params = new URLSearchParams()
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('accountId', this.userProfile.id ?? '')
      params.append('accountChannel', this.profileType)
      params.append('isFree', false)
      params.append('size', 8)
      return params
    },
    async getฺBannerHome() {
      let params = this.createProfileParams()
      await this.$profileProvider.getฺBannerHomeData(params).then(
        async (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('banner', data)
            let hero = data
              .filter((a) => a.position === 'hero')
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
            if (hero.length > 0) {
              hero.forEach((e, index) => {
                this.images.push({
                  alt: e.alt_tag?.[this.$i18n.locale] || e.title,
                  url: e.path_url,
                  path: e.image.url,
                  asset: false,
                  title: e.title_tag?.[this.$i18n.locale] || e.title,
                  gtmSeq: index + 1,
                  active_from: e.active_from,
                  active_to: e.active_to,
                  update_info: e.update_info.timestamp,
                  banner_name: e.banner_name,
                })
              })
              console.log('object', this.images)
            }
            let sub1 = data
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((a) => a.position === 'sub1')
            if (sub1 !== undefined) {
              this.banner_sub1 = {
                src: sub1.image.url,
                url: sub1.path_url,
                title: sub1.title_tag?.[this.$i18n.locale] || sub1.title,
                alt: sub1.alt_tag?.[this.$i18n.locale] || sub1.title,
                gtmSeq: 1,
                active_from: sub1.active_from,
                active_to: sub1.active_to,
                update_info: sub1.update_info.timestamp,
                banner_name: sub1.banner_name,
              }
              // preload image
              this.$utils.onPreload(
                this.$utils.genImgSrcSet(
                  this.$utils.imageTimeStamp(
                    this.banner_sub1.src,
                    this.banner_sub1.update_info,
                    false
                  ),
                  [
                    { w: 162, h: 128, q: 90, ws: '162w' },
                    { w: 188, h: 148, q: 90, ws: '188w' },
                    { w: 197, h: 155, q: 90, ws: '197w' },
                    { w: 221, h: 174, q: 90, ws: '221w' },
                    { w: 360, h: 283, q: 90, ws: '360w' },
                    { w: 488, h: 384, q: 90, ws: '488w' },
                  ]
                ),
                '(max-width: 360px) 162px, (max-width: 412px) 188px, (max-width: 768px) 360px, (max-width: 1024px) 488px,(max-width: 1280px) 197px, 221px'
              )
            }
            console.log('sub1', sub1)
            let sub2 = data
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((a) => a.position === 'sub2')
            if (sub2 !== undefined) {
              this.banner_sub2 = {
                src: sub2.image.url,
                url: sub2.path_url,
                title: sub2.title_tag?.[this.$i18n.locale] || sub2.title,
                alt: sub2.alt_tag?.[this.$i18n.locale] || sub2.title,
                gtmSeq: 2,
                active_from: sub2.active_from,
                active_to: sub2.active_to,
                update_info: sub2.update_info.timestamp,
                banner_name: sub2.banner_name,
              }
              this.$utils.onPreload(
                this.$utils.genImgSrcSet(
                  this.$utils.imageTimeStamp(
                    this.banner_sub2.src,
                    this.banner_sub2.update_info,
                    false
                  ),
                  [
                    { w: 162, h: 128, q: 90, ws: '162w' },
                    { w: 188, h: 148, q: 90, ws: '188w' },
                    { w: 197, h: 155, q: 90, ws: '197w' },
                    { w: 221, h: 174, q: 90, ws: '221w' },
                    { w: 360, h: 283, q: 90, ws: '360w' },
                    { w: 488, h: 384, q: 90, ws: '488w' },
                  ]
                ),
                '(max-width: 360px) 162px, (max-width: 412px) 188px, (max-width: 768px) 360px, (max-width: 1024px) 488px, (max-width: 1280px) 197px, 221px'
              )
            }
            console.log('sub2', sub2)
            let footer1 = data.find(
              (a) => a.position === 'footer1' && a.sequence === 1
            )
            console.log('footer1', footer1)
            if (footer1 !== undefined) {
              this.footer1 = {
                src: footer1.image.url,
                title: footer1.title_tag?.[this.$i18n.locale] || footer1.title,
                alt: footer1.alt_tag?.[this.$i18n.locale] || footer1.title,
                gtmSeq: 1,
                active_from: footer1.active_from,
                active_to: footer1.active_to,
                url: footer1.path_url,
                update_info: footer1.update_info.timestamp,
                banner_name: footer1.banner_name,
              }
            }
            let footer2 = data
              .filter((a) => a.position === 'footer2')
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
            if (footer2.length > 0) {
              footer2.forEach((a, index) => {
                this.footer2.push({
                  src: a.image.url,
                  title: a.title_tag?.[this.$i18n.locale] || a.title,
                  alt: a.alt_tag?.[this.$i18n.locale] || a.title,
                  gtmSeq: footer1 !== undefined ? index + 2 : index + 1,
                  active_from: a.active_from,
                  active_to: a.active_to,
                  url: a.path_url,
                  update_info: a.update_info.timestamp,
                  banner_name: a.banner_name,
                })
              })
            }

            let slide = data
              .filter((a) => a.position === 'slide')
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
            if (slide.length > 0) {
              this.promotionBanner = []
              slide.forEach((a, index) => {
                this.promotionBanner.push({
                  index: index + 1,
                  src: a.image.url,
                  title: a.title_tag?.[this.$i18n.locale] || a.title,
                  alt: a.alt_tag?.[this.$i18n.locale] || a.title,
                  gtmSeq: index + 1,
                  active_from: a.active_from,
                  active_to: a.active_to,
                  url: a.path_url,
                  update_info: a.update_info.timestamp,
                  path_url: a.path_url,
                  banner_name: a.banner_name,
                })
              })
            }
            let temp_highlight = data
              .filter((a) => a.position === 'highlight')
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
            if (temp_highlight.length > 0) {
              this.highlight = []
              temp_highlight.forEach((a, index) => {
                this.highlight.push({
                  src: a.image.url,
                  url: a.path_url,
                  title: a.title_tag?.[this.$i18n.locale] || a.title,
                  alt: a.alt_tag?.[this.$i18n.locale] || a.title,
                  gtmSeq: index + 1,
                  active_from: a.active_from,
                  active_to: a.active_to,
                  update_info: a.update_info.timestamp,
                  banner_name: a.banner_name,
                })
              })
            }

            let temp_special = data
              .filter((a) => a.position === 'special')
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
            if (temp_special.length > 0) {
              this.special = []
              temp_special.forEach((a, index) => {
                this.special.push({
                  src: a.image.url,
                  url: a.path_url,
                  title: a.title_tag?.[this.$i18n.locale] || a.title,
                  alt: a.alt_tag?.[this.$i18n.locale] || a.title,
                  gtmSeq: index + 1,
                  active_from: a.active_from,
                  active_to: a.active_to,
                  update_info: a.update_info.timestamp,
                  banner_name: a.banner_name,
                })
              })
            }

            // popup image
            let img_first = data
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((a) => a.position === 'first_load')
            let img_exist = data
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((a) => a.position === 'existing_load')
            this.onPopup(img_first, img_exist)
            // mall banner select top 3
            this.mallBanner = []
            data
              .filter((a) => a.position === 'mall_banner')
              .slice(0, 3)
              .forEach((e) => {
                this.mallBanner.push({
                  src: e.image.url,
                  src_mobile: e?.image_mobile?.url,
                  url: e.path_url,
                  title: e.title_tag?.[this.$i18n.locale] || e.title,
                  alt: e.alt_tag?.[this.$i18n.locale] || e.title,
                  gtmSeq: e.sequence,
                  active_from: e.active_from,
                  active_to: e.active_to,
                  update_info: e.update_info.timestamp,
                  banner_name: e.banner_name,
                })
              })

            // top banner
            this.strip_lo_1 = data
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((a) => a.position === 'strip_lo_1')
            // strip lo 2 - 4
            this.strip_lo_2 = data
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((a) => a.position === 'strip_lo_2')
            this.strip_lo_3 = data
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((a) => a.position === 'strip_lo_3')
            this.strip_lo_4 = data
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .find((a) => a.position === 'strip_lo_4')
            this.isSkeletonBanner = false
            await this.gtmBannerEvent()
          }
        },
        (error) => {
          console.log('getฺBannerHomeData', error.responsed)
        }
      )
    },
    async gtmBannerEvent() {
      //gtm Hero_Banner
      if (this.images.length) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoViewed',
          ecommerce: {
            promoView: {
              promotions: this.images.map((item) => {
                return {
                  id: item.path,
                  name: `HP|${item.gtmSeq}|${
                    item.title
                  }|${this.$utils.formatDateGTM(
                    item.active_from
                  )}_${this.$utils.formatDateGTM(item.active_to)}`,
                  creative: 'Hero_Banner',
                  position: item.gtmSeq,
                }
              }),
            },
          },
        })
      }

      // gtm GA4 Hero_Banner
      for (const item of this.images) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'view_promotion',
          ecommerce: {
            creative_name: 'Hero_Banner',
            creative_slot: `Hero_Banner_${item.gtmSeq}`,
            promotion_id: item.path,
            promotion_name: `HP|${item.gtmSeq}|${
              item.title
            }|${this.$utils.formatDateGTM(
              item.active_from
            )}_${this.$utils.formatDateGTM(item.active_to)}`,
          },
        })
      }

      //gtm Sub_Banner
      if (this.banner_sub1.src !== '' || this.banner_sub2.src !== '') {
        let listBanner = []
        if (this.banner_sub1.src !== '') {
          listBanner.push({
            id: this.banner_sub1.src,
            name: `HP|${this.banner_sub1.gtmSeq}|${
              this.banner_sub1.title
            }|${this.$utils.formatDateGTM(
              this.banner_sub1.active_from
            )}_${this.$utils.formatDateGTM(this.banner_sub1.active_to)}`,
            creative: 'Sub_Banner',
            position: 1,
          })
        }
        if (this.banner_sub2.src !== '') {
          listBanner.push({
            id: this.banner_sub2.src,
            name: `HP|${this.banner_sub2.gtmSeq}|${
              this.banner_sub2.title
            }|${this.$utils.formatDateGTM(
              this.banner_sub2.active_from
            )}_${this.$utils.formatDateGTM(this.banner_sub2.active_to)}`,
            creative: 'Sub_Banner',
            position: 2,
          })
        }
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoViewed',
          ecommerce: {
            promoView: {
              promotions: listBanner,
            },
          },
        })
      }

      // gtm GA4 Sub_Banner
      for (const item of [this.banner_sub1, this.banner_sub2]) {
        if (!item.src) continue
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'view_promotion',
          ecommerce: {
            creative_name: 'Sub_Banner',
            creative_slot: `Sub_Banner_${item.gtmSeq}`,
            promotion_id: item.src,
            promotion_name: `HP|${item.gtmSeq}|${
              item.title
            }|${this.$utils.formatDateGTM(
              item.active_from
            )}_${this.$utils.formatDateGTM(item.active_to)}`,
          },
        })
      }

      //gtm Slide_Banner
      if (this.promotionBanner.length) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoViewed',
          ecommerce: {
            promoView: {
              promotions: this.promotionBanner.map((item) => {
                return {
                  id: item.src,
                  name: `HP|${item.gtmSeq}|${
                    item.title
                  }|${this.$utils.formatDateGTM(
                    item.active_from
                  )}_${this.$utils.formatDateGTM(item.active_to)}`,
                  creative: 'Slide_Banner',
                  position: item.gtmSeq,
                }
              }),
            },
          },
        })
      }

      // gtm GA4 Slide_Banner
      for (const item of this.promotionBanner) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'view_promotion',
          ecommerce: {
            creative_name: 'Slide_Banner',
            creative_slot: `Slide_Banner_${item.gtmSeq}`,
            promotion_id: item.src,
            promotion_name: `HP|${item.gtmSeq}|${
              item.title
            }|${this.$utils.formatDateGTM(
              item.active_from
            )}_${this.$utils.formatDateGTM(item.active_to)}`,
          },
        })
      }

      //gtm Highlight_Banner
      if (this.highlight.length) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoViewed',
          ecommerce: {
            promoView: {
              promotions: this.highlight.map((item) => {
                return {
                  id: item.src,
                  name: `HP|${item.gtmSeq}|${
                    item.title
                  }|${this.$utils.formatDateGTM(
                    item.active_from
                  )}_${this.$utils.formatDateGTM(item.active_to)}`,
                  creative: 'Highlight_Banner',
                  position: item.gtmSeq,
                }
              }),
            },
          },
        })
      }

      // gtm GA4 Highlight_Banner
      for (const item of this.highlight) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'view_promotion',
          ecommerce: {
            creative_name: 'Highlight_Banner',
            creative_slot: `Highlight_Banner_${item.gtmSeq}`,
            promotion_id: item.src,
            promotion_name: `HP|${item.gtmSeq}|${
              item.title
            }|${this.$utils.formatDateGTM(
              item.active_from
            )}_${this.$utils.formatDateGTM(item.active_to)}`,
          },
        })
      }

      //gtm Special_Banner
      if (this.special.length) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoViewed',
          ecommerce: {
            promoView: {
              promotions: this.special.map((item) => {
                return {
                  id: item.src,
                  name: `HP|${item.gtmSeq}|${
                    item.title
                  }|${this.$utils.formatDateGTM(
                    item.active_from
                  )}_${this.$utils.formatDateGTM(item.active_to)}`,
                  creative: 'Special_Banner',
                  position: item.gtmSeq,
                }
              }),
            },
          },
        })
      }

      // gtm GA4 Special_Banner
      for (const item of this.special) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'view_promotion',
          ecommerce: {
            creative_name: 'Highlight_Banner',
            creative_slot: `Highlight_Banner_${item.gtmSeq}`,
            promotion_id: item.src,
            promotion_name: `HP|${item.gtmSeq}|${
              item.title
            }|${this.$utils.formatDateGTM(
              item.active_from
            )}_${this.$utils.formatDateGTM(item.active_to)}`,
          },
        })
      }

      //gtm footer
      if (this.footer1.src !== '' || this.footer2.length) {
        let listBannerFooter = []
        if (this.footer1.src !== '') {
          listBannerFooter.push({
            id: this.footer1.src,
            name: `HP|1|${this.footer1.title}|${this.$utils.formatDateGTM(
              this.footer1.active_from
            )}_${this.$utils.formatDateGTM(this.footer1.active_to)}`,
            creative: 'Footer_Banner',
            position: 1,
          })
        }
        if (this.footer2.length) {
          this.footer2.forEach((item) => {
            listBannerFooter.push({
              id: item.src,
              name: `HP|${item.gtmSeq}|${
                item.title
              }|${this.$utils.formatDateGTM(
                item.active_from
              )}_${this.$utils.formatDateGTM(item.active_to)}`,
              creative: 'Footer_Banner',
              position: item.gtmSeq,
            })
          })
        }
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoViewed',
          ecommerce: {
            promoView: {
              promotions: listBannerFooter,
            },
          },
        })
      }
      // gtm GA4 footer
      for (const item of [this.footer1, ...this.footer2]) {
        if (!item.src) continue
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'view_promotion',
          ecommerce: {
            creative_name: 'Footer_Banner',
            creative_slot: `Footer_Banner_${item.gtmSeq}`,
            promotion_id: item.src,
            promotion_name: `HP|${item.gtmSeq}|${
              item.title
            }|${this.$utils.formatDateGTM(
              item.active_from
            )}_${this.$utils.formatDateGTM(item.active_to)}`,
          },
        })
      }
    },
    onSelectBrand(data) {
      this.brandSelected = data
      console.log(this.brandSelected)
    },
    async getBrandsHome() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('segment', this.userProfile.segment)

      if (this.profileType === 'eprocurement') {
        params.append('price_type', this.userProfile.price_type)
        params.append('categories_type', this.userProfile.categories_type)
      }
      params.append('page_type', 'home')
      await this.$profileProvider.getBrandsHomeData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            console.log('object getBrandsHomeData', data)
            let brandHome = data.brand_homes.sort(function (a, b) {
              return a.sequence - b.sequence
            })
            brandHome.forEach((c, index) => {
              let banner = c.banner_images[0]
              this.brandHomeImage.push({
                id: c.brand_id,
                src: banner?.image?.url, // new
                sequence: c.sequence,
                title: banner?.title_tag?.[this.$i18n.locale], // new
                alt: banner?.alt_tag?.[this.$i18n.locale], // new
                gtmSeq: index + 1,
                active_from: c.active_from,
                active_to: c.active_to,
                url: banner.path_url, // new
                update_info: c.update_info.timestamp,
                banner_name: c.banner_name,
              })
            })
            data.brand_images
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
              .forEach((a, index) => {
                let imageUrl = this.imageBannerTemp
                if (this.$utils.anyArrayObjectValid(a.images))
                  imageUrl = a.images[0]?.url

                this.brandItems.push({
                  sequence: a.sequence,
                  src: imageUrl,
                  brandID: a.id,
                  brandName: a.display_name.th,
                  title: a.title_tag?.[this.$i18n.locale] || a.title,
                  alt: a.alt_tag?.[this.$i18n.locale] || a.title,
                  gtmSeq: index + 1,
                  active_from: a.active_from,
                  active_to: a.active_to,
                  update_date: this.$utils.isObjectKeyValid(
                    a.update_info,
                    'timestamp'
                  )
                    ? a.update_info.timestamp
                    : '',
                  banner_name: a.banner_name,
                })
              })
            console.log('brandItems', this.brandItems)
            if (this.$utils.anyArrayObjectValid(this.brandItems)) {
              this.activeBrand = this.brandItems[0]?.brandID
              if (this.$utils.isValid(this.activeBrand))
                this.GetBrandProductHome(this.activeBrand, false)
            }

            //gtm Brand_Logo
            if (this.brandItems.length) {
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'promoViewed',
                ecommerce: {
                  promoView: {
                    promotions: this.brandItems.map((item) => {
                      return {
                        id: item.src,
                        name: `HP|${item.gtmSeq}|${
                          item.title
                        }|${this.$utils.formatDateGTM(
                          item.active_from
                        )}_${this.$utils.formatDateGTM(item.active_to)}`,
                        creative: 'Brand_Logo',
                        position: item.gtmSeq,
                      }
                    }),
                  },
                },
              })
            }

            // gtm GA4 Brand_Logo
            for (const item of this.brandItems) {
              if (!item.src) continue
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'view_promotion',
                ecommerce: {
                  creative_name: 'Brand_Logo',
                  creative_slot: `Brand_Logo_${item.gtmSeq}`,
                  promotion_id: item.src,
                  promotion_name: `HP|${item.gtmSeq}|${
                    item.title
                  }|${this.$utils.formatDateGTM(
                    item.active_from
                  )}_${this.$utils.formatDateGTM(item.active_to)}`,
                },
              })
            }

            this.gtmEventOnClickBrandLogo()
          }
        },
        (error) => {
          console.log('getBrandsHome', error.responsed)
        }
      )
      this.isLoadBrandItems = true
      this.skeletonPage = false
    },
    async onActiveBrand(brandLogo) {
      this.items = []
      this.activeBrand = brandLogo.brandID
      console.log('brandLogo.brandID', brandLogo.brandID)
      if (
        this.$utils.isObjectValid(brandLogo) &&
        this.$utils.isValid(brandLogo.brandID)
      )
        this.GetBrandProductHome(brandLogo.brandID, true)
      this.gtmEventOnClickBrandLogo(brandLogo)
    },
    gtmEventOnClickBrandLogo(brandLogo) {
      //gtm promoClicked Brand_Logo
      if (brandLogo !== undefined) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoClicked',
          ecommerce: {
            promoClick: {
              promotions: [
                {
                  id: brandLogo.src,
                  name: `HP|${brandLogo.gtmSeq}|${
                    brandLogo.title
                  }|${this.$utils.formatDateGTM(
                    brandLogo.active_from
                  )}_${this.$utils.formatDateGTM(brandLogo.active_to)}`,
                  creative: 'Brand_Logo',
                  position: brandLogo.gtmSeq,
                },
              ],
            },
          },
        })

        // gtm GA4
        const promotion = {
          creative_name: 'Brand_Logo',
          creative_slot: `Brand_Logo_${brandLogo.gtmSeq}`,
          promotion_id: brandLogo.src,
          promotion_name: `HP|${brandLogo.gtmSeq}|${
            brandLogo.title
          }|${this.$utils.formatDateGTM(
            brandLogo.active_from
          )}_${this.$utils.formatDateGTM(brandLogo.active_to)}`,
        }
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'select_promotion',
          ecommerce: {
            user: this.$store.getters['user/userInfoTrack'],
            ...promotion,
          },
        })
        this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
      }

      //gtm Brand_Banner
      if (this.brandHomeImage.length) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'promoViewed',
          ecommerce: {
            promoView: {
              promotions: this.brandHomeImage
                .filter((q) => q.id === this.activeBrand)
                .map((item) => {
                  return {
                    id: item.src,
                    name: `HP|${item.gtmSeq}|${
                      item.title
                    }|${this.$utils.formatDateGTM(
                      item.active_from
                    )}_${this.$utils.formatDateGTM(item.active_to)}`,
                    creative: 'Brand_Banner',
                    position: item.gtmSeq,
                  }
                }),
            },
          },
        })
      }

      // gtm GA4 Brand_Banner
      for (const item of this.brandHomeImage.filter(
        (el) => el.id === this.activeBrand
      )) {
        this.$gtm.push({ ecommerce: null })
        this.$gtm.push({
          event: 'view_promotion',
          ecommerce: {
            creative_name: 'Brand_Banner',
            creative_slot: `Brand_Banner_${item.gtmSeq}`,
            promotion_id: item.src,
            promotion_name: `HP|${item.gtmSeq}|${
              item.title
            }|${this.$utils.formatDateGTM(
              item.active_from
            )}_${this.$utils.formatDateGTM(item.active_to)}`,
          },
        })
      }
    },
    async GetBrandProductHome(brand_id, loading) {
      this.brandloading = loading
      let params = this.createProductParams()
      await this.$productProvider
        .getBrandProductHomeData(brand_id, params, null, false)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              if (data.items !== null) {
                data.items.forEach((a, index) => {
                  if (
                    this.$utils.anyArrayObjectValid(a.skus) &&
                    this.$utils.isObjectValid(a.skus[0])
                  ) {
                    let sku = a.skus[0]
                    this.items.push({
                      productId: sku.sku_code,
                      productName: sku.product_names.find(
                        (b) => b.type === 'Online'
                      ).display_name,

                      // productImg: sku.images.find(
                      //   (c) => c.size === 'thumbnail' && c.sequence === 1
                      // ).url,
                      productImg: this.imageNormalShowcase(sku),
                      price_include_vat: sku.price_include_vat,
                      retail_price: sku.retail_price.include_vat,
                      is_promotion: sku.is_promotion,
                      is_fixprice: sku.is_fixprice,
                      is_flash_sale: sku.is_flash_sale,
                      is_hot_flash_sale: sku.is_hot_flash_sale,
                      is_online_only: sku.is_online_only,
                      promotion_prices: sku.promotion_prices,
                      display_discount_rate: sku.display_discount_rate,
                      meta: sku.meta,
                      seoMeta: sku.meta.seo_name,
                      seq: index + 1,
                      display_ratings: sku.display_ratings,
                      display_ratings_count: sku.display_ratings_count,
                      update_info: sku.update_info,
                      overlay_web_image: sku.overlay_web_image,
                      is_promotion_discount: sku.is_promotion_discount,
                      is_installment_sku: sku.is_installment_sku,
                      brand: sku.brand,
                    })
                  }
                })
                if (this.items.length) {
                  //gtm
                  this.$gtm.push({ ecommerce: null })
                  this.$gtm.push({
                    event: 'impression',
                    currencyCode: 'THB',
                    ecommerce: {
                      impressions: this.items.map((item) => {
                        return {
                          id: item.productId,
                          price: this.$utils.formatPrice(
                            item.price_include_vat,
                            2,
                            true
                          ),
                          list: 'Brand Highlight',
                          position: item.seq,
                        }
                      }),
                    },
                  })
                }
              }
            }
          },
          (error) => {
            console.log('GetProductHome', error.responsed)
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.brandloading = false
          }, 100)
        })
    },
    async getฺBannerCategory() {
      this.temp_categories = []
      let params = this.createProfileParams()
      await this.$profileProvider.getBannerCategoryData(params).then(
        async (result) => {
          if (result.status === 200) {
            let data = result.data
            //console.log('data', data)
            let category_banner = data
              .filter((a) => a.page_type === 'home')
              .sort(function (a, b) {
                return a.sequence - b.sequence
              })
            if (category_banner.length > 0) {
              this.getAllCategories(category_banner).then(() => {
                // console.log('gtm Top_Cat push')
                // gtm Top_Cat
                if (this.$utils.anyArrayObjectValid(this.categories)) {
                  this.$gtm.push({ ecommerce: null })
                  this.$gtm.push({
                    event: 'promoViewed',
                    ecommerce: {
                      promoView: {
                        promotions: this.categories.map((item, index) => {
                          return {
                            id: item.imgCategories,
                            name: `HP|${index + 1}|${
                              item.title
                            }|${this.$utils.formatDateGTM(
                              item.active_from
                            )}_${this.$utils.formatDateGTM(item.active_to)}`,
                            creative: 'Top_Cat',
                            position: index + 1,
                          }
                        }),
                      },
                    },
                  })

                  // gtm GA4 Top_Cat
                  for (const [index, item] of this.categories.entries()) {
                    this.$gtm.push({ ecommerce: null })
                    this.$gtm.push({
                      event: 'view_promotion',
                      ecommerce: {
                        creative_name: 'Top_Cat',
                        creative_slot: `Top_Cat_${index + 1}`,
                        promotion_id: item.imgCategories,
                        promotion_name: `HP|${index + 1}|${
                          item.title
                        }|${this.$utils.formatDateGTM(
                          item.active_from
                        )}_${this.$utils.formatDateGTM(item.active_to)}`,
                      },
                    })
                  }
                }
              })

              console.log('category_banner :>> ', category_banner)
            }
          }
        },
        (error) => {
          console.log('getBannerCategoryData', error.responsed)
        }
      )
    },
    async getAllCategories(category_banner) {
      // return new Promise((resolve, reject) => {
      // try {
      // var cat_ids = []
      // var banner_images = []
      // var sequence = []
      // var banner_titles = []
      // var active_from = []
      // var active_to = []
      // var path_urls = []
      let categorieData = []
      for (const element of category_banner) {
        let item = element
        let banner = item.banner_images.find((a) => a.sequence === 1)
        categorieData.push({
          cat_id: item.category_id,
          image: banner.image,
          sequence: item.sequence,
          title: banner.title,
          active_from: item.active_from,
          active_to: item.active_to,
          path_url: banner.path_url,
          update_info: item?.update_info?.timestamp,
        })
      }
      await this.getcatagories(categorieData)
      this.categoriesProductHome = []
      let deptIDs = []
      for (const element of this.categories) {
        let subCatagories = element.subCatagories
        if (
          this.$utils.anyArrayObjectValid(subCatagories) &&
          this.$utils.isValid(subCatagories)
        ) {
          let deptID = this.defaultSubCatagorieID(subCatagories)
          deptIDs.push(deptID)
        }
      }
      if (this.$utils.anyArrayObjectValid(deptIDs)) {
        await this.getCategoriesProductHome(deptIDs)
      }
      console.log('isLoadCategories', this.isLoadCategories)
      this.isLoadCategories = true
      //   resolve()
      // } catch (error) {
      //   reject(error)
      // }
      // })
      // return new Promise((resolve, reject) => {
      //   try {
      //     category_banner.forEach(async (c, index) => {
      //       var banner = c.banner_images.find((a) => a.sequence === 1)
      //       await this.getcatagory(
      //         c.category_id,
      //         banner.image,
      //         c.sequence,
      //         banner.title,
      //         c.active_from,
      //         c.active_to,
      //         banner.path_url
      //       )
      //       if (index === category_banner.length - 1) resolve()
      //     })
      //   } catch (error) {
      //     reject(error)
      //   }
      // })
    },
    async getcatagories(categorieData) {
      this.categories = []
      // console.log(cat_id)
      let params = this.createProductParams()
      let cat_ids = categorieData.map((q) => q.cat_id)
      if (this.$utils.anyArrayObjectValid(cat_ids)) {
        for (const element of cat_ids) {
          params.append('ids', element)
        }
      }
      await this.$productProvider.getHomeSpecificCategoriesData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            for (const element of data) {
              let parent = []
              let _category = categorieData.find((q) => q.cat_id === element.id)

              if (this.$utils.isObjectValid(_category)) {
                if (element.parentCategories !== null) {
                  element.parentCategories.forEach((s, index) => {
                    parent.push({
                      index: index + 1,
                      cat_id: _category.cat_id,
                      subCatId: s.id,
                      deptName: s.display_name,
                    })
                  })
                }

                this.categories.push({
                  id: _category.cat_id,
                  sequence: _category.sequence,
                  categoriesName: element.categories_name,
                  meta: element.meta,
                  imgCategories: _category.image.url,
                  subCatagories: parent,
                  // defaultSubCatagorieID: defaultSubCatagorieID,
                  title:
                    _category.title_tag?.[this.$i18n.locale] || _category.title,
                  alt:
                    _category.alt_tag?.[this.$i18n.locale] || _category.title,
                  active_from: _category.active_from,
                  active_to: _category.active_to,
                  url: _category.path_url,
                  update_info: _category.update_info,
                  banner_name: _category.banner_name,
                })
                this.categories = this.categories.sort(function (a, b) {
                  return a.sequence - b.sequence
                })
              }
            }
          }
        },
        (error) => {
          console.log('getcatagory', error.responsed)
        }
      )
    },
    async getCategoriesProductHome(deptIDs) {
      this.isloanding = true
      // this.items = []
      let params = new URLSearchParams()
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      if (this.$utils.anyArrayObjectValid(deptIDs)) {
        for (const element of deptIDs) {
          params.append('ids', element)
        }
      }

      params.append('accountId', this.userProfile.id || '')
      params.append('accountChannel', this.profileType)
      params.append('isFree', false)
      params.append('pageNo', 1)
      params.append('pageSize', 20)

      await this.$productProvider
        .getSpecificCategoriesProductHomeData(params, null, false)
        .then(
          (result) => {
            if (result.status === 200) {
              let data = result.data
              for (const element of data) {
                let _items = []
                if (this.$utils.anyArrayObjectValid(element.items)) {
                  element.items.forEach((a, index) => {
                    _items.push({
                      productId: a.skus[0]?.sku_code,
                      productName: a.skus[0]?.product_names.find(
                        (b) => b.type.toLowerCase() === 'online'
                      ).display_name,
                      // productImg: a.skus[0]?.images.find(
                      //   (c) => c.size === 'thumbnail' && c.sequence === 1
                      // ).url,
                      productImg: this.imageNormalShowcase(a.skus[0]),
                      price_include_vat: a.skus[0]?.price_include_vat,
                      retail_price: a.skus[0]?.retail_price.include_vat,
                      is_promotion: a.skus[0]?.is_promotion,
                      is_fixprice: a.skus[0]?.is_fixprice,
                      is_flash_sale: a.skus[0]?.is_flash_sale,
                      is_hot_flash_sale: a.skus[0]?.is_hot_flash_sale,
                      is_online_only: a.skus[0]?.is_online_only,
                      promotion_prices: a.skus[0]?.promotion_prices,
                      display_discount_rate: a.skus[0]?.display_discount_rate,
                      meta: a.skus[0]?.meta,
                      seoMeta: a.skus[0]?.meta.seo_name,
                      seq: index + 1,
                      display_ratings: a.skus[0]?.display_ratings,
                      display_ratings_count: a.skus[0]?.display_ratings_count,
                      update_info: a.update_info,
                      overlay_web_image: a.skus[0]?.overlay_web_image,
                      is_promotion_discount: a.skus[0]?.is_promotion_discount,
                      is_installment_sku: a.skus[0]?.is_installment_sku,
                      brand: a.brand,
                    })
                  })
                }
                if (this.$utils.anyArrayObjectValid(_items)) {
                  //gtm
                  this.$gtm.push({ ecommerce: null })
                  this.$gtm.push({
                    event: 'impression',
                    currencyCode: 'THB',
                    ecommerce: {
                      impressions: _items.map((item) => {
                        return {
                          id: item.productId,
                          price: this.$utils.formatPrice(
                            item.price_include_vat,
                            2,
                            true
                          ),
                          list: 'Top Categories',
                          position: item.seq,
                        }
                      }),
                    },
                  })
                }
                this.categoriesProductHome.push({
                  key: element.id,
                  items: _items,
                })
              }
            }
          },
          (error) => {
            console.log('GetCategoriesProductHome', error.responsed)
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.isloanding = false
          }, 100)
        })
    },
    // async getcatagory(
    //   cat_id,
    //   img,
    //   sequence,
    //   title,
    //   active_from,
    //   active_to,
    //   path_url
    // ) {
    //   console.log(cat_id)
    //   let parent = []
    //   this.categories = []
    //   this.subCatagories = []
    //   let params = this.createProductParams()
    //   await this.$productProvider.getHomeCategoriesData(cat_id, params).then(
    //     (result) => {
    //       if (result.status === 200) {
    //         let data = result.data
    //         // let defaultSubCatagorieID = ''
    //         if (data.parentCategories !== null) {
    //           data.parentCategories.forEach((s, index) => {
    //             // if (!this.$utils.isValid(defaultSubCatagorieID))
    //             //   defaultSubCatagorieID = s.id
    //             parent.push({
    //               index: index + 1,
    //               cat_id: cat_id,
    //               subCatId: s.id,
    //               deptName: s.display_name,
    //             })
    //           })
    //         }

    //         this.categories.push({
    //           id: cat_id,
    //           sequence: sequence,
    //           categoriesName: data.categories_name,
    //           imgCategories: img.url,
    //           subCatagories: parent,
    //           // defaultSubCatagorieID: defaultSubCatagorieID,
    //           title: title,
    //           alt: title,
    //           active_from: active_from,
    //           active_to: active_to,
    //           url: path_url,
    //         })
    //         this.categories = this.categories.sort(function (a, b) {
    //           return a.sequence - b.sequence
    //         })

    //         // console.log('categories', this.categories)
    //       }
    //     },
    //     (error) => {
    //       console.log('getcatagory', error.responsed)
    //     }
    //   )
    // },
    defaultSubCatagorieID(subCatagorie) {
      let subCategory = subCatagorie.find((q) => q.index === 1)
      if (this.$utils.isObjectValid(subCategory)) return subCategory.subCatId
      return ''
    },
    async onActiveCategories(Dept_id) {
      this.activeDept = Dept_id
      console.log(Dept_id)
    },
    async getRecommendedProductHome() {
      let params = this.createProductParams()
      params.append('user_open_id', this.openId)
      await this.$productProvider.getRecommendedProductHomeData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            if (data.items !== null && data.items.length > 0) {
              data.items.forEach((a, index) => {
                this.itemsRecommended.push({
                  productId: a.skus[0]?.sku_code,
                  productName: a.skus[0]?.product_names.find(
                    (b) => b.type === 'Online'
                  ).display_name,

                  // productImg: a.skus[0]?.images.find(
                  //   (c) => c.size === 'thumbnail' && c.sequence === 1
                  // ).url,
                  productImg: this.imageNormalShowcase(a.skus[0]),
                  price_include_vat: a.skus[0]?.price_include_vat,
                  retail_price: a.skus[0]?.retail_price.include_vat,
                  is_promotion: a.skus[0]?.is_promotion,
                  is_fixprice: a.skus[0]?.is_fixprice,
                  is_flash_sale: a.skus[0]?.is_flash_sale,
                  is_hot_flash_sale: a.skus[0]?.is_hot_flash_sale,
                  is_online_only: a.skus[0]?.is_online_only,
                  promotion_prices: a.skus[0]?.promotion_prices,
                  display_discount_rate: a.skus[0]?.display_discount_rate,
                  meta: a.skus[0]?.meta,
                  seoMeta: a.skus[0]?.meta.seo_name,
                  seq: index + 1,
                  display_ratings: a.skus[0]?.display_ratings,
                  display_ratings_count: a.skus[0]?.display_ratings_count,
                  update_info: a.update_info,
                  overlay_web_image: a.skus[0]?.overlay_web_image,
                  is_promotion_discount: a.skus[0]?.is_promotion_discount,
                  is_installment_sku: a.skus[0]?.is_installment_sku,
                  brand: a.brand,
                })
              })
            }
            if (this.itemsRecommended.length) {
              //gtm
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'impression',
                currencyCode: 'THB',
                ecommerce: {
                  impressions: this.itemsRecommended.map((item) => {
                    return {
                      id: item.productId,
                      price: this.$utils.formatPrice(
                        item.price_include_vat,
                        2,
                        true
                      ),
                      list: 'RECOMMENDED FOR YOU',
                      position: item.seq,
                    }
                  }),
                },
              })
            }
            // var products = data.items.sort(function (a, b) {
            //   return a.sequence - b.sequence
            // })
            // products.forEach((a) => {
            //   this.itemsRecommended.push({
            //     productId: a.sku_code,
            //     productName: a.product_names.find((b) => b.type === 'Online')
            //       .display_name,

            //     productImg: a.images.find(
            //       (c) => c.size === 'tp' && c.sequence === 1
            //     ).url,
            //     productPrice: a.productPrice,
            //     productDiscount: a.productDiscount,
            //   })
            // })
          }

          this.isLoadItemsRecommended = true
          // console.log(' this.itemsRecommended', this.itemsRecommended)
        },
        (error) => {
          console.log('GetRecommendedProductHome', error.responsed)
        }
      )
    },
    async getBlogs() {
      await this.$productProvider.getBlogsData().then(
        (result) => {
          console.log('getBlogsData', result)
          if (result.status === 200) {
            let data = result.data
            // console.log('data', data)
            data.forEach((a) => {
              this.feeds.push({
                imagePath: a.imagePath,
                description: a.description,
                linkUrl: a.linkUrl,
              })
            })
          }
        },
        (error) => {
          console.log('getBlog', error.responsed)
        }
      )
      console.log('isLoadBlog')
      this.isLoadBlog = true
    },
    async getWidget() {
      await this.getWidgetProductsData([
        'NewArrivals',
        'FeaturedProducts',
        'BestSeller',
      ])
    },
    // async getNewArrivals() {
    //   this.getWidgetProductsData('NewArrivals')
    // },
    // async getFeaturedProducts() {
    //   this.getWidgetProductsData('FeaturedProducts')
    // },
    // async getBestSeller() {
    //   this.getWidgetProductsData('BestSeller')
    // },
    async getWidgetProductsData(types) {
      let params = this.createWidgetParams()
      if (this.$utils.anyArrayObjectValid(types)) {
        for (const element of types) {
          params.append('types', element)
        }
      }

      await this.$productProvider.getWidgetProducts(params).then(
        (result) => {
          if (result.status === 200) {
            // this.isLoadWidget = true
            let data = result.data
            if (data?.items) {
              data.items.forEach((item, index) => {
                this.itemsWidget.push({
                  productId: item.skus[0]?.sku_code,
                  productName: item.skus[0]?.product_names.find(
                    (b) => b.type === 'Online'
                  ).display_name,
                  productImg: this.imageNormalShowcase(item.skus[0]),
                  price_include_vat: item.skus[0]?.price_include_vat,
                  retail_price: item.skus[0]?.retail_price.include_vat,
                  is_promotion: item.skus[0]?.is_promotion,
                  is_online_only: item.skus[0]?.is_online_only,
                  is_flash_sale: item.skus[0]?.is_flash_sale,
                  is_hot_flash_sale: item.skus[0]?.is_hot_flash_sale,
                  is_fixprice: item.skus[0]?.is_fixprice,
                  promotion_prices: item.skus[0]?.promotion_prices,
                  display_discount_rate: item.skus[0]?.display_discount_rate,
                  meta: item.skus[0]?.meta,
                  seoMeta: item.skus[0]?.meta.seo_name,
                  seq: index + 1,
                  type: item.type,
                  display_ratings: item.skus[0]?.display_ratings,
                  display_ratings_count: item.skus[0]?.display_ratings_count,
                  update_info: item.update_info,
                  overlay_web_image: item.skus[0]?.overlay_web_image,
                  is_promotion_discount: item.skus[0]?.is_promotion_discount,
                  is_installment_sku: item.skus[0]?.is_installment_sku,
                  brand: item.brand,
                })
              })
            }

            if (this.$utils.anyArrayObjectValid(data.items)) {
              //gtm
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'impression',
                currencyCode: 'THB',
                ecommerce: {
                  impressions: data.items.map((item, index) => {
                    return {
                      id: item.skus[0]?.sku_code,
                      price: this.$utils.formatPrice(
                        item.skus[0]?.price_include_vat,
                        2,
                        true
                      ),
                      list: (() => {
                        switch (item.type.toLowerCase()) {
                          case 'newarrivals':
                            return 'New Arrivals'
                          case 'featuredproducts':
                            return 'Featured Products'
                          default:
                            return 'Best Sellers'
                        }
                      })(),
                      position: index + 1,
                    }
                  }),
                },
              })
            }
          }
        },
        (error) => {
          console.log('getNewArrivals', error.responsed)
        }
      )
      this.isLoadingWidget = false
    },
    // onClickBanner(banner, bannerCreative) {
    //   if (banner !== undefined) {
    //     if (this.$utils.isValid(bannerCreative)) {
    //       //gtm promoClicked
    //       this.$gtm.push({ ecommerce: null })
    //       this.$gtm.push({
    //         event: 'promoClicked',
    //         ecommerce: {
    //           promoClick: {
    //             promotions: [
    //               {
    //                 id: banner.src,
    //                 name: `HP|${banner.gtmSeq}|${
    //                   banner.title
    //                 }|${this.$utils.formatDateGTM(
    //                   banner.active_from
    //                 )}_${this.$utils.formatDateGTM(banner.active_to)}`,
    //                 creative: bannerCreative,
    //                 position: banner.gtmSeq,
    //               },
    //             ],
    //           },
    //         },
    //       })

    //       // gtm GA4
    //       const promotion = {
    //         creative_name: banner.title,
    //         creative_slot: `${banner.title}_${banner.gtmSeq}`,
    //         promotion_id: banner.src,
    //         promotion_name: `HP|${banner.gtmSeq}|${
    //           banner.alt
    //         }|${this.$utils.formatDateGTM(
    //           banner.active_from
    //         )}_${this.$utils.formatDateGTM(banner.active_to)}`,
    //       }
    //       this.$gtm.push({ ecommerce: null })
    //       this.$gtm.push({
    //         event: 'select_promotion',
    //         ecommerce: {
    //           user: this.$store.getters['user/userInfoTrack'],
    //           ...promotion,
    //         },
    //       })
    //       this.$store.commit('user/SET_USER_PROMOTION_TRACK', promotion)
    //     }
    //   }
    // },
    imageNormalShowcase(sku) {
      if (this.$utils.anyArrayObjectValid(sku.images)) {
        return (
          sku.images.some((q) => q.size === 'large')
            ? sku.images
                .sort(function (a, b) {
                  return a.sequence - b.sequence
                })
                .find((q) => q.size === 'large').url
            : this.imageTemp
        ).replace('/large', '/original')
      } else {
        return this.imageTemp.replace('/large', '/original')
      }
    },
    onFlashsaleTimeout(data) {
      // console.log('onFlashsaleTimeout', data)
      this.isFlashSale = false
    },
    // check end date flash sale
    async getFlashSaleExpired() {
      await this.$productProvider.getFlashSaleExpired().then(
        (result) => {
          if (result.status === 200) {
            console.log('flashSaleEndDate', result.data)
            this.currentDate = new Date(
              result.data.current_date
            ).toLocaleString('en-US', {
              timeZone: 'Asia/Bangkok',
            })
            this.flashSaleEndDate = new Date(
              result.data.end_date
            ).toLocaleString('en-US', {
              timeZone: 'Asia/Bangkok',
            })

            // Time remaining
            let diff =
              new Date(this.flashSaleEndDate).getTime() -
              new Date(this.currentDate).getTime()

            if (diff > 0) this.getFlashSaleProductHome()
          } else {
            this.isLoadingFlashsale = false
          }
        },
        (error) => {
          console.log('GetRecommendedProductHome', error.responsed)
        }
      )
    },
    async getFlashSaleProductHome() {
      let params = this.createProductParams()
      params.append('user_open_id', this.openId)
      await this.$productProvider.getFlashSaleProductHomeData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data

            // check show flash sale
            if (data.items !== null && data.items.length > 0) {
              this.isFlashSale = true
              data.items.forEach((a, index) => {
                this.itemsFlashSale.push({
                  productId: a.skus[0]?.sku_code,
                  productName: a.skus[0]?.product_names.find(
                    (b) => b.type === 'Online'
                  ).display_name,
                  productImg: this.imageNormalShowcase(a.skus[0]),
                  price_include_vat: a.skus[0]?.price_include_vat,
                  retail_price: a.skus[0]?.retail_price.include_vat,
                  is_promotion: a.skus[0]?.is_promotion,
                  is_fixprice: a.skus[0]?.is_fixprice,
                  meta: a.skus[0]?.meta,
                  seoMeta: a.skus[0]?.meta.seo_name,
                  seq: index + 1,
                  display_ratings: a.skus[0]?.display_ratings,
                  display_ratings_count: a.skus[0]?.display_ratings_count,
                  update_info: a.update_info,
                  end_date: a.skus[0]?.end_date,
                  is_hot_flash_sale: a.skus[0]?.is_hot_flash_sale,
                  is_flash_sale: a.skus[0]?.is_flash_sale,
                  is_online_only: a.skus[0]?.is_online_only,
                  display_discount_rate: a.skus[0]?.display_discount_rate,
                  promotion_prices: a.skus[0]?.promotion_prices,
                  overlay_web_image: a.skus[0]?.overlay_web_image,
                  is_promotion_discount: a.skus[0]?.is_promotion_discount,
                  is_installment_sku: a.skus[0]?.is_installment_sku,
                  brand: a.brand,
                })
              })
            }
            // if (this.itemsRecommended.length) {
            //   //gtm
            //   this.$gtm.push({ ecommerce: null })
            //   this.$gtm.push({
            //     event: 'impression',
            //     currencyCode: 'THB',
            //     ecommerce: {
            //       impressions: this.itemsRecommended.map((item) => {
            //         return {
            //           id: item.productId,
            //           price: this.$utils.formatPrice(
            //             item.price_include_vat,
            //             2,
            //             true
            //           ),
            //           list: 'RECOMMENDED FOR YOU',
            //           position: item.seq,
            //         }
            //       }),
            //     },
            //   })
            // }
          }

          this.isLoadItemsFlashSale = true
          // console.log(' this.itemsRecommended', this.itemsRecommended)
        },
        (error) => {
          console.log('GetRecommendedProductHome', error.responsed)
        }
      )
      this.isLoadingFlashsale = false
    },
    async getInstoryHome() {
      let fields = `id,
        name,
        display_size,
        instory_items.link,
        instory_items.sequence,
        instory_items.translations.languages_code,
        instory_items.translations.display_name,
        instory_items.translations.title_tag,
        instory_items.translations.alt,
        instory_items.image.id,
        instory_items.image.filename_download
      `
      let filter = {
        _and: [
          {
            sale_methods: {
              sale_methods_id: {
                _in: [
                  this.$store.state.user.profileType === 'standard' ? 89 : 6,
                ],
              },
            },
          },
          { channels: { _contains: 'ofm' } },
          { page: { _icontains: 'home' } },
          { position: { _icontains: 'instory' } },
          {
            _or: [
              {
                segment: { _icontains: this.userProfile?.segment || '_nempty' },
              },
              { segment: { _icontains: 'all' } },
            ],
          },
        ],
      }

      let deep = {
        instory_items: {
          translations: {
            _filter: {
              languages_code: {
                _eq: this.$i18n.locale === 'th' ? 'th-TH' : 'en-US',
              },
            },
          },
        },
      }

      await this.$directusProvider
        .onGetInstories({
          fields,
          filter,
          deep,
          limit: 1,
          // sort: '-date_created',
        })
        .then(
          async (result) => {
            this.instoryBanner = result.data[0]
            console.log('directusProvider', this.instoryBanner)
          },
          (error) => {
            console.log('getBannerCategoryData', error)
          }
        )
      this.isSkeletonInstory = false
    },
    async getSpecialDealHome() {
      let params = await this.createParams()
      const response = await this.$bannerProvider.getBannerCampaignByNameOrUrl(
        params,
        false
      )
      if (response.status === 200) {
        const data = response.data
        console.log('getSpecialDealHome', data)
        this.bannerSpecialDeal = [...data.banner_images]
        this.urlParameter = data.url_parameter
        this.updateInfo = data?.updateInfo?.timestamp
        this.itemsSpecialDeal = []

        data.skus.items.forEach((a, index) => {
          this.itemsSpecialDeal.push({
            productId: a.skus[0]?.sku_code,
            productName: a.skus[0]?.product_names.find(
              (b) => b.type === 'Online'
            ).display_name,
            productImg: this.imageNormalShowcase(a.skus[0]),
            price_include_vat: a.skus[0]?.price_include_vat,
            retail_price: a.skus[0]?.retail_price.include_vat,
            is_promotion: a.skus[0]?.is_promotion,
            is_fixprice: a.skus[0]?.is_fixprice,
            meta: a.skus[0]?.meta,
            seoMeta: a.skus[0]?.meta.seo_name,
            seq: index + 1,
            display_ratings: a.skus[0]?.display_ratings,
            display_ratings_count: a.skus[0]?.display_ratings_count,
            update_info: a.update_info,
            end_date: a.skus[0]?.end_date,
            is_hot_flash_sale: a.skus[0]?.is_hot_flash_sale,
            is_flash_sale: a.skus[0]?.is_flash_sale,
            is_online_only: a.skus[0]?.is_online_only,
            display_discount_rate: a.skus[0]?.display_discount_rate,
            promotion_prices: a.skus[0]?.promotion_prices,
            overlay_web_image: a.skus[0]?.overlay_web_image,
            is_promotion_discount: a.skus[0]?.is_promotion_discount,
            is_installment_sku: a.skus[0]?.is_installment_sku,
            brand: a.brand,
          })
        })
      }
      this.isLoadingSpecialDeal = false
    },
    async createParams() {
      let params = new URLSearchParams()
      params.append('url', 'home')
      params.append('user_open_id', this.openId)
      params.append('account_id', this.userProfile.id || '')
      params.append('account_channel', this.profileType)
      params.append('page_type', 'special_deal')
      params.append('is_home_page', true)
      params.append('segment', this.userProfile.segment)

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('company_id', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('user_category_type', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenter_id', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('user_category_type', 'Standard')
      }

      params.append('page', 1)
      params.append('page_size', 20)
      // params.append('sort', this.sort)

      return params
    },
    onPopup(img_first, img_exist) {
      this.popupImageObj = img_first || img_exist
      if (Cookie.get('popupImage') !== undefined) {
        this.popupImageObj = img_exist
        // Cookie.get('popupImage') === 'existing' ? img_exist : {}
      }
      if (img_first?.image?.url) {
        this.isExistingPopup = img_exist?.image?.url ? 'existing' : 'disabled'
      } else {
        this.isExistingPopup = 'disabled'
        // this.popupImageObj = img_exist
      }
      this.isPopupImage = this.popupImageObj?.image?.url !== ''
    },
    async showPopup() {
      if (!this.popupImageComponent) {
        this.popupImageComponent = async () =>
          await import('@/components/modal/popupImage.vue')
      }
      this.isPopupVisible = true
    },
  },
}
