
import mainNavbar from '@/components/template/algolia/main-navbar'
import secondNavbar from '@/components/template/second-navbar'
import FooterComponent from '@/components/template/footer.vue'
import loading from '@/components/share/loading'
import modalComponent from '@/components/share/modal'
import SwiperSlider from '@/components/share/swiper-item.vue'

import { mapState, mapActions } from 'vuex'
export default {
  name: 'DefaultLayout',
  components: {
    'main-navbar': mainNavbar,
    'second-navbar': secondNavbar,
    'footer-component': FooterComponent,
    'swiper-item': SwiperSlider,
    loading,
    modalComponent,
  },
  data() {
    return {
      userData: {},
      itemsRecommended: [],
    }
  },
  head() {
    return {
      title: this.$t('meta.defaultTitle'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.description'),
        },
        // og
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.$t('meta.defaultTitle'),
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.$t('meta.description'),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'https://ofm-cdn0.ofm.co.th/images/default-og.png',
        },
      ],
    }
  },
  computed: {
    ...mapState('authen', ['isAuthenticated', 'openId']),
    ...mapState('cart', ['cartId']),
    ...mapState('user', ['userProfile', 'profileType']),
    getAuthenticatedFlag() {
      console.log('isAuthenticated', this.isAuthenticated)
      return this.isAuthenticated
    },
  },
  created() {},
  async mounted() {
    if (this.isAuthenticated) await this.getUserData()
    await this.getCartCount()
    this.itemsRecommended = [
      {
        index: 1,
        productId: 'OFM4007354',
        productName: 'เครื่องปริ้นเตอร์เลเซอร์ HP 1000w',
        productImg:
          'https://aumento.officemate.co.th/media/catalog/product/4/0/4007354.jpg?imwidth=320',
        productPrice: 8390,
        productDiscount: 0,
        subCatagories: 'Printer & Supplies',
        productRating: 4,
      },
      {
        index: 2,
        productId: 'OFM4003395',
        productName: 'ผงหมึก สีดำ FujiXerox CT202137',
        productImg:
          'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4003395.jpg?imwidth=320',
        discountPercent: '-10%',
        productPrice: 990,
        productDiscount: 0,
        subCatagories: 'Printer & Supplies',
        productRating: 4,
      },
      {
        index: 3,
        productId: 'OFM4550005',
        productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
        productImg:
          'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
        productPrice: 7990,
        productDiscount: 0,
        subCatagories: 'Printer & Supplies',
        productRating: 4,
      },
      {
        index: 4,
        productId: 'OFM4005801',
        productName: 'มัลติฟังก์ชันอิงค์เจ็ท Canon Pixma G2010 ดำ',
        productImg:
          'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4005801.jpg?imwidth=320',
        productPrice: 3999,
        productDiscount: 3790,
        subCatagories: 'Printer & Supplies',
        productRating: 4,
      },
      {
        index: 5,
        productId: 'OFM4003288',
        productName: 'เครื่องปริ้นเตอร์เลเซอร์ Brother HL-1210W',
        productImg:
          'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4003288.jpg?imwidth=320',
        productPrice: 2590,
        productDiscount: 0,
        subCatagories: 'Printer & Supplies',
        productRating: 4,
      },
      {
        index: 6,
        productId: 'OFM4002326',
        productName: 'เครื่องปริ้นเตอร์เลเซอร์ Brother HL-1110',
        productImg:
          'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4002326.jpg?imwidth=320',
        productPrice: 1990,
        productDiscount: 0,
        subCatagories: 'Printer & Supplies',
        productRating: 4,
      },
      {
        index: 7,
        productId: 'OFM4006924',
        productName: 'เครื่องปริ้นเตอร์เลเซอร์ Canon LBP113w',
        productImg:
          'https://aumento.officemate.co.th/media/catalog/product/4/0/4006924_1.jpg?imwidth=320',
        productPrice: 4990,
        productDiscount: 0,
        subCatagories: 'Printer & Supplies',
        productRating: 4,
      },
      {
        index: 8,
        productId: 'OFM4007354',
        productName: 'เครื่องปริ้นเตอร์เลเซอร์ HP 1000w',
        productImg:
          'https://aumento.officemate.co.th/media/catalog/product/4/0/4007354.jpg?imwidth=320',
        productPrice: 8390,
        productDiscount: 0,
        subCatagories: 101,
        productRating: 4,
      },
      {
        index: 9,
        productId: 'OFM4003395',
        productName: 'ผงหมึก สีดำ FujiXerox CT202137',
        productImg:
          'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4003395.jpg?imwidth=320',
        discountPercent: '-10%',
        productPrice: 990,
        productDiscount: 0,
        subCatagories: 101,
        productRating: 4,
      },
      {
        index: 10,
        productId: 'OFM4550005',
        productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
        productImg:
          'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
        productPrice: 7990,
        productDiscount: 0,
        subCatagories: 101,
        productRating: 4,
      },
    ]
  },
  methods: {
    ...mapActions('cart', ['setCartCount']),
    ...mapActions('user', ['setUserProfile']),
    async getUserData() {
      await this.$profileProvider
        .getUserDataById(this.openId)
        .then((result) => {
          console.log(result)
          this.userData = result.data
          console.log('this.userProfile', this.userProfile)
          if (!this.userData.is_verify)
            this.$router.push({ path: this.$i18n.path('verify') })
          else {
            if (
              this.userProfile !== undefined &&
              Object.keys(this.userProfile).length
            ) {
              // case has seleced profile
              console.log('this.profileType', this.profileType)
              if (this.profileType === 'standard') {
                let account = result.data.accounts.find(
                  (q) => q.id === this.userProfile.id
                )
                console.log('account', account)
                this.setUserProfile({
                  payload: account,
                  profileType: 'standard',
                })
              } else if (this.profileType === 'eprocurement') {
                let company = result.data.companies.find(
                  (q) => q.id === this.userProfile.id
                )
                console.log('company', company)
                this.setUserProfile({
                  payload: company,
                  profileType: 'eprocurement',
                })
              }
            } else {
              if (
                this.userData.accounts.length ||
                this.userData.companies.length
              ) {
                if (
                  this.userData.accounts.length === 1 &&
                  this.userData.companies.length === 0
                ) {
                  // use account
                  this.setUserProfile({
                    payload: this.userData.accounts[0],
                    profileType: 'standard',
                  })
                } else if (
                  this.userData.accounts.length === 0 &&
                  this.userData.companies.length === 1
                ) {
                  //use company
                  this.setUserProfile({
                    payload: this.userData.companies[0],
                    profileType: 'eprocurement',
                  })
                } else {
                  //select account || company
                  this.$router.push({
                    path: this.$i18n.path('account/select-company'),
                  })
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    async getCartCount() {
      let responsed = await this.$shoppingCartProvider.getCartCount(
        this.cartId,
        this.userProfile.id
      )
      if (responsed.status === 200) this.setCartCount(responsed.data)
    },
  },
}
