import { render, staticRenderFns } from "./swiper-item.vue?vue&type=template&id=885569b4"
import script from "./swiper-item.vue?vue&type=script&lang=js"
export * from "./swiper-item.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ItemAddToCart: require('/usr/src/nuxt-app/components/share/item-add-to-cart.vue').default,SkuItemAddToCart: require('/usr/src/nuxt-app/components/share/sku-item-add-to-cart.vue').default,Item: require('/usr/src/nuxt-app/components/share/item.vue').default})
