
import ItemsAddToCartComponent from '@/components/share/item-add-to-cart.vue'
export default {
  name: 'ProductResult',
  components: {
    'item-add-to-cart': ItemsAddToCartComponent,
  },
  props: {
    filters: { type: Object, default: () => {} },
    view: { type: String, default: 'grid' },
    items: { type: Array, default: () => [] },
    listName: { type: String, default: '' },
    queryIdEventAlgolia: { type: String, default: '' },
    triggerEventAlgolia: { type: String, default: '' },
    indexEventAlgolia: { type: String, default: null },
    page: { type: Number, default: 0 },
    pageSize: { type: Number, default: 0 },
    showFlashSale: { type: Boolean, default: false },
    showOthers: { type: Boolean, default: true },
    gtmItemListId: { type: String, default: '' },
    gtmItemListName: { type: String, default: '' },
  },
  data() {
    return {}
  },
  // created() {
  //   this.data = [
  //     {
  //       index: 1,
  //       productId: 'OFM4007354',
  //       productName: 'เครื่องปริ้นเตอร์เลเซอร์ HP 1000w',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/4/0/4007354.jpg?imwidth=320',
  //       productPrice: 8390,
  //       productDiscount: 0,
  //       subCatagories: 'Printer & Supplies',
  //       productRating: 4,
  //     },
  //     {
  //       index: 2,
  //       productId: 'OFM4003395',
  //       productName: 'ผงหมึก สีดำ FujiXerox CT202137',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4003395.jpg?imwidth=320',
  //       discountPercent: '-10%',
  //       productPrice: 990,
  //       productDiscount: 0,
  //       subCatagories: 'Printer & Supplies',
  //       productRating: 4,
  //     },
  //     {
  //       index: 3,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 'Printer & Supplies',
  //       productRating: 4,
  //     },
  //     {
  //       index: 4,
  //       productId: 'OFM4005801',
  //       productName: 'มัลติฟังก์ชันอิงค์เจ็ท Canon Pixma G2010 ดำ',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4005801.jpg?imwidth=320',
  //       productPrice: 3999,
  //       productDiscount: 3790,
  //       subCatagories: 'Printer & Supplies',
  //       productRating: 4,
  //     },
  //     {
  //       index: 5,
  //       productId: 'OFM4003288',
  //       productName: 'เครื่องปริ้นเตอร์เลเซอร์ Brother HL-1210W',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4003288.jpg?imwidth=320',
  //       productPrice: 2590,
  //       productDiscount: 0,
  //       subCatagories: 'Printer & Supplies',
  //       productRating: 4,
  //     },
  //     {
  //       index: 6,
  //       productId: 'OFM4002326',
  //       productName: 'เครื่องปริ้นเตอร์เลเซอร์ Brother HL-1110',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4002326.jpg?imwidth=320',
  //       productPrice: 1990,
  //       productDiscount: 0,
  //       subCatagories: 'Printer & Supplies',
  //       productRating: 4,
  //     },
  //     {
  //       index: 7,
  //       productId: 'OFM4006924',
  //       productName: 'เครื่องปริ้นเตอร์เลเซอร์ Canon LBP113w',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/4/0/4006924_1.jpg?imwidth=320',
  //       productPrice: 4990,
  //       productDiscount: 0,
  //       subCatagories: 'Printer & Supplies',
  //       productRating: 4,
  //     },
  //     {
  //       index: 8,
  //       productId: 'OFM4007354',
  //       productName: 'เครื่องปริ้นเตอร์เลเซอร์ HP 1000w',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/4/0/4007354.jpg?imwidth=320',
  //       productPrice: 8390,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 9,
  //       productId: 'OFM4003395',
  //       productName: 'ผงหมึก สีดำ FujiXerox CT202137',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4003395.jpg?imwidth=320',
  //       discountPercent: '-10%',
  //       productPrice: 990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 10,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 11,
  //       productId: 'OFM4003395',
  //       productName: 'ผงหมึก สีดำ FujiXerox CT202137',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4003395.jpg?imwidth=320',
  //       discountPercent: '-10%',
  //       productPrice: 990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //     {
  //       index: 12,
  //       productId: 'OFM4550005',
  //       productName: 'เครื่องพิมพ์ดอทเมตริกซ์ Epson LQ-310',
  //       productImg:
  //         'https://aumento.officemate.co.th/media/catalog/product/O/F/OFM4550005.jpg?imwidth=320',
  //       productPrice: 7990,
  //       productDiscount: 0,
  //       subCatagories: 101,
  //       productRating: 4,
  //     },
  //   ]
  // },
  watch: {
    // items: {
    //   handler: function (val) {
    //     if (val.length) this.onGetOverlay()
    //   },
    //   deep: true,
    // },
  },
  methods: {
    positionOnPage(index) {
      return this.$eventInsightsAlgolia.calculatePositionAlgolia(
        index,
        this.page,
        this.pageSize
      )
    },
    // onGetOverlay() {
    //   // call api
    //   const itemsOverlay = [
    //     {
    //       id: '1009868',
    //       img: 'https://ofm-cdn0.ofm.co.th/images/logo/ofm-logo.png',
    //     },
    //     {
    //       id: '1091645',
    //       img: 'https://ofm-cdn0.ofm.co.th/images/logo/ofm-logo.png',
    //     },
    //   ]
    //   //emit image over to item-add-to-cart
    //   this.$nuxt.$emit('productOverlay', itemsOverlay)
    // },
  },
}
