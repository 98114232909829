
import BreadCrumb from '@/components/template/bread-crumb.vue'
import FilterPanel from '@/components/template/filter-panel.vue'
import FilterController from '@/components/share/filter-controller.vue'
import ProductResult from '@/components/template/product-result.vue'
import ProductPagination from '@/components/template/product-pagination.vue'
import SekletonResult from '@/components/skeleton/product-result.vue'
import { /* mapActions, */ mapState, mapGetters } from 'vuex'

import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'
export default {
  name: 'SpecialProduct',
  components: {
    breadcrumb: BreadCrumb,
    'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    'filter-controller': FilterController,
    'product-result': ProductResult,
    ProductPagination,
    'skeleton-result': SekletonResult,
    // 'swiper-image': SwiperImage,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('specialproducts.breadcrumbItemsTextHome'),
          href: this.$i18n.path(`account/information`),
        },
        {
          text: this.$t('specialproducts.breadcrumbItemsTextSpecialProducts'), //this.$route.params.name
          href: this.$i18n.path(`account/specialproducts`),
        },
      ],
      sortBy: [
        {
          text: this.$t('specialproducts.dropdownSortByDiscount'),
          value: '0',
        },
        {
          text: this.$t('specialproducts.dropdownPriceLowHigh'),
          value: '1',
        },
        {
          text: this.$t('specialproducts.dropdownPriceHighLow'),
          value: '2',
        },
        {
          text: this.$t('specialproducts.dropdownAZ'),
          value: '3',
        },
        {
          text: this.$t('specialproducts.dropdownZA'),
          value: '4',
        },
      ],
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      page: 1,
      perPage: 30,
      pageTotal: 0,
      condition: 'Funiture',
      filter: {},
      sort: '',
      view: 'grid',
      items: [],
      lang: '',
      attribute: [],
      paramAttribute: { attribute: [], price: [] },
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      isGetProduct: false,
      isGetAttribute: false,
      skeletonPage: true,
      firstTime: true,
    }
  },
  computed: {
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
  },
  watch: {
    // perPage: {
    //   handler: function () {
    //     console.log(this.perPage)
    //     this.getProduct()
    //   },
    //   deep: true,
    // },
  },
  async created() {
    this.lang = this.$store.state.locale
  },
  async mounted() {
    this.showItems = false
    let url = new URL(window.location.href).searchParams.toString().length
    this.firstTime = url === 0

    if (this.firstTime) {
      await this.getProduct()
      this.showItems = true
    }
    // setTimeout(async () => {
    //   let url = new URL(window.location.href).searchParams.toString().length
    //   this.firstTime = url === 0
    //   if (this.firstTime) {
    //     await this.getProduct()
    //     this.showItems = true
    //   }
    // }, 100)
    this.getAttribute()
  },
  updated() {
    console.log('skeletonPage', this.skeletonPage)
    this.skeletonPage = false
  },
  methods: {
    async onPagination(page) {
      // console.log(page)
      this.page = page
      await this.getProduct()
      this.showItems = true
    },
    async onChangePage(val) {
      this.perPage = val
      await this.getProduct()
      this.showItems = true
    },
    // async emitAttribute(value) {
    //   this.paramAttribute = value
    //   await this.getProduct()
    // },
    async selectSort(e) {
      if (this.showItems) {
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCT
        if (e.value === '0') this.sort = ''
        else if (e.value === '1') {
          this.sort = 'price-ascending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEASC
        } else if (e.value === '2') {
          this.sort = 'price-descending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEDESC
        } else if (e.value === '3') this.sort = 'new-descending'
        else this.sort = 'old-ascending'
        console.log('selectSort')
        await this.getProduct()
        this.showItems = true
      }
    },
    // selectSort(e) {
    //   // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
    //   if (e.value === '0') this.sort = ''
    //   else if (e.value === '1') this.sort = 'price-ascending'
    //   else if (e.value === '2') this.sort = 'price-descending'
    //   else if (e.value === '3') this.sort = 'new-descending'
    //   else this.sort = 'old-ascending'
    //   this.getProduct()
    // },
    async getProduct() {
      console.log('start getproduct')
      this.showItems = false
      this.isGetProduct = false

      /* เรียก func เพื่อสร้าง พารามิเตอร์ */
      let params = await this.createParams()
      const response = await this.$productProvider.getProductSpecialCatalogList(
        params
      )
      console.log('getProductSpecialCatalogList', response)
      if (response.status === 200) {
        this.pageTotal = response.data.totalRecords
        this.items = response.data.items
        console.log('this.items', this.items)
      }
      this.isGetProduct = true
    },
    async getAttribute() {
      console.log('start getAttribute')
      this.isGetAttribute = false
      let attr = []
      let params = await this.createBrandAndAttributeParams()
      const response =
        await this.$productProvider.getBrandProductSpecialCatalog(params)
      console.log('getBrandProductSpecialCatalog', response)
      if (response.status === 200) {
        let brandList = []
        response.data.forEach((element) => {
          brandList.push({
            text: element.display_name[this.lang],
            value: { by: 'brand', value: element.id },
          })
        })

        attr.push({
          title: this.$t('specialproducts.labelTitleBrandAttribute'),
          list: brandList,
        })
      }

      const responseAttribute =
        await this.$productProvider.getAttributeProductSpecialCatalog(params)
      console.log('getAttributeProductSpecialCatalog', responseAttribute)
      if (responseAttribute.status === 200) {
        const attribute = responseAttribute.data
        // console.log('attr', attribute)
        /* เพื่อจัดรูปของข้อมูล ให้อยู่ในรูปที่ component filter-panel สามารถนำไป render สร้างเป็น filter ด้านซ้ายของเว็บไซต์ */
        attribute.forEach((element) => {
          let attributeList = []
          if (this.$utils.anyArrayObjectValid(element.value)) {
            element.value.forEach((value, index) => {
              if (
                this.$utils.isObjectValid(value) &&
                this.$utils.isValid(value[this.lang]) &&
                !attributeList.some((q) => q.value.value === value[this.lang])
              ) {
                attributeList.push({
                  text: value[this.lang],
                  value: {
                    index: index,
                    by: element.display_name[this.lang],
                    id: element.id,
                    value: value[this.lang],
                  },
                })
              }
            })
          }
          if (this.$utils.anyArrayObjectValid(attributeList)) {
            attr.push({
              title: element.display_name[this.lang],
              list: attributeList,
            })
          }
        })
      }
      this.isGetAttribute = true
      console.log('attribute', attr)
      this.attribute = attr
      this.skeletonPage = false
    },
    async emitAttribute(value) {
      this.paramAttribute = value
      console.log('emitAttribute', this.firstTime)
      // await this.getProduct()
      // setTimeout(async () => {
      if (!this.firstTime) {
        await this.getProduct()
        this.showItems = true
      }
      this.firstTime = false
      // }, 100)
    },
    async createBrandAndAttributeParams() {
      let params = new URLSearchParams()
      params.append('userOpenId', this.cartId)

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('isFree', false)
      return params
    },
    async createParams() {
      let params = new URLSearchParams()
      params.append('userOpenId', this.cartId)

      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      params.append('sort', this.sort)
      /* วนเพื่อสร้าง param brand ตามค่าที่เราเลือก */
      if (this.paramAttribute.attribute.length !== 0) {
        this.paramAttribute.attribute.forEach((data) => {
          data.by === 'brand'
            ? params.append('brands', data.value)
            : params.append('attributes', data.id + '-' + data.value)
        })
      }
      /* สร้าง params ราคา max min ในกรณีที่มีการ กำหนดค่า */
      console.log('this.paramAttribute', this.paramAttribute)
      if (this.paramAttribute.price.length !== 0) {
        params.append('min', this.paramAttribute.price[0])
        params.append('max', this.paramAttribute.price[1])
      }

      params.append('isFree', false)
      return params
    },
  },
}
