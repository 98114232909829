
import { mapGetters } from 'vuex'

export default {
  name: 'TableReportCategory',
  props: {
    items: { type: Array, default: () => [] },
    itemCount: { type: Number, default: () => 0 },
    level: { type: Number, default: () => 0 },
  },
  data() {
    return {
      search: '',
      //itemCount: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      fields: [
        {
          key: 'index',
          label: this.$t(`company-report-category.labelNo`),
          class: 'text-center w-70',
          sortable: false,
        },
        {
          key: 'company_code',
          label: this.$t(`company-report-category.labelCompanyID`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },

        {
          key: 'account_offline_id',
          label: this.$t(`company-report-category.labelCustomerID`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'display_name_th',
          label: 'หมวดหมู่สินค้า (TH)',
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'display_name_en',
          label: 'หมวดหมู่สินค้า (EN)',
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'department_code',
          label: this.$t(`company-report-category.labelDepartmentID`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'department_name_th',
          label: this.$t(`company-report-category.labelDepartmentName`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'costcenter_code',
          label: this.$t(`company-report-category.labelDivisionID`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'costcenter_name_th',
          label: this.$t(`company-report-category.labelDivisionName`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'contact_email',
          label: this.$t(`company-report-category.labelPurchaserEmail`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },

        {
          key: 'contact_name_th',
          label: this.$t(`company-report-category.labelPurchaser`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },

        // {
        //   key: 'email',
        //   label: this.$t(`company-report-category.labelApproverEmail`),
        //   thClass: '',
        //   tdClass: '',
        //   sortable: false,
        // },
        // {
        //   key: 'approver_name',
        //   label: this.$t(`company-report-category.labelApprover`),
        //   thClass: '',
        //   tdClass: '',
        //   sortable: false,
        // },
        // {
        //   key: 'email',
        //   label: 'อีเมลผู้อนุมัติ',
        //   thClass: '',
        //   tdClass: '',
        //   sortable: false,
        // },
        // {
        //   key: 'approver_name',
        //   label: 'ชื่อผู้อนุมัติ',
        //   thClass: '',
        //   tdClass: '',
        //   sortable: false,
        // },

        {
          key: 'order_id',
          label: this.$t(`company-report-category.labelPONumber`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'order_date',
          label: this.$t(`company-report-category.labelPODate`),
          thClass: '',
          tdClass: '',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatDateTime(value)
          },
        },
        {
          key: 'order_status',
          label: this.$t(`company-report-category.labelPOStatus`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'reference_document_no',
          label: this.$t(`company-report-category.labelRef`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'approval_date',
          label: this.$t(`company-report-category.labelApprovalDate`),
          thClass: '',
          tdClass: '',
          sortable: false,
          formatter: (value, key, item) => {
            if (value === '1969/12/31 17:00:00') {
              return ''
            }
            if (value === '1970/01/01 00:00:00') {
              return ''
            }
            return this.$utils.formatDateTime(value)
          },
        },
        {
          key: 'sku_code',
          label: this.$t(`company-report-category.labelSku`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'product_name_th',
          label: this.$t(`company-report-category.labelPNameTH`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'product_name_en',
          label: this.$t(`company-report-category.labelPNameEN`),
          thClass: '',
          tdClass: '',
          sortable: false,
        },
        {
          key: 'sale_unit_th',
          label: this.$t(`company-report-category.labelUnitTH`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'sale_unit_en',
          label: this.$t(`company-report-category.labelUnitEN`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'quantity',
          label: this.$t(`company-report-category.labelQTY`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          key: 'price_excvat',
          label: this.$t(`company-report-category.labelPriceExcVat`),
          thClass: '',
          tdClass: 'text-right',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatPrice(value, 2)
          },
        },
        {
          key: 'total_amt_excvat',
          label: this.$t(`company-report-category.labelTotalExcVat`),
          thClass: '',
          tdClass: 'text-right',
          sortable: false,
          formatter: (value, key, item) => {
            return this.$utils.formatPrice(value, 2)
          },
        },
        {
          key: 'reference_invoice',
          label: this.$t(`company-report-category.labelRefInvoice`),
          thClass: '',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    pageTo() {
      let first = ''
      let last = ''
      first = (this.currentPage - 1) * this.perPage + 1
      if (this.currentPage === Math.ceil(this.itemCount / this.perPage)) {
        last = this.itemCount
      } else {
        last = this.currentPage * this.perPage
      }
      if (this.itemCount === 0) {
        first = 0
        last = 0
      } else if (this.itemCount < this.perPage) {
        last = this.itemCount
      }
      return first + '-' + last
    },
  },
  // watch: {
  //   items: {
  //     handler: function (val) {
  //       this.itemCount = val.length
  //     },
  //     deep: false,
  //   },
  // },
  methods: {
    onFiltered(filteredItems) {
      this.itemCount = filteredItems.length
      this.currentPage = 1
    },
    onPreview() {
      //this.$nextTick(() => {
      if (this.currentPage <= Math.ceil(this.itemCount / this.perPage)) {
        this.currentPage++
        this.$emit('getCateriesReport', this.currentPage, false)
      }
      //})
    },
    onLast() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.$emit('getCateriesReport', this.currentPage, false)
      }
    },
    orderDetail(id) {
      let order_id = this.items.find((a) => a.order_id === id)?.id
      return this.$i18n.path(`account/order/${order_id}?mode=view`)
    },
    fieldsFilter() {
      let fields = [...this.fields]
      if (this.level === 2) {
        fields = fields.filter(
          (field) =>
            field.key !== 'department_code' &&
            field.key !== 'department_name_th'
        )
      }
      console.log('fields', fields)
      return fields
    },
  },
}
