import { render, staticRenderFns } from "./product-result.vue?vue&type=template&id=1dc945c1"
import script from "./product-result.vue?vue&type=script&lang=js"
export * from "./product-result.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ItemAddToCart: require('/usr/src/nuxt-app/components/share/item-add-to-cart.vue').default})
