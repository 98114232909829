
export default {
  name: 'SpecialDealComponent',
  props: {
    updateInfo: {
      type: String,
      default: '',
    },
    urlParameter: {
      type: String,
      default: '',
    },
    bannerSpecialDeal: {
      type: Array,
      default: Array,
    },
    items: {
      type: Array,
      default: Array,
    },
    triggerEventAlgolia: { type: String, default: '' },
    indexEventAlgolia: { type: String, default: null },
  },
  watch: {
    bannerSpecialDeal: {
      immediate: true,
      handler: function (val) {
        console.log('bannerSpecialDeal', val)
        if (val.length) {
          this.imgTitle = val.filter((image) => image.sequence === 0)
          this.imgBanner = val.filter((image) => image.sequence !== 0)
        }
        console.log('bannerSpecialDeal imgTitle', this.imgTitle)
        console.log('bannerSpecialDeal imgBanner', this.imgBanner)
      },
      deep: true,
    },
  },
  data() {
    return {
      imgTitle: {},
      imgBanner: {},
    }
  },
}
