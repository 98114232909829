
import BreadCrumb from '@/components/template/bread-crumb.vue'
import FilterPanel from '@/components/template/filter-panel.vue'
import FilterController from '@/components/share/filter-controller.vue'
import ProductResult from '@/components/template/product-result.vue'
import ProductPagination from '@/components/template/product-pagination.vue'
import { /* mapActions, */ mapState, mapGetters } from 'vuex'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'
import SekletonResult from '@/components/skeleton/product-result.vue'

export default {
  name: 'NewArrivalsProductsPage',
  components: {
    breadcrumb: BreadCrumb,
    'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    'filter-controller': FilterController,
    'product-result': ProductResult,
    ProductPagination,
    'skeleton-result': SekletonResult,
    // 'swiper-image': SwiperImage,
  },
  head() {
    return {
      // title: this.namepage,
      // meta: [
      //   {
      //     hid: 'description',
      //     name: 'description',
      //     content: this.pageContent,
      //   },
      // ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href:
      //       `https://www.ofm.co.th` +
      //       this.$i18n.path(`widget/${this.$route.params.name}`),
      //   },
      // ],
      script: [
        {
          // Breadcrumb Rich Snippet
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: this.breadcrumbItems[0].text, //Main index
                item: `https://www.ofm.co.th`,
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: this.$route.params.name, // widget
              },
            ],
          },
        },
      ],
    }
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      namepage: '',
      sortBy: [
        {
          text: 'Default...',
          value: '0',
        },
        {
          text: 'PRICE LOW > HIGH',
          value: '1',
        },
        {
          text: 'PRICE HIGH > LOW',
          value: '2',
        },
        {
          text: 'A > Z',
          value: '3',
        },
        {
          text: 'Z > A',
          value: '4',
        },
      ],
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      page: 1,
      perPage: 30,
      pageTotal: 0,
      condition: 'Funiture',
      filter: {},
      sort: '',
      view: 'grid',
      items: [],
      lang: '',
      attribute: [],
      paramAttribute: { attribute: [], price: [] },
      totalCount: '',
      display: '',
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      skeleton: false,
      isGetAttribute: false,
      skeletonPage: true,
      isNotFound: false,
      showItems: false,
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    breadcrumbItems() {
      return [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: this.namepage,
        },
      ]
    },
    // generateListName() {
    //   let name = ''
    //   switch (this.$route.params.name) {
    //     case 'new-arrival':
    //       name = this.$t(`widget.newtext`)
    //       break
    //     case 'featured-product':
    //       name = 'Featured Productss'
    //       break
    //     case 'promotion':
    //       name = 'Promotion'
    //       break
    //     default:
    //       name = 'Best Sellers'
    //       break
    //   }
    //   return name
    // },
  },
  watch: {
    perPage: {
      handler: function () {
        console.log(this.perPage)
        this.getProduct()
      },
      deep: true,
    },
  },
  updated() {
    this.skeletonPage = false
    this.$_onGetInsider({
      page: {
        type: 'Other',
        custom: {
          category_name: null,
        },
      },
    })
    this.$_onInsiderResult({
      result: [...(this.items || [])],
    })
  },
  created() {
    // this.$nextTick(() => {
    // this.getAttributeProductHomePage()
    // })
  },
  async mounted() {
    this.selectName()
    console.log('xxxxxxxxxxxxxxxxxxxxxxxxx')
    console.log(this.$route.params.name)
    this.lang = this.$store.state.locale
    // await this.getProduct()
    await this.getAttributeProductHomePage()
    // this.attribute = await Object.assign([], [...new Set(this.attribute)])
    console.log('this.attribute', this.attribute)
  },
  methods: {
    async onPagination(page) {
      // console.log(page)
      this.page = page
      await this.getProduct()
      this.showItems = true
    },
    async onChangePage(val) {
      this.perPage = val
      await this.getProduct()
      this.showItems = true
    },
    // async emitAttribute(value) {
    //   this.paramAttribute = value
    // },
    async selectSort(event) {
      if (this.showItems) {
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCT
        if (event.value === '0') this.sort = ''
        else if (event.value === '1') {
          this.sort = 'price-ascending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEASC
        } else if (event.value === '2') {
          this.sort = 'price-descending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEDESC
        } else if (event.value === '3') {
          this.sort = 'new-descending-' + this.lang
        } else this.sort = 'old-ascending-' + this.lang
        await this.getProduct()
        this.showItems = true
      }
    },
    async getProduct() {
      this.skeleton = false
      this.showItems = false
      console.log('start getproduct : ', this.sort)
      /* เรียก func เพื่อสร้าง พารามิเตอร์ */
      let params = await this.createBrandAndAttributeParams()
      console.log('params', params.toString())
      var response = ''
      if (this.$route.params.name === 'recommend-for-you') {
        response = await this.$productProvider.getRecommendProductsSeeAll(
          params
        )
      } else {
        response = await this.$productProvider.getWidgetHomePageProductsSeeAll(
          params
        )
      }

      console.log('response.status', response.status)
      if (response.status === 200) {
        if (response.data.totalRecords === undefined) {
          this.isNotFound = true
          this.skeletonPage = false
        }
        this.pageTotal = response.data.totalRecords
        this.items = response.data.items
        if (this.items !== null && this.items.length) {
          // ทำ seq เพื่อส่งเข้า gtm event impression
          let startIndex =
            (response.data.pageNo - 1) * response.data.pageSize + 1
          this.items.map((item, index) => {
            item.seq = index + startIndex
          })
          //gtm
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'impression',
            currencyCode: 'THB',
            ecommerce: {
              impressions: this.items.map((item) => {
                return {
                  id: item.skus[0].sku_code,
                  price: this.$utils.formatPrice(
                    item.skus[0].price_include_vat,
                    2,
                    true
                  ),
                  list:
                    this.$route.params.name === 'new-arrival'
                      ? this.$t(`widgetPage.new_arrival`)
                      : this.$route.params.name === 'featured-product'
                      ? this.$t(`widgetPage.trending_products`)
                      : this.$route.params.name === 'promotion'
                      ? 'Promotion'
                      : 'Best Sellers',
                  position: item.seq,
                }
              }),
            },
          })

          // gtm GA4
          const itemList = {
            'best-seller': {
              id: 'best_sellers',
              name: 'Best Sellers',
            },
            'new-arrival': {
              id: 'new_arrival',
              name: 'New Arrival',
            },
            promotion: {
              id: 'promotions',
              name: 'Promotions',
            },
            'featured-product': {
              id: 'featured_product',
              name: 'Featured Product',
            },
            'recommend-for-you': {
              id: 'recommend_for_you',
              name: 'Recommended',
            },
          }
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'view_item_list',
            ecommerce: {
              user: this.$store.getters['user/userInfoTrack'],
              item_list_id: itemList[this.$route.params.name].id || '',
              item_list_name: itemList[this.$route.params.name].name || '',
              items: this.items.map((item, index) => {
                const sku = item.skus[0]
                return {
                  index: index,
                  item_id: sku.sku_code,
                  item_name: sku.product_names[1]?.display_name.th,
                  price: sku.price_include_vat,
                  discount:
                    sku.retail_price.include_vat - sku.price_include_vat,
                  item_list_id: itemList[this.$route.params.name].id || '',
                  item_list_name: itemList[this.$route.params.name].name || '',
                  ...this.$store.getters['user/userPromotionTrack'],
                }
              }),
            },
          })
        }
        console.log('this.items', this.items)
      }
      this.skeleton = true
    },
    async getAttributeProductHomePage() {
      let attr = []
      this.isGetAttribute = false
      let params = await this.createParams()

      // get category
      const responseCategory =
        await this.$productProvider.getwidgetCategoryData(params)
      if (responseCategory.status === 200) {
        console.log('responseCategory', responseCategory.data)
        let categoryList = []
        responseCategory.data.forEach((element) => {
          categoryList.push({
            text: element.display_name[this.lang],
            value: { by: 'category', value: element.category_id },
          })
        })

        attr.push({
          title: this.$t('filterPanelComponent.category'),
          list: categoryList,
        })
        console.log('categoryList', categoryList)
      }

      // get brand
      var response = ''
      if (this.$route.params.name === 'recommend-for-you') {
        response = await this.$productProvider.getRecommendBrandsData(params)
      } else {
        response = await this.$productProvider.getwidgetBrandsData(params)
      }
      // const response = await this.$productProvider.getwidgetBrandsData(params)
      if (response.status === 200) {
        let brandList = []
        console.log(response.data)
        response.data.forEach((element) => {
          brandList.push({
            text: element.display_name[this.lang],
            value: { by: 'brand', value: element.id },
          })
        })

        attr.push({
          title: this.$t('filterPanelComponent.brand'),
          list: brandList,
        })
      }
      // get attribute
      var responseAttribute = ''
      if (this.$route.params.name === 'recommend-for-you') {
        responseAttribute =
          await this.$productProvider.getRecommendAttributeProductHomeData(
            params
          )
      } else {
        responseAttribute =
          await this.$productProvider.getAttributeProductHomeData(params)
      }
      // const responseAttribute =
      //   await this.$productProvider.getAttributeProductHomeData(params)
      if (responseAttribute.status === 200) {
        const attribute = responseAttribute.data
        // console.log('attr', attribute)
        /* เพื่อจัดรูปของข้อมูล ให้อยู่ในรูปที่ component filter-panel สามารถนำไป render สร้างเป็น filter ด้านซ้ายของเว็บไซต์ */
        attribute.forEach((element) => {
          let attributeList = []
          if (this.$utils.anyArrayObjectValid(element.value)) {
            element.value.forEach((value, index) => {
              if (
                this.$utils.isObjectValid(value) &&
                this.$utils.isValid(value[this.lang]) &&
                !attributeList.some((q) => q.value.value === value[this.lang])
              ) {
                attributeList.push({
                  text: value[this.lang],
                  value: {
                    index: index,
                    by: element.display_name[this.lang],
                    id: element.id,
                    value: value[this.lang],
                  },
                })
              }
            })
          }
          if (this.$utils.anyArrayObjectValid(attributeList)) {
            attr.push({
              title: element.display_name[this.lang],
              list: attributeList,
            })
          }
        })
      }
      this.attribute = attr
      this.isGetAttribute = true
      this.skeleton = true
      console.log('getattr', this.attribute)
    },
    async emitAttribute(value) {
      this.paramAttribute = value
      console.log('this.paramAttribute-', this.paramAttribute)
      await this.getProduct()
      this.showItems = true
    },
    async createParams() {
      let params = new URLSearchParams()
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      if (this.$route.params.name === 'new-arrival') {
        params.append('type', 'NewArrivals')
      }
      if (this.$route.params.name === 'best-seller') {
        params.append('type', 'BestSeller')
      }
      if (this.$route.params.name === 'featured-product') {
        params.append('type', 'FeaturedProducts')
      }
      if (this.$route.params.name === 'recommend-for-you') {
        params.append('type', 'Recommended')
        params.append('userOpenId', this.openId)
      }
      if (this.$route.params.name === 'promotion') {
        params.append('type', 'Promotion')
      }
      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)
      params.append('isFree', false)
      console.log('router', this.$route)

      return params
    },
    // selectSort(e) {
    //   // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
    //   if (e.value === '0') this.sort = ''
    //   else if (e.value === '1') this.sort = 'price-ascending'
    //   else if (e.value === '2') this.sort = 'price-descending'
    //   else if (e.value === '3') this.sort = 'new-descending'
    //   else this.sort = 'old-ascending'
    //   this.getProduct()
    // },
    async createBrandAndAttributeParams() {
      let params = new URLSearchParams()

      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      if (this.$route.params.name === 'new-arrival') {
        params.append('type', 'NewArrivals')
      }
      if (this.$route.params.name === 'best-seller') {
        params.append('type', 'BestSeller')
      }
      if (this.$route.params.name === 'featured-product') {
        params.append('type', 'FeaturedProducts')
      }
      if (this.$route.params.name === 'recommend-for-you') {
        params.append('type', 'Recommended')
        params.append('userOpenId', this.openId)
      }
      if (this.$route.params.name === 'promotion') {
        params.append('type', 'Promotion')
      }
      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      params.append('sort', this.sort)
      /* วนเพื่อสร้าง param category ตามค่าที่เราเลือก */
      /* วนเพื่อสร้าง param brand ตามค่าที่เราเลือก */
      if (this.paramAttribute.attribute.length !== 0) {
        this.paramAttribute.attribute.forEach((data) => {
          if (data.by === 'brand') {
            params.append('brands', data.value)
          } else if (data.by === 'category') {
            params.append('categories', data.value)
          } else {
            params.append('attributes', data.id + '-' + data.value)
          }
        })
      }
      /* สร้าง params ราคา max min ในกรณีที่มีการ กำหนดค่า */
      console.log('this.paramAttribute', this.paramAttribute)
      if (this.paramAttribute.price.length !== 0) {
        params.append('min', this.paramAttribute.price[0])
        params.append('max', this.paramAttribute.price[1])
      }

      params.append('isFree', false)
      return params
    },
    selectName() {
      switch (this.$route.params.name) {
        case 'new-arrival':
          this.namepage = this.$t(`widgetPage.new_arrival`)
          break
        case 'best-seller':
          this.namepage = this.$t(`widgetPage.best_sellers`)
          break
        case 'featured-product':
          this.namepage = this.$t(`widgetPage.trending_products`)
          break
        case 'recommend-for-you':
          this.namepage = this.$t(`widgetPage.recommed_products`)
          break
        case 'promotion':
          this.namepage = this.$t(`index.labelPromotion`)
          break
        default:
          this.namepage = this.$route.params.name
          break
      }
      console.log('namepage', this.namepage)
    },
  },
}
