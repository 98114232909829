
import InputText from '@/components/share/input-text.vue'

import Consent from '@/components/share/consent.vue'
// jwtDecode from 'jwt-decode'
export default {
  layout: 'blank-nav',
  name: 'NewPassword',
  components: {
    'input-text': InputText,
    consent: Consent,
  },
  data() {
    return {
      reloadPassword: true,
      dataForNewPassword: {
        password: '',
        rePassword: '',
      },
      verifyAccessToken: '',
      userOpenId: '',
      email: '',
    }
  },
  computed: {
    confirmation() {
      return this.dataForNewPassword.password !==
        this.dataForNewPassword.rePassword
        ? true
        : false
    },
  },
  watch: {
    // 'dataForNewPassword.rePassword': function (value) {
    //   if (
    //     value !== this.dataForNewPassword.password &&
    //     this.dataForNewPassword.password !== ''
    //   ) {
    //     this.confirmation = true
    //     this.fieldPassword = 'รหัสผ่าน'
    //     this.fieldRePassword = 'รหัสผ่าน'
    //   } else {
    //     this.confirmation = false
    //     this.fieldPassword = 'รหัสผ่าน'
    //     this.fieldRePassword = 'ยืนยันรหัสผ่าน'
    //   }
    // },
    // 'dataForNewPassword.password': function (value) {
    //   if (
    //     value !== this.dataForNewPassword.rePassword &&
    //     this.dataForNewPassword.rePassword !== ''
    //   ) {
    //     this.confirmation = true
    //     this.fieldPassword = 'รหัสผ่าน'
    //     this.fieldRePassword = 'รหัสผ่าน'
    //   } else {
    //     this.confirmation = false
    //     this.fieldPassword = 'รหัสผ่าน'
    //     this.fieldRePassword = 'ยืนยันรหัสผ่าน'
    //   }
    // },
  },
  async mounted() {
    if (
      Object.keys(this.$route.query).length &&
      this.$utils.isValid(this.$route.query.code)
    ) {
      let sp = this.$route.query.code.split('_')
      await this.verifyCode(sp[0], sp[1])
    } else this.$router.push({ path: this.$i18n.path('forgotpassword') })
  },
  methods: {
    async onSubmit() {
      this.$refs.passwordVerifyForm.validate().then(async (result) => {
        if (result) {
          // await console.log(this.dataForNewPassword)
          await this.setNewPassword()
          this.createConsentStatus()
        }
      })
    },
    async verifyCode(open_id, code) {
      const result = await this.$authenProvider.verifyOTP(open_id, code)
      console.log('result', result)
      if (result.status === 201) {
        this.verifyAccessToken = result.data['access_token']
        this.userOpenId = result.data['open_id']
      } else if (result.status === 404) {
        this.$router.push({ path: this.$i18n.path('forgotpassword') })
      }
    },
    async setNewPassword() {
      // decode = jwtDecode(this.verifyAccessToken)
      const password = this.$utils.cryptoJS(this.dataForNewPassword.password)
      let apiData = Object.assign({
        access_token: this.verifyAccessToken,
        password: password,
        open_id: this.userOpenId,
      })
      const result = await this.$authenProvider.updatePassword(apiData)
      if (result.status === 204) {
        this.$bvToast.toast('เปลี่ยนรหัสผ่านสำเร็จ', {
          title: `Success`,
          variant: 'success',
          solid: true,
        })
        this.$router.push({ path: this.$i18n.path('/') })
      }
    },
    createConsentStatus() {
      let payload = JSON.stringify({
        //email: this.userInfo.email,
        user_open_id: this.userOpenId,
        consent_status: this.consentStatus,
      })
      this.$profileProvider.createUserConsentStatus(payload)
    },
  },
}
