
import BreadCrumb from '@/components/template/bread-crumb.vue'

import FilterPanel from '@/components/template/filter-panel.vue'
import FilterController from '@/components/share/filter-controller.vue'

// import SelectDropdown from '@/components/share/select-dropdown.vue'
import ProductResult from '@/components/template/product-result.vue'
import ProductPagination from '@/components/template/product-pagination.vue'
import { /* mapActions, */ mapState, mapGetters } from 'vuex'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'
import SekletonResult from '@/components/skeleton/product-result.vue'
import notfound from '@/components/share/notfound.vue'

export default {
  name: 'CategoryLevel3',
  components: {
    breadcrumb: BreadCrumb,
    'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    'filter-controller': FilterController,
    'product-result': ProductResult,
    ProductPagination,
    'skeleton-result': SekletonResult,
    notfound,
  },
  head() {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageContent,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageContent,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogImage,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href:
            `https://www.ofm.co.th` +
            this.$i18n.path(
              `category/${this.department_title}/${this.category_title}/${this.subcategory_title}`
            ),
        },
      ],
      script: [
        {
          // Breadcrumb Rich Snippet
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: this.$nuxt._route.params.department, //Main Category
                item:
                  `https://www.ofm.co.th` +
                  this.$i18n.path(`category/${this.$route.params.department}`),
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: this.$nuxt._route.params.category, //Tier 2 Sub-Category
                item:
                  `https://www.ofm.co.th` +
                  this.$i18n.path(
                    `category/${this.$route.params.department}/${this.$route.params.category}`
                  ),
              },
              {
                '@type': 'ListItem',
                position: 3,
                name: this.$nuxt._route.params.subcategory, //Tier 3 Sub-Category
              },
            ],
          },
        },
      ],
    }
  },
  // async fetch() {
  //   if (process.server) {
  //     await this.getCategoriesFromLayout()
  //   }
  // },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'category',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  async asyncData({ route, app, store, $axios }) {
    let pageTitle = `${app.i18n.t('meta.defaultTitle')}`
    let pageContent = `${app.i18n.t('meta.description')}`
    let ogImage = 'https://ofm-cdn0.ofm.co.th/images/default-og.png'
    let catL_1 = {}
    let catL_2 = {}
    let catL_3 = {}

    let department_title = ''
    let category_title = ''
    let subcategory_title = ''

    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
    }
    const params = {
      seo_name_level2: route.params.category,
      seo_name_level3: route.params.subcategory,
    }
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    console.log('http-server', http)
    // axios
    try {
      const getCategoryMeta = await $axios.get(
        encodeURI(
          `${http}${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Categories/${route.params.department}/meta/`
        ),
        {
          params,
          headers,
        }
      )
      console.log('getCategoryMeta', getCategoryMeta)
      if (getCategoryMeta.status === 200) {
        const categories = getCategoryMeta.data.categories

        catL_1 = categories.find((q) => q.level === 1)
        catL_2 = categories.find((q) => q.level === 2)
        catL_3 = categories.find((q) => q.level === 3)
        // console.log('catL_1', catL_1)

        let otherLang = store.state.locale === 'th' ? 'en' : 'th'
        // PLP 3
        // default name
        department_title = catL_1.meta.seo_name[store.state.locale]
        category_title = catL_2.meta.seo_name[store.state.locale]
        subcategory_title = catL_3.meta.seo_name[store.state.locale]
        let pathName = catL_3.display_name[store.state.locale]

        if (app.$utils.isObjectValid(catL_3.meta)) {
          if (app.$utils.isObjectValid(catL_3.meta.title_tag)) {
            let titleTag = catL_3.meta.title_tag
            pageTitle = app.$utils.isValid(titleTag[store.state.locale])
              ? titleTag[store.state.locale]
              : titleTag[otherLang]
          } else {
            // default pageTitle
            pageTitle = `${pathName}`
          }

          if (app.$utils.isObjectValid(catL_3.meta.description_tag)) {
            let descriptionTag = catL_3.meta.description_tag
            pageContent =
              // app.i18n.t('meta.descShop') +
              (app.$utils.isValid(descriptionTag[store.state.locale])
                ? descriptionTag[store.state.locale]
                : descriptionTag[otherLang])
          } else {
            // default pageContent
            pageContent = `${app.i18n.t('meta.descShop')}${pathName}`
          }
        }
      }
    } catch (e) {
      console.log('catch', e)
    }
    return {
      pageTitle: pageTitle,
      pageContent: pageContent,
      ogImage: ogImage,
      asyncCateIdLevelOne: catL_1.id,
      asyncCateIdLevelTwo: catL_2.id,
      asyncCateIdLevelThree: catL_3.id,
      department_title: department_title,
      category_title: category_title,
      subcategory_title: subcategory_title,
    }
  },
  data() {
    return {
      asyncCateIdLevelOne: '',
      asyncCateIdLevelTwo: '',
      asyncCateIdLevelThree: '',
      breadcrumbItems: [
        {
          text: this.$nuxt._route.params.department,
          href: this.$i18n.path(`category/${this.$route.params.department}`),
        },
        {
          text: this.$nuxt._route.params.category,
          href: this.$i18n.path(
            `category/${this.$route.params.department}/${this.$route.params.category}`
          ),
        },
        {
          text: this.$nuxt._route.params.subcategory,
          href: this.$i18n.path(
            `category/${this.$route.params.department}/${this.$route.params.category}/${this.$route.params.subcategory}`
          ),
        },
      ],
      sortBy: [
        {
          text: this.$t(
            'category_department_category_subcategory.dropdownSortByDiscount'
          ),
          value: '0',
        },
        {
          text: this.$t(
            'category_department_category_subcategory.dropdownPriceLowHigh'
          ),
          value: '1',
        },
        {
          text: this.$t(
            'category_department_category_subcategory.dropdownPriceHighLow'
          ),
          value: '2',
        },
        {
          text: this.$t('category_department_category_subcategory.dropdownAZ'),
          value: '3',
        },
        {
          text: this.$t('category_department_category_subcategory.dropdownZA'),
          value: '4',
        },
      ],
      page: 1,
      perPage: 30,
      pageTotal: 0,
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      condition: 'Funiture',
      filter: {},
      sort: '',
      view: 'grid',
      items: [],
      lang: '',
      attribute: [],
      paramAttribute: { attribute: [], price: [] },
      nameLevelThree: '',
      seoNameLevelOne: '',
      seoNameLevelTwo: '',
      seoNameLevelThree: '',
      cateIdLevelOne: '',
      cateIdLevelTwo: '',
      cateIdLevelThree: '',
      startDate: '',
      pageTitle: '',
      pageContent: '',
      filterProductStatus: ['Inactive', 'Delete'],
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      // skeletonItems: true,
      skeletonPage: true,
      isNotFound: false,
      showItems: false,
      categoryContent: '',
      showContent: false,
      firstTime: true,
      showCategoryContent: false,
    }
  },
  computed: {
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    isItems() {
      return (
        this.$utils.anyArrayObjectValid(this.attribute) ||
        this.$utils.anyArrayObjectValid(this.items)
      )
    },
  },
  watch: {
    async cateIdLevelThree() {
      this.setTitle()
      // this.getProduct()
      await this.onAsyncDataCatId()
      await this.getAttribute()
      // this.attribute = await Object.assign([], this.attribute)
    },
    // perPage: {
    //   handler: async function () {
    //     console.log('start perPage', new Date() - this.startDate)
    //     this.getProduct()
    //   },
    //   deep: true,
    // },
    // cateIdLevelThree: {
    //   handler: function () {
    //     console.log('start cateIdLevelThree', new Date() - this.startDate)
    //     this.getProduct()
    //     console.log('end cateIdLevelThree', new Date() - this.startDate)
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.startDate = new Date()
    console.log(this.$nuxt._route.params)
    this.seoNameLevelOne = this.$nuxt._route.params.department
    this.categoryName = this.$nuxt._route.params.department
    this.seoNameLevelTwo = this.$nuxt._route.params.category
    this.seoNameLevelThree = this.$nuxt._route.params.subcategory
    this.lang = this.$store.state.locale
    console.log(this.lang)
    this.$nuxt.$on('returnCategory', (data) => {
      this.setCategory('two', data)
    })
    this.$nuxt.$on('returnCategoryLevelTwo', (data) => {
      this.setCategory('three', data)
    })
    this.$nuxt.$on('returnCategoryLevelThree', (data) => {
      // console.log('start last_child', new Date() - this.startDate)
      this.setCategory('last_child', data)
    })

    this.$nuxt.$on('onCategoryReady', () => {
      this.getCategoriesFromLayout()
    })
    // this.$nuxt.$on('render', (result) => {
    //   console.log('render default: ', result)
    //   this.getCategoriesFromLayout()
    // })
  },
  updated() {
    // if (!this.skeleton) {
    //   this.skeletonPage = false
    // }
    this.$_onGetInsider({
      page: {
        type: 'Category',
        custom: {
          category_name: this.$nuxt._route.params.department,
        },
      },
    })
    this.$_onInsiderResult({
      taxonomy: [
        this.$utils.isObjectKeyValid(this.breadcrumbItems[0], 'text')
          ? this.breadcrumbItems[0].text
          : '',
        this.$utils.isObjectKeyValid(this.breadcrumbItems[1], 'text')
          ? this.breadcrumbItems[1].text
          : '',
        this.$utils.isObjectKeyValid(this.breadcrumbItems[2], 'text')
          ? this.breadcrumbItems[2].text
          : '',
      ],
      result: [...(this.items || [])],
    })
  },
  async mounted() {
    // process.client &&
    // if (this.cateIdLevelThree === '') {
    await this.getCategoriesFromLayout()
    // }
    // this.skeletonPage = false
    window.addEventListener('load', this.onBoundingLoad)
    window.addEventListener('scroll', this.onBoundingLoad)
  },
  beforeDestroy() {
    this.$nuxt.$off('returnCategory')
    this.$nuxt.$off('returnCategoryLevelTwo')
    this.$nuxt.$off('returnCategoryLevelThree')
    this.$nuxt.$off('onCategoryReady')
    window.removeEventListener('load', this.onBoundingLoad)
    window.removeEventListener('scroll', this.onBoundingLoad)
  },
  methods: {
    async onAsyncDataCatId() {
      this.showItems = false
      if (this.cateIdLevelOne && this.cateIdLevelTwo && this.cateIdLevelThree) {
        setTimeout(async () => {
          let url = new URL(window.location.href).searchParams.toString().length
          this.firstTime = url === 0
          if (this.firstTime) {
            await this.getProduct()
            this.showItems = true
          }
        }, 100)
      } else {
        this.isNotFound = true
      }
    },
    async onPagination(page) {
      // console.log(page)
      this.page = page
      // console.log('onPagination')
      await this.getProduct()
      this.showItems = true
    },
    async onChangePage(val) {
      this.perPage = val
      // console.log('onChangePage')
      await this.getProduct()
      this.showItems = true
    },
    async getCategoriesFromLayout() {
      await this.$nuxt.$emit('requestCategory', this.seoNameLevelOne, 1)
      await this.$nuxt.$emit('requestCategory', this.seoNameLevelTwo, 2)
      await this.$nuxt.$emit('requestCategory', this.seoNameLevelThree, 3)
    },
    /* func assign ข้อมูลหลังจากเรียกไดรับ returnจาก default.vue */
    async setCategory(level, data) {
      console.log('set category level 3')
      if (
        this.$utils.isObjectValid(data) &&
        this.$utils.isObjectValid(data.items)
      ) {
        if (level === 'two') {
          // this.cateIdLevelOne = data.id
          this.cateIdLevelOne = this.asyncCateIdLevelOne
          this.breadcrumbItems[0].text = data.display_name[this.$i18n.locale]
        } else if (level === 'three') {
          // this.cateIdLevelTwo = data.id
          this.cateIdLevelTwo = this.asyncCateIdLevelTwo
          this.breadcrumbItems[1].text = data.display_name[this.$i18n.locale]
        } else if (level === 'last_child') {
          /* last_child จะเป็นข้อมูลสุดท้ายของ cate getมาเพื่อรับไอดีเพื่อใช้เรียก api */
          // console.log('start setCategory', new Date() - this.startDate)
          // this.cateIdLevelThree = data.id
          this.cateIdLevelThree = this.asyncCateIdLevelThree
          this.nameLevelThree = data.display_name
          this.breadcrumbItems[2].text = data.display_name[this.$i18n.locale]
          // await this.$productProvider.getCategoryById(data.id).then(
          //   async (result) => {
          //     if (result.status === 200) {
          //       if (this.$utils.isObjectValid(result.data.meta)) {
          //         this.pageTitle = result.data.meta.title_tag[this.$i18n.locale]
          //         this.pageContent =
          //           result.data.meta.description_tag[this.$i18n.locale]
          //       }
          //     }
          //   },
          //   (error) => {
          //     console.log('getBannerCategoryData', error.responsed)
          //   }
          // )
        }
      } else {
        this.skeletonPage = false
        this.skeletonSwiper = false
        this.isNotFound = true
      }
    },
    async setTitle() {
      await this.$productProvider.getCategoryById(this.cateIdLevelThree).then(
        async (result) => {
          if (result.status === 200) {
            let otherLang = this.$i18n.locale === 'th' ? 'en' : 'th'

            if (this.$utils.isObjectValid(result.data.meta)) {
              if (this.$utils.isObjectValid(result.data.meta.title_tag)) {
                let titleTag = result.data.meta.title_tag
                this.pageTitle = this.$utils.isValid(
                  titleTag[this.$i18n.locale]
                )
                  ? titleTag[this.$i18n.locale]
                  : titleTag[otherLang]
              }
              console.log('this.pageTitle', this.pageTitle)

              if (this.$utils.isObjectValid(result.data.meta.description_tag)) {
                let descriptionTag = result.data.meta.description_tag
                this.pageContent = this.$utils.isValid(
                  descriptionTag[this.$i18n.locale]
                )
                  ? descriptionTag[this.$i18n.locale]
                  : descriptionTag[otherLang]
              }
              this.categoryContent = result.data.content[this.$i18n.locale]
            }
          }
        },
        (error) => {
          console.log('getBannerCategoryData', error.responsed)
        }
      )
    },
    /* func เรียก api เพื่อข้อมูลแสดงข้อมูลสิ้นค้า  */
    async getProduct() {
      // this.skeletonItems = true
      this.showItems = false
      this.items = []
      // console.log('get product list xxxxxxxxxxxxxxx')
      console.log('start getproduct', new Date() - this.startDate)
      /* เรียก func เพื่อสร้าง พารามิเตอร์ */
      let params = await this.createParams()
      // console.log('get product', this.cateIdLevelOne, this.cateIdLevelTwo)
      if (
        this.cateIdLevelOne !== '' &&
        this.cateIdLevelTwo !== '' &&
        this.cateIdLevelThree !== ''
      ) {
        const response = await this.$productProvider.getProductList(params)
        if (response.status === 200) {
          // this.getAttribute()
          this.showItems = true
          this.pageTotal = response.data.totalRecords
          this.items = response.data.items
          if (this.isItems) {
            // ทำ seq เพื่อส่งเข้า gtm event impression
            let startIndex =
              (response.data.pageNo - 1) * response.data.pageSize + 1
            this.items.map((item, index) => {
              item.seq = index + startIndex
            })

            //gtm
            this.$gtm.push({ ecommerce: null })
            this.$gtm.push({
              event: 'impression',
              currencyCode: 'THB',
              ecommerce: {
                impressions: this.items.map((item) => {
                  return {
                    id: item.skus[0].sku_code,
                    price: this.$utils.formatPrice(
                      item.skus[0].price_include_vat,
                      2,
                      true
                    ),
                    list: 'Category',
                    position: item.seq,
                  }
                }),
              },
            })

            // gtm GA4
            this.$gtm.push({ ecommerce: null })
            this.$gtm.push({
              event: 'view_item_list',
              ecommerce: {
                user: this.$store.getters['user/userInfoTrack'],
                item_list_id: this.cateIdLevelThree,
                item_list_name: this.breadcrumbItems[2].text,
                items: this.items.map((item, index) => {
                  const sku = item.skus[0]
                  return {
                    item_id: sku.sku_code,
                    item_name: sku.product_names[1]?.display_name.th,
                    index: index,
                    item_brand: item.brand?.display_name.en,
                    price: sku.price_include_vat,
                    discount:
                      sku.retail_price.include_vat - sku.price_include_vat,
                    item_list_id: this.cateIdLevelThree,
                    item_list_name: this.breadcrumbItems[2].text,
                    ...this.$store.getters['user/userPromotionTrack'],
                  }
                }),
              },
            })
          }
          console.log('items', response.data.items)
          console.log('end getproduct', new Date() - this.startDate)
          console.log('end getAttribute', new Date() - this.startDate)
        }
      }
      // this.skeletonItems = false
      // console.log(this.items)
    },
    // async getProduct() {
    //   console.log('getProduct')
    //   let params = await this.createParams()
    //   const response = await this.$productProvider.getProductList(params)
    //   if (response.status === 200) {
    //     this.items = response.data.items
    //     this.pageTotal = response.data.totalRecords
    //   }
    // },
    async getAttribute() {
      let params = await this.createParams()
      /* get แบนจากสิ้นค้าที่มีทั้งหมด */
      console.log('getAttribute', params.toString())
      const response = await this.$productProvider.getBrand(params)
      console.log('getBrand', response)
      let attr = []
      if (response.status === 200) {
        let brandList = []
        response.data.forEach((element) => {
          brandList.push({
            text: element.display_name[this.lang],
            value: { by: 'brand', value: element.id },
          })
        })
        if (this.$utils.anyArrayObjectValid(brandList)) {
          attr.push({
            title: this.$t('filterPanelComponent.brand'),
            list: brandList,
          })
        }
      }
      const responseAttribute = await this.$productProvider.getAttribute(params)
      console.log('getAttribute', responseAttribute)
      if (responseAttribute.status === 200) {
        const attribute = responseAttribute.data
        attribute.forEach((element) => {
          let attributeList = []
          if (this.$utils.anyArrayObjectValid(element.value)) {
            element.value.forEach((value, index) => {
              if (
                this.$utils.isObjectValid(value) &&
                this.$utils.isValid(value[this.lang]) &&
                !attributeList.some((q) => q.value.value === value[this.lang])
              ) {
                attributeList.push({
                  text: value[this.lang],
                  value: {
                    index: index,
                    by: element.display_name[this.lang],
                    id: element.id,
                    value: value[this.lang],
                  },
                })
              }
            })
          }
          if (this.$utils.anyArrayObjectValid(attributeList)) {
            attr.push({
              title: element.display_name[this.lang],
              list: attributeList,
            })
          }
        })
        // attribute.forEach((element) => {
        //   let attriList = []
        //   element.value.forEach((value) => {
        //     attriList.push({
        //       text: value[this.lang],
        //       value: { by: element.id, value: value[this.lang] },
        //     })
        //   })
        //   this.attribute.push({
        //     title: element.display_name[this.lang],
        //     list: attriList,
        //   })
        // })
      }
      this.attribute = attr
      this.skeletonPage = false
      if (!this.attribute.length) this.showItems = true
      // this.skeletonItems = false
    },
    async emitAttribute(value) {
      this.paramAttribute = value
      setTimeout(async () => {
        if (!this.firstTime) {
          await this.getProduct()
          this.showItems = true
        }
        this.firstTime = false
        console.log('on emitAttribute', value)

        // replaceState
        // let query = new URL(window.location.href).search
        // window.history.replaceState(
        //   null,
        //   null,
        //   this.$i18n.path(
        //     `category/${this.department_title}/${this.category_title}/${this.subcategory_title}${query}`
        //   )
        // )
      }, 100)
    },
    /* สร้าง params สำหรับเรียก api get product */
    async createParams() {
      let params = new URLSearchParams()
      params.append('userOpenId', this.cartId)
      params.append('accountId', this.userProfile.id)
      /* profileType จะเป็น standard กับ eprocurement */
      params.append('accountChannel', this.profileType)

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      params.append('sort', this.sort)
      params.append('categoryId', this.cateIdLevelOne)
      params.append('categoryId', this.cateIdLevelTwo)
      params.append('categoryId', this.cateIdLevelThree)
      if (this.paramAttribute.attribute.length !== 0) {
        this.paramAttribute.attribute.forEach((data) => {
          data.by === 'brand'
            ? params.append('brands', data.value)
            : params.append('attributes', data.id + '-' + data.value)
        })
      }
      if (this.paramAttribute.price.length !== 0) {
        params.append('min', this.paramAttribute.price[0])
        params.append('max', this.paramAttribute.price[1])
      }

      params.append('isFree', false)
      if (this.$utils.anyArrayObjectValid(this.filterProductStatus)) {
        this.filterProductStatus.forEach((item) => {
          params.append('productStatus', item)
        })
      }
      return params
    },
    async selectSort(e) {
      if (this.showItems) {
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCT
        if (e.value === '0') this.sort = ''
        else if (e.value === '1') {
          this.sort = 'price-ascending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEASC
        } else if (e.value === '2') {
          this.sort = 'price-descending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEDESC
        } else if (e.value === '3') this.sort = 'new-descending'
        else this.sort = 'old-ascending'
        console.log('selectSort')
        await this.getProduct()
        this.showItems = true
      }
    },
    async onBoundingLoad() {
      if (!this.skeletonPage) {
        let height = 0
        if (
          this.$refs['category-content'].getBoundingClientRect().top <
          window.innerHeight - height
        ) {
          this.showCategoryContent = true
        }
      }
    },
  },
}
