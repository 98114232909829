
import BreadCrumb from '@/components/template/bread-crumb.vue'
import FilterPanel from '@/components/template/filter-panel.vue'
import FilterController from '@/components/share/filter-controller.vue'
import ProductResult from '@/components/template/product-result.vue'
import ProductPagination from '@/components/template/product-pagination.vue'
import { /* mapActions, */ mapState, mapGetters } from 'vuex'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'
import SkeletonResult from '@/components/skeleton/product-result.vue'
import countdown from '@/components/flashsale/countdown.vue'
import notfound from '@/components/share/notfound.vue'

export default {
  name: 'FlashSaleListPage',
  components: {
    breadcrumb: BreadCrumb,
    'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    'filter-controller': FilterController,
    'product-result': ProductResult,
    ProductPagination,
    'skeleton-result': SkeletonResult,
    // 'swiper-image': SwiperImage,
    countdown,
    notfound,
  },
  head() {
    return {
      // title: this.namepage,
      // meta: [
      //   {
      //     hid: 'description',
      //     name: 'description',
      //     content: this.pageContent,
      //   },
      // ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href:
      //       `https://www.ofm.co.th` +
      //       this.$i18n.path(`widget/${this.$route.params.name}`),
      //   },
      // ],
      script: [
        {
          // Breadcrumb Rich Snippet
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: this.breadcrumbItems[0].text, //Main index
                item: `https://www.ofm.co.th`,
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'flash sale',
              },
            ],
          },
        },
      ],
    }
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      namepage: 'Flash Sale',
      sortBy: [
        {
          text: 'Default...',
          value: '0',
        },
        {
          text: 'PRICE LOW > HIGH',
          value: '1',
        },
        {
          text: 'PRICE HIGH > LOW',
          value: '2',
        },
        {
          text: 'A > Z',
          value: '3',
        },
        {
          text: 'Z > A',
          value: '4',
        },
      ],
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      page: 1,
      perPage: 30,
      pageTotal: 0,
      condition: 'Funiture',
      filter: {},
      sort: '',
      view: 'grid',
      items: [],
      lang: '',
      attribute: [],
      paramAttribute: { attribute: [], price: [] },
      totalCount: '',
      display: '',
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      skeleton: false,
      isGetAttribute: false,
      skeletonPage: true,
      isNotFound: false,
      showItems: false,
      banner: [],
      flashSaleEndDate: '',
      hasFlashSale: false,
      currentDate: '',
    }
  },
  computed: {
    ...mapGetters('authen', ['openId']),
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    breadcrumbItems() {
      return [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: this.namepage,
        },
      ]
    },
    isBanner() {
      console.log('isBanner', this.banner)
      return this.banner != null && this.banner.length > 0
    },
    sortedBanner: function () {
      //       function compare(a, b) {
      //         if (a.sequence < b.sequence) return -1
      //         if (a.sequence > b.sequence) return 1
      //         return 0
      //       }
      // this.isBanner ? this.banner.sort(compare) : []
      return this.isBanner ? this.banner : []
    },
    isFlashSale() {
      return this.$store.state.user.profileType !== 'eprocurement'
    },
  },
  watch: {
    // perPage: {
    //   handler: function () {
    //     console.log(this.perPage)
    //     this.getProduct()
    //   },
    //   deep: true,
    // },
  },
  updated() {
    this.skeletonPage = false
    this.$_onGetInsider({
      page: {
        type: 'Other',
        custom: {
          category_name: null,
        },
      },
    })
    this.$_onInsiderResult({
      result: [...(this.items || [])],
    })
  },
  created() {
    // this.$nextTick(() => {
    // this.getAttributeProductHomePage()
    // })
  },
  async mounted() {
    // this.selectName()
    this.lang = this.$store.state.locale
    // await this.getProduct()
    if (this.isFlashSale) {
      await this.getFlashSaleExpired()
      // await this.getAttributeProductHomePage()
    } else {
      this.skeletonPage = false
    }

    // this.attribute = await Object.assign([], [...new Set(this.attribute)])
    console.log('this.attribute', this.attribute)
  },
  methods: {
    // check end date flash sale
    async getFlashSaleExpired() {
      await this.$productProvider.getFlashSaleExpired().then(
        async (result) => {
          if (result.status === 200) {
            this.currentDate = new Date(
              result.data.current_date
            ).toLocaleString('en-US', {
              timeZone: 'Asia/Bangkok',
            })
            this.flashSaleEndDate = new Date(
              result.data.end_date
            ).toLocaleString('en-US', {
              timeZone: 'Asia/Bangkok',
            })

            // Time remaining
            let diff =
              new Date(this.flashSaleEndDate).getTime() -
              new Date(this.currentDate).getTime()

            if (diff > 0) {
              this.hasFlashSale = true
              await this.getฺBannerHome()
              await this.getAttributeProductHomePage()
            }
          }
        },
        (error) => {
          console.log('GetRecommendedProductHome', error.responsed)
        }
      )
      this.skeletonPage = false
    },
    async onPagination(page) {
      // console.log(page)
      this.page = page
      await this.getProduct()
      this.showItems = true
    },
    async onChangePage(val) {
      this.perPage = val
      await this.getProduct()
      this.showItems = true
    },
    // async emitAttribute(value) {
    //   this.paramAttribute = value
    // },
    async selectSort(event) {
      if (this.showItems) {
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCT
        if (event.value === '0') this.sort = ''
        else if (event.value === '1') {
          this.sort = 'price-ascending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEASC
        } else if (event.value === '2') {
          this.sort = 'price-descending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEDESC
        } else if (event.value === '3') {
          this.sort = 'new-descending-' + this.lang
        } else this.sort = 'old-ascending-' + this.lang
        await this.getProduct()
        this.showItems = true
      }
    },
    async getฺBannerHome() {
      this.banner = []
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('segment', this.userProfile.segment)
      params.append('page_type', 'flash_sale')
      await this.$profileProvider.getฺBannerHomeData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            if (data.length > 0) {
              data.sort(function (a, b) {
                return a.sequence - b.sequence
              })
              data.forEach((e, index) => {
                this.banner.push({
                  // alt: e.sequence,
                  // url: e.path_url,
                  // path: e.image.url,
                  // asset: false,
                  // title: e.title,
                  // path_url: e.path_url,
                  // gtmSeq: index + 1,

                  index: e.sequence,
                  src: e.image.url,
                  url: e.path_url,
                  title: e.title_tag?.[this.$i18n.locale] || e.title,
                  alt: e.alt_tag?.[this.$i18n.locale] || e.title,
                  gtmSeq: index + 1,
                  active_from: e.active_from,
                  active_to: e.active_to,
                  update_info: e.update_info,
                })
              })
            }
          }
        },
        (error) => {
          console.log('getฺBannerHomeData', error.responsed)
        }
      )
      console.log('this.banner', this.banner)
    },
    async getProduct() {
      this.skeleton = false
      this.showItems = false
      console.log('start getproduct : ', this.sort)
      /* เรียก func เพื่อสร้าง พารามิเตอร์ */
      let params = await this.createBrandAndAttributeParams()
      console.log('params', params.toString())
      const response = await this.$productProvider.getFlashSaleProductsSeeAll(
        params
      )

      console.log('getProduct', response.data)
      if (response.status === 200) {
        if (response.data.totalRecords === undefined) {
          this.isNotFound = true
          this.skeletonPage = false
        }

        // this.flashSaleEndDate = new Date(
        //   response.data.items[0]?.end_date
        // ).toLocaleString('en-US', {
        //   timeZone: 'Asia/Bangkok',
        // })

        // let now = new Date().toLocaleString('en-US', {
        //   timeZone: 'Asia/Bangkok',
        // })
        // // Time remaining
        // let diff =
        //   new Date(this.flashSaleEndDate).getTime() - new Date(now).getTime()

        if (response.data.items !== null) {
          this.pageTotal = response.data.totalRecords
          this.items = this.hasFlashSale ? response.data.items : []
          // ทำ seq เพื่อส่งเข้า gtm event impression
          let startIndex =
            (response.data.pageNo - 1) * response.data.pageSize + 1
          this.items.map((item, index) => {
            item.seq = index + startIndex
          })
          //gtm
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'impression',
            currencyCode: 'THB',
            ecommerce: {
              impressions: this.items.map((item) => {
                return {
                  id: item.skus[0].sku_code,
                  price: this.$utils.formatPrice(
                    item.skus[0].price_include_vat,
                    2,
                    true
                  ),
                  list: 'Flash Sale',
                  position: item.seq,
                }
              }),
            },
          })

          // gtm GA4
          // const itemList = {
          //   'best-seller': {
          //     id: 'best_sellers',
          //     name: 'Best Sellers',
          //   },
          //   'new-arrival': {
          //     id: 'new_arrival',
          //     name: 'New Arrival',
          //   },
          //   promotion: {
          //     id: 'promotions',
          //     name: 'Promotions',
          //   },
          //   'featured-product': {
          //     id: 'featured_product',
          //     name: 'Featured Product',
          //   },
          //   'recommend-for-you': {
          //     id: 'recommend_for_you',
          //     name: 'Recommended',
          //   },
          // }
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'view_item_list',
            ecommerce: {
              user: this.$store.getters['user/userInfoTrack'],
              item_list_id: 'flash_sale',
              item_list_name: 'flash sale',
              items: this.items.map((item, index) => {
                const sku = item.skus[0]
                return {
                  index: index,
                  item_id: sku.sku_code,
                  item_name: sku.product_names[1]?.display_name.th,
                  price: sku.price_include_vat,
                  discount:
                    sku.retail_price.include_vat - sku.price_include_vat,
                }
              }),
            },
          })
        }
        console.log('this.items', this.items)
      }
      this.skeleton = true
    },
    async getAttributeProductHomePage() {
      let attr = []
      this.isGetAttribute = false
      let params = await this.createParams()

      // get category
      // const responseCategory =
      //   await this.$productProvider.getFlashSaleCategories(params)
      // if (responseCategory.status === 200) {
      //   console.log('responseCategory', responseCategory.data)
      //   let categoryList = []
      //   responseCategory.data.forEach((element) => {
      //     categoryList.push({
      //       text: element.display_name[this.lang],
      //       value: { by: 'category', value: element.category_id },
      //     })
      //   })

      //   attr.push({
      //     title: this.$t('filterPanelComponent.category'),
      //     list: categoryList,
      //   })
      //   console.log('categoryList', categoryList)
      // }

      // get brand
      const response = await this.$productProvider.getFlashSaleBrandsData(
        params
      )
      if (response.status === 200) {
        let brandList = []
        console.log('getFlashSaleBrandsData', response.data)
        response.data.forEach((element) => {
          brandList.push({
            text: element.display_name[this.lang],
            value: { by: 'brand', value: element.id },
          })
        })

        attr.push({
          title: this.$t('filterPanelComponent.brand'),
          list: brandList,
        })
      }
      // get categories
      const responseCaterories =
        await this.$productProvider.getFlashSaleCategories(params)
      if (responseCaterories.status === 200) {
        const categories = responseCaterories.data
        console.log('getFlashSaleCategories', categories)
        let categoriesList = []
        categories.forEach((element) => {
          categoriesList.push({
            text: element.display_name[this.lang],
            value: { by: 'category', value: element.category_id },
          })
        })
        if (this.$utils.anyArrayObjectValid(categoriesList)) {
          attr.push({
            title: this.$t('filterPanelComponent.category'),
            list: categoriesList,
          })
        }
      }
      this.attribute = attr
      this.isGetAttribute = true
      this.skeleton = true
      console.log('getattr', this.attribute)
    },
    async emitAttribute(value) {
      this.paramAttribute = value
      console.log('this.paramAttribute-', this.paramAttribute)
      await this.getProduct()
      this.showItems = true
    },
    async createParams() {
      let params = new URLSearchParams()
      // if (
      //   this.profileType === 'eprocurement' &&
      //   this.$utils.isObjectValid(this.userProfile)
      // ) {
      //   if (this.$utils.isValid(this.userProfile.id)) {
      //     params.append('companyId', this.userProfile.id)
      //   }
      //   if (this.$utils.isValid(this.userProfile.categories_type)) {
      //     params.append('userCategoryType', this.userProfile.categories_type)
      //   }
      //   if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
      //     params.append('costcenterId', this.userCostcenter.id)
      //   }
      // } else if (this.profileType === 'standard') {
      //   params.append('userCategoryType', 'Standard')
      // }

      params.append('type', 'flash_sale')
      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)
      params.append('isFree', false)
      console.log('router', this.$route)

      return params
    },
    // selectSort(e) {
    //   // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
    //   if (e.value === '0') this.sort = ''
    //   else if (e.value === '1') this.sort = 'price-ascending'
    //   else if (e.value === '2') this.sort = 'price-descending'
    //   else if (e.value === '3') this.sort = 'new-descending'
    //   else this.sort = 'old-ascending'
    //   this.getProduct()
    // },
    async createBrandAndAttributeParams() {
      let params = new URLSearchParams()

      params.append('accountId', this.userProfile.id)
      params.append('accountChannel', this.profileType)
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('type', 'FlashSale')
      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      params.append('sort', this.sort)
      /* วนเพื่อสร้าง param category ตามค่าที่เราเลือก */
      /* วนเพื่อสร้าง param brand ตามค่าที่เราเลือก */
      if (this.paramAttribute.attribute.length !== 0) {
        this.paramAttribute.attribute.forEach((data) => {
          if (data.by === 'brand') {
            params.append('brands', data.value)
          } else if (data.by === 'category') {
            params.append('categories', data.value)
          } else {
            params.append('attributes', data.id + '-' + data.value)
          }
        })
      }
      /* สร้าง params ราคา max min ในกรณีที่มีการ กำหนดค่า */
      console.log('this.paramAttribute', this.paramAttribute)
      if (this.paramAttribute.price.length !== 0) {
        params.append('min', this.paramAttribute.price[0])
        params.append('max', this.paramAttribute.price[1])
      }

      params.append('isFree', false)
      return params
    },
    onFlashsaleTimeout() {
      this.hasFlashSale = false
    },
    // selectName() {
    //   switch (this.$route.params.name) {
    //     case 'new-arrival':
    //       this.namepage = this.$t(`widgetPage.new_arrival`)
    //       break
    //     case 'best-seller':
    //       this.namepage = this.$t(`widgetPage.best_sellers`)
    //       break
    //     case 'featured-product':
    //       this.namepage = this.$t(`widgetPage.trending_products`)
    //       break
    //     case 'recommend-for-you':
    //       this.namepage = this.$t(`widgetPage.recommed_products`)
    //       break
    //     case 'promotion':
    //       this.namepage = this.$t(`index.labelPromotion`)
    //       break
    //     default:
    //       this.namepage = this.$route.params.name
    //       break
    //   }
    //   console.log('namepage', this.namepage)
    // },
  },
}
