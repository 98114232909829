
export default {
  name: 'ProductPaginationComponent',
  props: {
    perPage: { type: Number, default: 0 },
    total: { type: Number, default: 0 },
    align: { type: String, default: 'left' },
    currentPage: { type: Number, default: 1 },
    pageUnit: {
      type: String,
      default: '',
    },
    scrolltotop: { type: Boolean, default: true },
  },
  data() {
    return {
      page: 1,
    }
  },
  watch: {
    page: {
      handler: async function (val) {
        console.log('val', val)
        await this.scrollToTop()
        this.$emit('pagination', val)
      },
      deep: true,
    },
  },
  computed: {
    displayPageUnit() {
      return this.$utils.isValid(this.pageUnit)
        ? this.pageUnit
        : this.$t('productPaginationComponent.unit')
    },
  },
  mounted() {
    this.page = this.currentPage
  },
  methods: {
    scrollToTop() {
      if (this.scrolltotop) {
        function preventScroll(e) {
          e.preventDefault()
          e.stopPropagation()

          return false
        }
        window.addEventListener('wheel', preventScroll, {
          passive: false,
        })

        const scrollToTop = () => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          setTimeout(() => {
            window.removeEventListener('wheel', preventScroll, {
              passive: false,
            })
          }, 500)
        }
        scrollToTop()
      }
    },
  },
}
