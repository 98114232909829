import { render, staticRenderFns } from "./order-items.vue?vue&type=template&id=8d46774a"
import script from "./order-items.vue?vue&type=script&lang=js"
export * from "./order-items.vue?vue&type=script&lang=js"
import style0 from "./order-items.vue?vue&type=style&index=0&id=8d46774a&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputQty: require('/usr/src/nuxt-app/components/share/input-qty.vue').default})
