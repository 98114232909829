import { render, staticRenderFns } from "./tab-product-brand.vue?vue&type=template&id=63826d04"
import script from "./tab-product-brand.vue?vue&type=script&lang=js"
export * from "./tab-product-brand.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperItem: require('/usr/src/nuxt-app/components/share/swiper-item.vue').default})
