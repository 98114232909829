
import BreadCrumb from '@/components/template/bread-crumb.vue'
import FilterPanel from '@/components/template/filter-panel.vue'
import FilterController from '@/components/share/filter-controller-algolia.vue'
import SwiperSlider from '@/components/share/swiper-item.vue'
import ProductResult from '@/components/template/product-result.vue'
import SekletonResult from '@/components/skeleton/product-result.vue'
import notfound from '@/components/share/notfound.vue'
import { /* mapActions, */ mapState, mapGetters } from 'vuex'
import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'

export default {
  name: 'Brand',
  components: {
    breadcrumb: BreadCrumb,
    'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    'filter-controller': FilterController,
    'product-result': ProductResult,
    'swiper-item': SwiperSlider,
    'skeleton-result': SekletonResult,
    notfound,
  },
  head() {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageContent,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageContent,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogImage,
        },
      ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href:
      //       `https://www.ofm.co.th` +
      //       this.$i18n.path(`brand/${this.$route.params.keyword}`),
      //   },
      // ],
    }
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'searchbrandresults',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  async asyncData({ route, app, store, $axios }) {
    let brandTitle = route.params.keyword
    let pageTitle = `${brandTitle}`
    let pageContent = `${app.i18n.t('meta.descShop')}${brandTitle}`
    let ogImage = 'https://ofm-cdn0.ofm.co.th/images/default-og.png'
    let brand = {
      display_name: {
        th: brandTitle,
        en: brandTitle,
      },
      image_url:
        'https://ofm-cdn0.ofm.co.th/images/logo/ic_coupon_platform.png',
      content: {
        th: '',
        en: '',
      },
    }
    let filterBanner = []

    // get brand data from name
    let params = new URLSearchParams()
    params.append('brand_name', brandTitle)
    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
      'Sale-Method-Id':
        store.state.user.profileType === 'eprocurement' ? 6 : 89,
    }
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    console.log('http-server', http)

    // axios

    // set base url

    try {
      const result = await $axios.get(
        encodeURI(
          `${http}${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Brands/name`
          //'http://localhost:5003/api/v1/Brands/name'
        ),
        { headers, params }
      )
      if (result.status === 200) {
        const _result = result.data
        filterBanner = result.data?.banner_images || []
        let otherLang = store.state.locale === 'th' ? 'en' : 'th'
        // default name
        let pathName = _result.display_name[store.state.locale]
        // pathSEOName = categories.meta.seo_name
        if (app.$utils.isObjectValid(_result.meta)) {
          if (app.$utils.isObjectValid(_result.meta.title_tag)) {
            let titleTag = _result.meta.title_tag

            pageTitle = app.$utils.isValid(titleTag[store.state.locale])
              ? titleTag[store.state.locale]
              : titleTag[otherLang]
          } else {
            // default pageTitle
            pageTitle = `${pathName}`
          }

          if (app.$utils.isObjectValid(_result.meta.description_tag)) {
            let descriptionTag = _result.meta.description_tag
            pageContent =
              app.i18n.t('meta.descShop') +
              (app.$utils.isValid(descriptionTag[store.state.locale])
                ? descriptionTag[store.state.locale]
                : descriptionTag[otherLang])
          } else {
            // default pageContent
            pageContent = `${app.i18n.t('meta.descShop')}${pathName}`
          }
        }

        if (_result.image_url) {
          ogImage = _result.image_url
        }
        brand = _result
      }
    } catch (e) {
      console.log('catch', e)
    }

    return {
      pageTitle: pageTitle,
      pageContent: pageContent,
      ogImage: ogImage,
      brand: brand,
      filterBanner: filterBanner,
    }
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('brand_keyword.breadcrumbItemsText'),
          href: '/',
        },
        {
          text: this.$nuxt._route.params.keyword,
          href: '',
        },
      ],
      sortBy: [
        {
          text: this.$t('search_keyword.dropdownBestSeller'),
          value: '0',
        },
        {
          text: this.$t('search_keyword.dropdownPromotion'),
          value: '1',
        },
        {
          text: this.$t('search_keyword.dropdownNewArrival'),
          value: '2',
        },
        {
          text: this.$t('search_keyword.dropdownRating'),
          value: '3',
        },
        {
          text: this.$t('search_keyword.dropdownPriceHighLow'),
          value: '4',
        },
        {
          text: this.$t('search_keyword.dropdownPriceLowHigh'),
          value: '5',
        },
      ],
      page: 0,
      perPage: 30,
      pageTotal: 0,
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      condition: 'Funiture',
      filter: {},
      sort: '',
      view: 'grid',
      items: null,
      lang: '',
      attribute: [],
      attributes: [],
      priceDefault: [200, 9000],
      paramAttribute: { attribute: [], price: [] },
      itemsRecentlyViewedProducts: [],
      isNotFound: false,
      images: [],
      queryIdEventAlgolia: '',
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.SEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      skeletonPage: true,
      isGetBrandner: false,
      isGetProduct: false,
      isGetProductHistory: false,
      showItems: false,
      firstTime: true,
      brand: null,
      showContent: false,
      gtmItemListId: '',
      gtmItemListName: '',
      // filterBanner: [],
    }
  },
  computed: {
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    headerTitleAny() {
      return (
        this.$route.params.keyword !== null &&
        this.items !== null &&
        this.items.length > 0
      )
    },
    headerTitleNotfound() {
      return (
        this.$route.params.keyword !== null &&
        this.items !== null &&
        this.items.length === 0
      )
    },
    isEprocurementStandard() {
      return this.profileType === 'eprocurement' &&
        this.userProfile.categories_type !== 'Standard'
        ? true
        : false
    },
    isBanner() {
      return this.filterBanner != null && this.filterBanner.length > 0
    },
    sortedBanner: function () {
      function compare(a, b) {
        if (a.sequence < b.sequence) return -1
        if (a.sequence > b.sequence) return 1
        return 0
      }

      return this.isBanner ? this.filterBanner.sort(compare) : []
    },
  },
  watch: {},
  created() {
    this.lang = this.$store.state.locale
  },
  updated() {
    this.skeletonPage = false
  },
  async mounted() {
    this.showItems = false
    let url = new URL(window.location.href).searchParams.toString().length
    this.firstTime = url === 0

    await this.getProduct()

    this.getUserProductHistory()
    this.getฺBannerHome()
    // this.attributes = await Object.assign([], this.attribute)
    this.$nextTick(() => {
      if (this.$utils.anyArrayObjectValid(this.attribute)) {
        this.attributes = Object.assign([], this.attribute)
      } else {
        this.showItems = true
      }
    })

    let params = new URLSearchParams()
    params.append('brand_name', this.$route.params.keyword)
    // params.append('page_type', 'brand_page')
    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
      'Sale-Method-Id':
        this.$store.state.user.profileType === 'eprocurement' ? 6 : 89,
    }
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    console.log('http-server', http)

    try {
      const result = await this.$axios.get(
        encodeURI(
          `${http}${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Brands/name`
          //'http://localhost:5003/api/v1/Brands/name'
        ),
        { headers, params }
      )
      if (result.status === 200) {
        const _result = result.data
        console.log('_result', _result)

        // this.filterBanner = result.data.banner_images
      }
    } catch (e) {
      console.log('catch', e)
    }
  },
  methods: {
    async onPagination(page) {
      this.page = page - 1
      await this.getProduct()
      this.showItems = true
    },
    async onChangePage(val) {
      this.perPage = val
      await this.getProduct()
      this.showItems = true
    },
    /* func assign ข้อมูลหลังจากเรียกไดรับ returnจาก default.vue */
    /* func เรียก api เพื่อข้อมูลแสดงข้อมูลสิ้นค้า  */
    async getProduct() {
      this.isGetProduct = false
      this.showItems = false
      this.items = []
      let attr = []
      let bodyBrands = []
      let bodyCategoriesLevel1s = []
      let bodyAttributes = []
      let params = await this.createParams()
      console.log('param', params.toString())
      if (
        this.$route.params.keyword !== undefined &&
        this.$route.params.keyword !== null &&
        this.$route.params.keyword !== ''
      ) {
        bodyBrands.push(this.$route.params.keyword)
        // params.append('keyword', this.$route.params.keyword)
      }

      if (this.paramAttribute.attribute.length !== 0) {
        this.paramAttribute.attribute.forEach((data) => {
          data.by === 'category'
            ? bodyCategoriesLevel1s.push(data.value)
            : bodyAttributes.push(data.id + '-' + data.value)
        })
      }
      let apiData = JSON.stringify({
        filterBrands: bodyBrands,
        filterCategoriesLevel1s: bodyCategoriesLevel1s,
        filterAttributes: bodyAttributes,
      })
      console.log('apiData', apiData)
      const response = await this.$productProvider.productsSearchEngine(
        params,
        apiData
      )
      if (response.status === 201) {
        // if (response?.data?.brand) {
        //   this.brand = response.data.brand
        // }

        this.showItems = this.firstTime
        console.log('productProvider', response.data)
        this.pageTotal = response.data.items.totalRecords
        // this.items =
        //   response.data.items.items === null ? [] : response.data.items.items
        if (
          this.$utils.isObjectValid(response.data.items) &&
          this.$utils.anyArrayObjectValid(response.data.items.items)
        ) {
          this.isNotFound = false
          this.items = this.$utils.anyArrayObjectValid(
            response.data.items.items
          )
            ? response.data.items.items.filter((q) =>
                this.$utils.anyArrayObjectValid(q.skus)
              )
            : []
        } else {
          this.items = []
          this.isNotFound = true
        }

        if (this.$utils.anyArrayObjectValid(this.items)) {
          // ทำ seq เพื่อส่งเข้า gtm event impression

          let startIndex =
            response.data.items.pageNo * response.data.items.pageSize + 1
          this.items.map((item, index) => {
            item.seq = index + startIndex
          })

          //gtm
          this.gtmItemListId = this.brand.id
          this.gtmItemListName = `plp_${this.brand.display_name.en}`

          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'view_item_list',
            ecommerce: {
              user: this.$store.getters['user/userInfoTrack'],
              item_list_id: this.brand.id,
              item_list_name: `plp_${this.brand.display_name.en}`,
              items: this.items.map((item, index) => {
                const sku = item.skus[0]
                return {
                  index: index,
                  item_id: sku.sku_code,
                  item_name: sku.product_names[1]?.display_name.th,
                  price: sku.price_include_vat,
                  discount:
                    sku.retail_price.include_vat - sku.price_include_vat,
                  item_list_id: this.brand.id,
                  item_list_name: `plp_${this.brand.display_name.en}`,
                  ...this.$store.getters['user/userPromotionTrack'],
                }
              }),
            },
          })

          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'view_promotion',
            ecommerce: {
              creative_name: `logo_${this.brand.display_name.en}`,
              creative_slot: `brand_page`,
              promotion_id: this.brand.id,
              promotion_name: `plp_${this.brand.display_name.en}`,
            },
          })
        }
        let facets = response.data.facets
        console.log('facets', response.data)
        if (facets != null && facets.length > 0) {
          //  && isFirst
          /* categories_level1 */
          let categories_level1 = facets.find(
            (q) => q.display_name === 'categories_level1.display_name'
          )

          if (categories_level1 != null) {
            let categories_level1_list = []

            for (const [index, element] of categories_level1.values.entries()) {
              console.log('element', element)
              categories_level1_list.push({
                text: element.key[this.lang],
                value: { by: 'category', index: index, value: element.id },
                // value: {
                //   // by: 'categories_level1',
                //   by: 'category',
                //   value: `${element.key['th']}__${element.key['en']}`,
                //   id: element.id,
                //   index: index,
                // },
                // value: { by: 'categories_level1', value: categories_level1.values[i].value },
              })
            }

            attr.push({
              title: this.$t('brand_keyword.labelTitleCategoryAttribute'),
              list: categories_level1_list,
            })
          }

          /* attributes */
          let attributes = facets.find(
            (q) => q.display_name === 'attributes.display_name'
          )
          // console.log('attributes', attributes)
          if (attributes != null) {
            for (const element of attributes.values) {
              let attributeList = []
              if (this.$utils.anyArrayObjectValid(element.value)) {
                element.value.forEach((value, index) => {
                  if (
                    this.$utils.isObjectValid(value) &&
                    this.$utils.isValid(value[this.lang]) &&
                    !attributeList.some(
                      (q) => q.value.value === value[this.lang]
                    )
                  ) {
                    attributeList.push({
                      text: value[this.lang],
                      value: {
                        index: index,
                        by: element.display_name[this.lang],
                        id: element.id,
                        value: value[this.lang],
                      },
                    })
                  }
                })
              }
              if (this.$utils.anyArrayObjectValid(attributeList)) {
                attr.push({
                  title: element.display_name[this.lang],
                  list: attributeList,
                })
              }
            }
          }

          /* price */
          let price = facets.find(
            (q) => q.display_name === 'price_default.include_vat'
          )
          if (price != null) {
            // console.log(price)
            this.priceDefault = price.values[0].value
          }
        }

        if (this.$utils.isValid(response.data.query_id)) {
          this.queryIdEventAlgolia = response.data.query_id
          // console.log('this.queryIdEventAlgolia', this.queryIdEventAlgolia)
        }
      }
      // if (this.items != null) {
      //   this.items = this.items.reduce((result, item) => {
      //     let rs = item.skus.find((f) => f.is_free)
      //     if (rs) {
      //       return result
      //     } else {
      //       return [...result, item]
      //     }
      //   }, [])
      // }
      this.attribute = attr
      // console.log('this.attribute', this.attribute)
      this.isGetProduct = true
    },
    async emitAttribute(value) {
      this.paramAttribute = value
      console.log('emitAttribute', this.firstTime)
      // await this.getProduct()
      // setTimeout(async () => {
      if (!this.firstTime) {
        await this.getProduct()
        this.showItems = true
      }
      this.firstTime = false
      // }, 100)
    },
    /* สร้าง params สำหรับเรียก api get product */
    async createParams() {
      // console.log('search page', this.page)
      let params = new URLSearchParams()

      params.append('userOpenId', this.cartId)
      params.append('accountId', this.userProfile.id)
      /* profileType จะเป็น standard กับ eprocurement */
      params.append('accountChannel', this.profileType)
      params.append('isFree', false)
      /* category type exp. costcenter, company */
      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }

      params.append('page', this.page)
      params.append('pageSize', this.perPage)
      // console.log(this.perPage)
      params.append('sort', this.sort)
      // params.append('categoryId', this.cateIdLevelOne)
      // params.append('categoryId', this.cateIdLevelTwo)
      // params.append('categoryId', this.cateIdLevelThree)
      // console.log('this.paramAttribute.attribute', this.paramAttribute.attribute)
      // if (this.paramAttribute.attribute.length !== 0) {
      //   this.paramAttribute.attribute.forEach((data) => {
      //     data.by === 'categories_level1'
      //       ? params.append('categoriesLevel1s', data.value)
      //       : params.append('attributes', data.by + '-' + data.value)
      //     // data.by === 'brand'
      //     //   ? params.append('brands', data.value)
      //     //     : data.by === 'categories_level1'
      //     //       ? params.append('categoriesLevel1s', data.value)
      //     //         : params.append('attributes', data.by + '-' + data.value)
      //   })
      // }
      if (this.paramAttribute.attribute.length !== 0) {
        this.paramAttribute.attribute.forEach((data) => {
          if (data.by === 'brand') {
            params.append('filterBrands', data.value)
          } else if (data.by === 'category') {
            params.append('filterCategoriesLevel1s', data.value)
          } else {
            params.append('filterAttributes', data.id + '-' + data.value)
          }
        })
      }
      if (this.paramAttribute.price.length !== 0) {
        if (this.paramAttribute.price[0] !== 0)
          params.append('min', this.paramAttribute.price[0])
        if (this.paramAttribute.price[1] !== 0)
          params.append('max', this.paramAttribute.price[1])
      }

      if (
        this.$route.params.keyword !== undefined &&
        this.$route.params.keyword !== null &&
        this.$route.params.keyword !== ''
      ) {
        params.append('brand_name', this.$route.params.keyword)
      }
      console.log('paramAttribute', this.paramAttribute)
      return params
    },
    async selectSort(e) {
      if (this.showItems) {
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCT
        if (e.value === '0') {
          this.sort = 'products'
        } else if (e.value === '1') {
          this.sort = 'product-promotion'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPROPROMOTION
        } else if (e.value === '2') {
          this.sort = 'product-new-arrival'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTNEWARRIVAL
        } else if (e.value === '3') {
          this.sort = 'product-rating'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTRATING
        } else if (e.value === '4') {
          this.sort = 'price-descending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEDESC
        } else if (e.value === '5') {
          this.sort = 'price-ascending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEASC
        }
        await this.getProduct()
        this.showItems = true
      }
    },
    async getฺBannerHome() {
      this.isGetBrandner = false
      let params = this.createProfileParams()
      await this.$profileProvider.getฺBannerHomeData(params).then(
        (result) => {
          if (result.status === 200) {
            let data = result.data
            if (data.length > 0) {
              data.sort(function (a, b) {
                return a.sequence - b.sequence
              })
              data.forEach((e) => {
                this.images.push({
                  alt: e.sequence,
                  url: e.path_url,
                  path: e.image.url,
                  asset: false,
                  title: e.title,
                })
              })
              // console.log('this.images', this.images)
            }
          }
        },
        (error) => {
          console.log('getฺBannerHomeData', error.responsed)
        }
      )
      this.isGetBrandner = true
    },
    createProfileParams() {
      let params = new URLSearchParams()
      params.append('account_channel', this.profileType)
      params.append('segment', this.userProfile.segment)
      params.append('page_type', 'search')
      if (this.profileType === 'eprocurement') {
        params.append('price_type', this.userProfile.price_type)
        params.append('categories_type', this.userProfile.categories_type ?? '')
      }
      return params
    },
    async getUserProductHistory() {
      this.isGetProductHistory = false
      let params = new URLSearchParams()
      params.append(
        'costcenter_id',
        Object.keys(this.userCostcenter).length ? this.userCostcenter.id : ''
      )
      params.append('status', 'Active')
      const result = await this.$productProvider.getUserProductHistory(
        this.cartId,
        this.userProfile.id,
        params
      )

      // console.log('user product history', result.data)
      this.itemsRecentlyViewedProducts = []
      if (result.status === 200 && result.data.length) {
        result.data.forEach((sku, index) => {
          if (this.$utils.isObjectValid(sku)) {
            this.itemsRecentlyViewedProducts.push({
              productId: sku.sku_code,
              productName: sku.product_names.find(
                (b) => b.type.toLowerCase() === 'online'
              ).display_name,

              // productImg: sku.images.find(
              //   (c) => c.size === 'thumbnail' && c.sequence === 1
              // ).url,
              productImg: this.imageNormalShowcase(sku),
              price_include_vat: sku.price_include_vat,
              retail_price: sku.retail_price.include_vat,
              is_promotion: sku.is_promotion,
              is_fixprice: sku.is_fixprice,
              is_hot_flash_sale: sku.is_hot_flash_sale,
              is_flash_sale: sku.is_flash_sale,
              is_online_only: sku.is_online_only,
              meta: sku.meta,
              seoMeta: sku.meta.seo_name,
              seq: index + 1,
              display_ratings: sku.display_ratings,
              display_ratings_count: sku.display_ratings_count,
              display_discount_rate: sku.display_discount_rate,
              promotion_prices: sku.promotion_prices,
              is_promotion_discount: sku.is_promotion_discount,
              brand: sku.brand,
            })
          }
        })

        // gtm
        if (this.$utils.anyArrayObjectValid(this.itemsRecentlyViewedProducts)) {
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'impression',
            currencyCode: 'THB',
            ecommerce: {
              impressions: this.itemsRecentlyViewedProducts.map((item) => {
                return {
                  id: item.sku_code,
                  price: this.$utils.formatPrice(
                    item.price_include_vat,
                    2,
                    true
                  ),
                  list: 'Recently Viewed Products',
                  position: item.seq,
                }
              }),
            },
          })
        }
      }
      this.isGetProductHistory = true
    },
    imageNormalShowcase(sku) {
      if (sku.images !== undefined && sku.images.length > 0) {
        return (
          sku.images.some((q) => q.size === 'large')
            ? sku.images
                .sort(function (a, b) {
                  return a.sequence - b.sequence
                })
                .find((q) => q.size === 'large').url
            : this.imageTemp
        ).replace('/large', '/original')
      } else {
        return this.imageTemp.replace('/large', '/original')
      }
    },
    replaceDisplaySearch(index) {
      let msg = this.$t('brand_keyword.labelNotMatchEprocurement')
      return msg.split('{keyword}')[index]
    },
  },
}
