import { render, staticRenderFns } from "./index.vue?vue&type=template&id=199fe043"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=199fe043&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SliderBanners: require('/usr/src/nuxt-app/components/share/slider-banners.vue').default,CardProductSelect: require('/usr/src/nuxt-app/components/share/card-product-select.vue').default,Countdown: require('/usr/src/nuxt-app/components/flashsale/countdown.vue').default,SwiperItem: require('/usr/src/nuxt-app/components/share/swiper-item.vue').default,SwiperImage: require('/usr/src/nuxt-app/components/share/swiper-image.vue').default,StripLo: require('/usr/src/nuxt-app/components/share/strip-lo.vue').default,SpecialDeal: require('/usr/src/nuxt-app/components/share/special-deal.vue').default,TabProductBrand: require('/usr/src/nuxt-app/components/share/tab-product-brand.vue').default,TabProductCategories: require('/usr/src/nuxt-app/components/share/tab-product-categories.vue').default,BlogItems: require('/usr/src/nuxt-app/components/share/blog-items.vue').default})
