
import { mapState, mapGetters, mapActions } from 'vuex'
import jwtDecode from 'jwt-decode'
const Cookie = process.client ? require('js-cookie') : undefined
export default {
  layout: 'blank-nav',
  name: 'SelectCompany',
  components: {},
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: {
        customerType: '',
        customerID: '',
        customerLevel: '',
        customerEmail: Object.keys(ctx.store.getters['user/userInfo']).length
          ? ctx.store.getters['user/userInfo'].email
          : '',
      },
    })
  },
  data() {
    return {
      accountSelect: 'person',
      companyList: [],
      personalList: [],
      adminCostcenterList: [],
      purchaseCostcenterList: [],
      selectedDepartment: '',
      expandHeight: '',
      selectedProfile: {},
      selectedCostcenter: {},
      roleAdmin: [1, 2],
      rolePurchase: [3, 4],
      isSelectedCostcenter: false,
      costcenterList: [],
      defaultLanguage: '',
      previousPage: '',
    }
  },
  computed: {
    ...mapState('authen', ['accessToken', 'openId', 'routeApproveRedirect']),
    ...mapGetters(['routeHistory']),
    decodeAccessToken() {
      if (this.$utils.isValid(this.accessToken)) {
        return jwtDecode(this.accessToken)
      } else return ''
    },
    hasAdminPermission() {
      let result = false
      if (
        this.isSelectedCostcenter &&
        Object.keys(this.selectedProfile).length &&
        this.accountSelect === 'company' &&
        this.selectedProfile.roles.length
      ) {
        result = this.roleAdmin.some((value) =>
          this.selectedProfile.roles.map((q) => q.role_id).includes(value)
        )
      }
      return result
    },
  },
  watch: {
    accountSelect: function (val) {
      if (val === 'person') {
        if (this.personalList.length === 1)
          this.selectedProfile = this.personalList[0]
        else this.selectedProfile = {}
      } else if (val === 'company') {
        if (this.companyList.length === 1)
          this.selectedProfile = this.companyList[0]
        else this.selectedProfile = {}
      }
    },
    isSelectedCostcenter: async function (val) {
      if (val === true) {
        if (
          Object.keys(this.selectedProfile).length &&
          this.accountSelect === 'company' &&
          this.selectedProfile.roles.length
        ) {
          await this.getCostcenterList('manual')
        }
      }
    },
  },
  mounted() {
    this.getUserData()
  },
  methods: {
    ...mapActions('user', [
      'setUserProfile',
      'setUserCostcenter',
      'removeUserProfile',
    ]),
    ...mapActions('authen', ['signout']),
    async onSubmit() {
      if (
        Object.keys(this.selectedProfile).length &&
        this.accountSelect === 'company'
      ) {
        this.isSelectedCostcenter =
          this.selectedProfile.categories_type === 'Costcenter' ? true : false
      }
      if (
        Object.keys(this.selectedProfile).length &&
        !this.isSelectedCostcenter
      ) {
        this.setUserProfile({
          payload: this.selectedProfile,
          profileType:
            this.accountSelect === 'person' ? 'standard' : 'eprocurement',
        })

        // gtm
        this.$gtm.push({
          event: 'user',
          ...this.$store.getters['user/userInfoTrack'],
        })

        if (this.accountSelect === 'company') {
          if (
            Object.keys(this.selectedProfile).length &&
            this.selectedProfile.roles.length
          ) {
            await this.getCostcenterList('auto')
          }
        } else {
          this.updateLastActive(
            this.selectedProfile.id,
            this.accountSelect === 'person' ? 'standard' : 'eprocurement'
          )
          this.redirectToHistoryPage()
        }
      }
      //  else if (
      //   Object.keys(this.selectedProfile).length &&
      //   this.isSelectedCostcenter
      // ) {
      //   console.log('select cost')
      // }
    },
    async updateLastActive(profileId, accountChannel) {
      let apiData = JSON.stringify({
        profile_id: profileId,
        account_channel: accountChannel,
      })
      await this.$profileProvider.updateUserLastActive(this.openId, apiData)
    },
    onSelectedProfile(data) {
      console.log(data)
      this.selectedProfile = data
    },
    async getUserData() {
      await this.$profileProvider
        .getUserDataById(this.openId)
        .then((result) => {
          console.log(result)
          if (result.data.accounts.length) {
            this.personalList = result.data.accounts
            this.selectedProfile = this.personalList[0]
          }

          if (result.data.companies.length) {
            this.companyList = result.data.companies
            if (!result.data.accounts.length) {
              this.accountSelect = 'company'
            }
          }
          if (this.$utils.isValid(result.data.default_language)) {
            this.defaultLanguage = result.data.default_language
            console.log('this.defaultLanguage', this.defaultLanguage)
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    async getCostcenterList(action) {
      await this.$profileProvider
        .getCostcentList(this.selectedProfile.id, this.openId)
        .then((result) => {
          console.log('result get constcenter', result)
          this.adminCostcenterList = result.data.costcenters
          if (this.adminCostcenterList.length) {
            this.costcenterList = JSON.parse(
              JSON.stringify(this.adminCostcenterList)
            )
            this.costcenterList.map((q) => (q.is_purchase_permission = false))
          }
          if (
            result.data.requesterlines.length &&
            result.data.costcenters.length
          ) {
            let requesterCostcenter = result.data.requesterlines.map(
              (q) => q.costcenter
            )
            // console.log('requesterCostcenter', requesterCostcenter)
            this.purchaseCostcenterList = result.data.costcenters.reduce(
              (result, item) => {
                console.log('item', item)
                if (requesterCostcenter.map((q) => q.id).includes(item.id))
                  result.push(item)
                return result
              },
              []
            )

            if (
              this.costcenterList.length &&
              this.purchaseCostcenterList.length
            ) {
              this.purchaseCostcenterList.forEach((item) => {
                let costcenter = this.costcenterList.find(
                  (q) => q.id === item.id
                )
                if (costcenter !== undefined) {
                  costcenter.is_purchase_permission = true
                  if (!Object.keys(this.selectedCostcenter).length) {
                    // default select costcent อันแรกที่มีสิทธิ์สั่งซื้อ
                    this.selectedCostcenter = costcenter
                  }
                }
              })
            }
            // console.log('purchaseCostcenterList', this.purchaseCostcenterList)
          }
          if (action === 'auto' && this.costcenterList.length) {
            //auto select costcenter top 1
            console.log('purchaseCostcenterList', this.purchaseCostcenterList)
            if (this.purchaseCostcenterList.length) {
              let tempCostcenter = Object.assign(
                {},
                this.purchaseCostcenterList[0]
              )
              delete tempCostcenter['shippings']
              delete tempCostcenter['invoices']
              delete tempCostcenter['create_info']
              delete tempCostcenter['update_info']
              this.setUserCostcenter({
                payload: tempCostcenter,
              })
            } else {
              let tempCostcenter = Object.assign({}, this.costcenterList[0])
              delete tempCostcenter['shippings']
              delete tempCostcenter['invoices']
              delete tempCostcenter['create_info']
              delete tempCostcenter['update_info']
              this.setUserCostcenter({
                payload: tempCostcenter,
              })
            }

            if (
              this.$utils.isPasswordExpire(this.selectedProfile.password_expire)
            )
              this.$router.push({
                path: this.$i18n.path('changepassword'),
              })
            this.updateLastActive(this.selectedProfile.id, 'eprocurement')
            this.redirectToHistoryPage()
          } else if (action === 'auto' && !this.costcenterList.length) {
            if (
              this.$utils.isPasswordExpire(this.selectedProfile.password_expire)
            )
              this.$router.push({
                path: this.$i18n.path('changepassword'),
              })
            this.updateLastActive(this.selectedProfile.id, 'eprocurement')
            this.redirectToHistoryPage()
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    onSubmitCostcenter() {
      if (Object.keys(this.selectedCostcenter).length) {
        this.setUserProfile({
          payload: this.selectedProfile,
          profileType: 'eprocurement',
        })
        let tempCostcenter = Object.assign({}, this.selectedCostcenter)
        delete tempCostcenter['shippings']
        delete tempCostcenter['invoices']
        delete tempCostcenter['create_info']
        delete tempCostcenter['update_info']
        this.setUserCostcenter({
          payload: tempCostcenter,
        })
        if (this.$utils.isPasswordExpire(this.selectedProfile.password_expire))
          this.$router.push({
            path: this.$i18n.path('changepassword'),
          })
        this.updateLastActive(this.selectedProfile.id, 'eprocurement')
        this.redirectToHistoryPage()
      }
    },
    async redirectToHistoryPage() {
      // saveRouteHistory
      console.log('routeApproveRedirect', Cookie.get('previousPage'))
      let previousPage = Cookie.get('previousPage')
      if (previousPage) {
        this.$router.push(previousPage)
      } else {
        if (this.$utils.isValid(this.routeHistory)) {
          if (
            this.routeHistory === '/' &&
            this.$utils.isValid(this.defaultLanguage)
          ) {
            this.$router.push({
              path: `${this.defaultLanguage === 'th' ? '/' : '/en/'}`,
            })
          } else {
            this.$router.push({
              path: this.routeHistory,
            })
          }
        } else {
          console.log('$i18n.locale', this.$i18n.locale)
          // this.$router.push({ path: '/en' })
          if (this.$utils.isValid(this.defaultLanguage)) {
            this.$router.push({
              path: `${this.defaultLanguage === 'th' ? '/' : '/en/'}`,
            })
          } else this.$router.push({ path: this.$i18n.path('') })

          // this.$router.push({
          //   // path: this.$i18n.path('approver/b2b'),
          //   path: this.$i18n.path(''),
          // })
        }
      }
      // braze
      this.$setBrazeUser(
        this.$store.getters['user/userInfo'],
        this.$store.getters['user/profileType'],
        this.$store.getters['user/userProfile']
      )
    },
    logout() {
      this.signout()
      this.removeUserProfile()
      // this.$router.go()
      // this.$router.push(this.$i18n.path(''))
      window.location.href = '/'
    },
    gotoNext() {
      console.log(this.selectCompany + ' ' + this.selectedDepartment)
    },
    getExpand(length, selectCompany) {
      this.selectedDepartment = ''
      this.selectCompany = selectCompany
      this.expandHeight =
        length !== 0 && selectCompany !== ''
          ? 'height:' + 40 * length + 'px'
          : ''
    },
  },
}
