import { render, staticRenderFns } from "./b2b.vue?vue&type=template&id=0f2fda95"
import script from "./b2b.vue?vue&type=script&lang=js"
export * from "./b2b.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {B2bNavbar: require('/usr/src/nuxt-app/components/template/b2b-navbar.vue').default,Loading: require('/usr/src/nuxt-app/components/share/loading.vue').default})
