import { render, staticRenderFns } from "./_keyword.vue?vue&type=template&id=0a36d687"
import script from "./_keyword.vue?vue&type=script&lang=js"
export * from "./_keyword.vue?vue&type=script&lang=js"
import style0 from "./_keyword.vue?vue&type=style&index=0&id=0a36d687&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FilterPanel: require('/usr/src/nuxt-app/components/template/filter-panel.vue').default,FilterController: require('/usr/src/nuxt-app/components/share/filter-controller.vue').default,ProductResult: require('/usr/src/nuxt-app/components/skeleton/product-result.vue').default,ProductPagination: require('/usr/src/nuxt-app/components/template/product-pagination.vue').default,SwiperItem: require('/usr/src/nuxt-app/components/share/swiper-item.vue').default,Notfound: require('/usr/src/nuxt-app/components/share/notfound.vue').default})
