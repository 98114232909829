
import BreadCrumb from '@/components/template/bread-crumb.vue'
import FilterPanel from '@/components/template/filter-panel.vue'
import FilterController from '@/components/share/filter-controller.vue'
import ProductResult from '@/components/template/product-result.vue'
import ProductPagination from '@/components/template/product-pagination.vue'
import SekletonResult from '@/components/skeleton/product-result.vue'
import { /* mapActions, */ mapState, mapGetters } from 'vuex'

import {
  IndexInsightsAlgoliaEnum,
  TriggerInsightsAlgoliaEnum,
} from '@/enum/event-insights-algolia.enum.js'

export default {
  name: 'SpecialDealSeeAll',
  components: {
    breadcrumb: BreadCrumb,
    'filter-panel': FilterPanel,
    // 'select-dropdown': SelectDropdown,
    'filter-controller': FilterController,
    'product-result': ProductResult,
    ProductPagination,
    'skeleton-result': SekletonResult,
    // 'swiper-image': SwiperImage,
    'instory-banner-component': async () =>
      await import('@/components/share/instory-banner.vue'),
  },
  head() {
    return {
      title: this.pageTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageContent,
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.pageTitle,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.pageContent,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.ogImage,
        },
      ],
      // link: [
      //   {
      //     rel: 'canonical',
      //     href:
      //       `https://www.ofm.co.th` +
      //       this.$i18n.path(`promotions/${this.$route.params.url}`),
      //   },
      // ],
    }
  },
  middleware(ctx) {
    let userProfile = ''
    switch (ctx.store.getters['user/profileType']) {
      case 'standard':
        userProfile = ctx.store.getters['user/userProfile'].offline_id
        break
      case 'eprocurement':
        userProfile = ctx.store.getters['user/userProfile'].company_code
        break
      default:
        break
    }
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: {
        customerType: ctx.store.getters['user/profileType']
          ? ctx.store.getters['user/profileType']
          : '',
        customerID: userProfile,
        customerLevel: Object.keys(ctx.store.getters['user/userProfile']).length
          ? ctx.store.getters['user/userProfile'].segment
          : '',
        customerEmail: Object.keys(ctx.store.getters['user/userInfo']).length
          ? ctx.store.getters['user/userInfo'].email
          : '',
      },
    })
  },
  async asyncData({ route, app, store, $axios }) {
    let promotionTitle = route.params.url
    // default
    let pageTitle = `${promotionTitle}${app.i18n.t('meta.titlePattern')}`
    let pageContent = `${app.i18n.t(
      'meta.descShop'
    )}${promotionTitle}${app.i18n.t('meta.descPromotion')}`
    let ogImage = 'https://ofm-cdn0.ofm.co.th/images/default-og.png'

    const headers = {
      Authorization: `Bearer ${process.env.NUXT_ENV_GUEST_TOKEN}`,
    }
    const http = process.server
      ? process.env.NUXT_ENV_BASE_URL_HTTP
      : process.env.NUXT_ENV_BASE_URL
    // axios
    try {
      const responsed = await $axios.get(
        encodeURI(
          `${http}${process.env.NUXT_ENV_SERVICE_PRODUCT}api/v1/Banners/campaigns/${promotionTitle}/meta`
        ),
        {
          headers,
        }
      )

      if (responsed.status === 200) {
        let lang = store.state.locale === 'th' ? 'en' : 'th'
        if (app.$utils.isValid(responsed.data.title_tag[store.state.locale])) {
          pageTitle = responsed.data?.title_tag[lang]
        }
        if (
          app.$utils.isValid(responsed.data.description_tag[store.state.locale])
        ) {
          pageContent = responsed.data?.description_tag[lang]
        }
        console.log('responsed', responsed.data)
      }
    } catch (e) {
      console.log('catch', e)
    }

    return {
      pageTitle: pageTitle,
      pageContent: pageContent,
      ogImage: ogImage,
    }
  },
  data() {
    return {
      condition_detail: '',
      breadcrumbItems: [
        {
          text: this.$t('promotions_url.breadcrumbItemsText'),
          href: '/',
        },
        {
          text: this.$route.params.name,
        },
      ],
      // swiperOption: {
      //   navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev',
      //   },
      // },
      sortBy: [
        {
          text: this.$t('promotions_url.dropdownSortByDefault'),
          value: '0',
        },
        {
          text: this.$t('promotions_url.dropdownPriceLowHigh'),
          value: '1',
        },
        {
          text: this.$t('promotions_url.dropdownPriceHighLow'),
          value: '2',
        },
        {
          text: this.$t('promotions_url.dropdownAZ'),
          value: '3',
        },
        {
          text: this.$t('promotions_url.dropdownZA'),
          value: '4',
        },
      ],
      perPage: 30,
      pageOption: [
        {
          text: 30,
          value: 30,
        },
        {
          text: 60,
          value: 60,
        },
        {
          text: 90,
          value: 90,
        },
      ],
      banner: null,
      page: 1,
      // perPage: 30,
      pageTotal: 0,
      name: '',
      url: '',
      condition: 'Funiture',
      filter: {},
      sort: '',
      view: 'grid',
      items: [],
      lang: '',
      attribute: [],
      paramAttribute: { attribute: [], price: [] },
      triggerEventAlgolia: TriggerInsightsAlgoliaEnum.NONSEARCHVIEWPDP,
      indexEventAlgolia: IndexInsightsAlgoliaEnum.PRODUCT,
      skeletonPage: true,
      isGetProduct: false,
      isGetAttribute: false,
      itemsRecentlyViewedProducts: [],
      isNotFound: false,
      showItems: false,
      firstTime: true,
      // instory
      instoryBanner: [],
      isSkeletonInstory: true,
      filterBanner: [],
    }
  },
  computed: {
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', ['userProfile', 'profileType', 'userCostcenter']),
    isBanner() {
      return this.filterBanner != null && this.filterBanner.length > 0
    },
    sortedBanner: function () {
      function compare(a, b) {
        if (a.sequence < b.sequence) return -1
        if (a.sequence > b.sequence) return 1
        return 0
      }

      return this.isBanner ? this.filterBanner.sort(compare) : []
    },
    attributesComp() {
      return this.attribute
    },
  },
  watch: {
    attributesComp: {
      handler: function (val) {
        if (val) this.skeletonPage = false
      },
      deep: true,
    },
  },
  async created() {
    this.lang = this.$store.state.locale
    this.name = this.$nuxt._route.params.name
    this.url = this.$nuxt._route.params.url

    if (process.client) {
      let url = new URL(window.location.href).searchParams.toString().length
      this.firstTime = url === 0
    }
  },
  updated() {
    this.skeletonPage = false
    // this.$_onGetInsider({
    //   page: {
    //     type: 'Promotion',
    //     custom: {
    //       category_name: null,
    //     },
    //   },
    // })
    // this.$_onInsiderResult({
    //   result: [...(this.items || [])],
    // })
  },
  async mounted() {
    await this.getInstorySpecialDeal()
    if (this.firstTime) {
      await this.getProduct()
      this.skeletonPage = false
    }
    await this.getAttributeProductHomePage()
  },
  methods: {
    compare(a, b) {
      if (a.sequence < b.sequence) return -1
      if (a.sequence > b.sequence) return 1
      return 0
    },
    async onPagination(page) {
      this.page = page
      await this.getProduct()
      this.showItems = true
    },
    async onChangePage(val) {
      this.perPage = val
      await this.getProduct()
      this.showItems = true
    },
    async getProduct() {
      console.log('start getproduct')
      this.isGetProduct = false
      this.showItems = false
      /* เรียก func เพื่อสร้าง พารามิเตอร์ */
      let params = await this.createParams()

      const response = await this.$bannerProvider.getBannerCampaignByNameOrUrl(
        params,
        false
      )
      console.log('response.status', response.data)
      if (response.status === 200) {
        this.showItems = true
        this.pageTotal = response.data.skus.totalRecords
        this.filterBanner = response.data.banner_images.filter(
          (image) => image.sequence !== 0 && image.sequence !== 1
        )
        this.banner = response.data
        this.condition_detail =
          this.lang === 'en'
            ? this.banner.condition.en
            : this.banner.condition.th
        console.log('banner', this.banner)
        this.items = response.data.skus.items
        console.log('this.items', this.items)
        if (this.items !== null && this.items.length) {
          // ทำ seq เพื่อส่งเข้า gtm event impression
          let startIndex =
            (response.data.skus.pageNo - 1) * response.data.skus.pageSize + 1
          this.items.map((item, index) => {
            item.seq = index + startIndex
          })
          //gtm
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'impression',
            currencyCode: 'THB',
            ecommerce: {
              impressions: this.items.map((item) => {
                return {
                  id: item.skus[0].sku_code,
                  price: this.$utils.formatPrice(
                    item.skus[0].price_include_vat,
                    2,
                    true
                  ),
                  list: 'Promotions',
                  position: item.seq,
                }
              }),
            },
          })

          // gtm GA4
          this.$gtm.push({ ecommerce: null })
          this.$gtm.push({
            event: 'view_promotion',
            ecommerce: {
              user: this.$store.getters['user/userInfoTrack'],
              promotion_id: this.$route.params.url,
              promotion_name: this.$route.params.url,
              items: this.items.map((item, index) => {
                const sku = item.skus[0]
                return {
                  item_id: sku.sku_code,
                  item_name: sku.product_names[1]?.display_name.th,
                  index: index,
                  price: sku.price_include_vat,
                  discount:
                    sku.retail_price.include_vat - sku.price_include_vat,
                  ...this.$store.getters['user/userPromotionTrack'],
                }
              }),
            },
          })
        }
        delete this.banner.skus
      } else {
        this.skeletonPage = false
        this.isNotFound = true
        // this.getUserProductHistory()
      }
      this.isGetProduct = true
    },
    async getAttributeProductHomePage() {
      let attr = []
      this.isGetAttribute = false
      let params = await this.createBrandAndAttributeParams()
      // get category
      // const responseCategory =
      //   await this.$productProvider.getwidgetCategoryData(params)
      // if (responseCategory.status === 200) {
      //   console.log('responseCategory', responseCategory.data)
      //   let categoryList = []
      //   responseCategory.data.forEach((element) => {
      //     categoryList.push({
      //       text: element.display_name[this.lang],
      //       value: { by: 'category', value: element.category_id },
      //     })
      //   })

      //   attr.push({
      //     title: this.$t('filterPanelComponent.category'),
      //     list: categoryList,
      //   })
      //   console.log('categoryList', categoryList)
      // }

      const response = await this.$bannerProvider.getBrandBannerCampaign(
        params,
        false
      )
      if (response.status === 200) {
        let brandList = []
        response.data.forEach((element) => {
          brandList.push({
            text: element.display_name[this.lang],
            value: { by: 'brand', value: element.id },
          })
        })

        attr.push({
          title: this.$t('promotions_url.labelTitleBrandAttribute'),
          list: brandList,
        })
      } else {
        this.showItems = true
      }

      const responseAttribute =
        await this.$bannerProvider.getAttributeBannerCampaign(params, false)
      if (responseAttribute.status === 200) {
        const attribute = responseAttribute.data
        // console.log('attr', attribute)
        /* เพื่อจัดรูปของข้อมูล ให้อยู่ในรูปที่ component filter-panel สามารถนำไป render สร้างเป็น filter ด้านซ้ายของเว็บไซต์ */
        attribute.forEach((element) => {
          let attributeList = []
          if (this.$utils.anyArrayObjectValid(element.value)) {
            element.value.forEach((value, index) => {
              if (
                this.$utils.isObjectValid(value) &&
                this.$utils.isValid(value[this.lang]) &&
                !attributeList.some((q) => q.value.value === value[this.lang])
              ) {
                attributeList.push({
                  text: value[this.lang],
                  value: {
                    index: index,
                    by: element.display_name[this.lang],
                    id: element.id,
                    value: value[this.lang],
                  },
                })
              }
            })
          }
          if (this.$utils.anyArrayObjectValid(attributeList)) {
            attr.push({
              title: element.display_name[this.lang],
              list: attributeList,
            })
          }
        })
      } else {
        this.showItems = true
      }
      this.attribute = await Object.assign([], [...new Set(attr)])
      this.isGetAttribute = true
      console.log('getattr', this.attribute)
    },
    async emitAttribute(value) {
      console.log('onemitAttribute')
      this.paramAttribute = value
      if (!this.firstTime) {
        await this.getProduct()
        this.skeletonPage = false
      }
      this.firstTime = false
      this.showItems = true
    },
    async createBrandAndAttributeParams() {
      let params = new URLSearchParams()
      // params.append('name', this.name)
      params.append('url', this.url)
      params.append('userOpenId', this.cartId)
      params.append('accountChannel', this.profileType)

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('companyId', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('userCategoryType', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenterId', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('userCategoryType', 'Standard')
      }
      params.append('segment', this.userProfile.segment || '')
      params.append('type', 'Promotion')
      params.append('isFree', false)
      return params
    },
    async createParams() {
      let params = new URLSearchParams()
      params.append('url', this.url)
      params.append('user_open_id', this.cartId)
      params.append('account_id', this.userProfile.id)
      params.append('account_channel', this.profileType)
      params.append('page_type', 'special_deal')
      params.append('segment', this.userProfile.segment || '')

      if (
        this.profileType === 'eprocurement' &&
        this.$utils.isObjectValid(this.userProfile)
      ) {
        if (this.$utils.isValid(this.userProfile.id)) {
          params.append('company_id', this.userProfile.id)
        }
        if (this.$utils.isValid(this.userProfile.categories_type)) {
          params.append('user_category_type', this.userProfile.categories_type)
        }
        if (this.$utils.isObjectKeyValid(this.userCostcenter, 'id')) {
          params.append('costcenter_id', this.userCostcenter.id)
        }
      } else if (this.profileType === 'standard') {
        params.append('user_category_type', 'Standard')
      }

      params.append('page', this.page)
      params.append('page_size', this.perPage)
      params.append('sort', this.sort)
      /* วนเพื่อสร้าง param category ตามค่าที่เราเลือก */
      /* วนเพื่อสร้าง param brand ตามค่าที่เราเลือก */
      if (this.paramAttribute.attribute.length !== 0) {
        this.paramAttribute.attribute.forEach((data) => {
          if (data.by === 'brand') {
            params.append('brands', data.value)
          } else if (data.by === 'category') {
            // params.append('categories', data.value)
          } else {
            params.append('attributes', data.id + '-' + data.value)
          }
        })
      }
      /* สร้าง params ราคา max min ในกรณีที่มีการ กำหนดค่า */
      console.log('this.paramAttribute', this.paramAttribute)
      if (this.paramAttribute.price.length !== 0) {
        params.append('min', this.paramAttribute.price[0])
        params.append('max', this.paramAttribute.price[1])
      }

      // params.append('isFree', false)
      return params
    },
    async selectSort(event) {
      if (this.showItems) {
        this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCT
        if (event.value === '0') this.sort = ''
        else if (event.value === '1') {
          this.sort = 'price-ascending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEASC
        } else if (event.value === '2') {
          this.sort = 'price-descending'
          this.indexEventAlgolia = IndexInsightsAlgoliaEnum.PRODUCTPRICEDESC
        } else if (event.value === '3') {
          this.sort = 'new-descending-' + this.lang
        } else this.sort = 'old-ascending-' + this.lang
        await this.getProduct()
        this.showItems = true
      }
    },
    // selectSort(e) {
    //   // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
    //   if (e.value === '0') this.sort = ''
    //   else if (e.value === '1') this.sort = 'price-ascending'
    //   else if (e.value === '2') this.sort = 'price-descending'
    //   else if (e.value === '3') this.sort = 'new-descending'
    //   else this.sort = 'old-ascending'
    //   this.getProduct()
    // },
    async getUserProductHistory() {
      let params = new URLSearchParams()
      params.append(
        'costcenter_id',
        Object.keys(this.userCostcenter).length ? this.userCostcenter.id : ''
      )
      params.append('status', 'Active')
      const result = await this.$productProvider.getUserProductHistory(
        this.cartId,
        this.userProfile.id,
        params
      )

      // console.log('user product history', result.data)
      this.itemsRecentlyViewedProducts = []
      if (result.status === 200 && result.data.length) {
        result.data.forEach((sku, index) => {
          if (this.$utils.isObjectValid(sku)) {
            this.itemsRecentlyViewedProducts.push({
              productId: sku.sku_code,
              productName: sku.product_names.find(
                (b) => b.type.toLowerCase() === 'online'
              ).display_name,

              // productImg: sku.images.find(
              //   (c) => c.size === 'thumbnail' && c.sequence === 1
              // ).url,
              productImg: this.imageNormalShowcase(sku),
              price_include_vat: sku.price_include_vat,
              retail_price: sku.retail_price.include_vat,
              is_promotion: sku.is_promotion,
              is_fixprice: sku.is_fixprice,
              is_hot_flash_sale: sku.is_hot_flash_sale,
              is_flash_sale: sku.is_flash_sale,
              is_online_only: sku.is_online_only,
              meta: sku.meta,
              seoMeta: sku.meta.seo_name,
              seq: index + 1,
              display_ratings: sku.display_ratings,
              display_ratings_count: sku.display_ratings_count,
              display_discount_rate: sku.display_discount_rate,
              promotion_prices: sku.promotion_prices,
              is_promotion_discount: sku.is_promotion_discount,
            })
          }
        })
      }
    },
    async getInstorySpecialDeal() {
      let fields = `id,
        name,
        display_size,
        page,
        instory_items.link,
        instory_items.sequence,
        instory_items.translations.languages_code,
        instory_items.translations.display_name,
        instory_items.translations.title_tag,
        instory_items.translations.alt,
        instory_items.image.id,
        instory_items.image.filename_download
      `
      let filter = {
        _and: [
          {
            sale_methods: {
              sale_methods_id: {
                _eq: this.$store.state.user.profileType === 'standard' ? 89 : 6,
              },
            },
          },
          { channels: { _contains: 'ofm' } },
          { url_campaign: { _eq: '/special-deal/' + this.url } },
          { page: { _eq: 'campaign' } },
          {
            _or: [
              {
                segment: { _icontains: this.userProfile?.segment || '_nempty' },
              },
              { segment: { _icontains: 'all' } },
            ],
          },
        ],
      }

      let deep = {
        instory_items: {
          translations: {
            _filter: {
              languages_code: {
                _eq: this.$i18n.locale === 'th' ? 'th-TH' : 'en-US',
              },
            },
          },
        },
      }

      await this.$directusProvider
        .onGetInstories({
          fields,
          filter,
          deep,
          // limit: 1,
          // sort: '-date_created',
        })
        .then(
          async (result) => {
            this.instoryBanner = result.data[0]
            console.log('directusProvider', this.instoryBanner)
            this.isSkeletonInstory = false
          },
          (error) => {
            console.log('getBannerCategoryData', error)
            this.isSkeletonInstory = false
          }
        )
    },
  },
}
