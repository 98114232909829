
import { mapActions, mapGetters } from 'vuex'
import modalWarning from '@/components/modal/checkout-warning'
import SummaryComponent from '@/components/checkout/summary-component.vue'

export default {
  layout: 'blank-nav',
  name: 'RePayment',
  components: {
    'modal-warning': modalWarning,
    'summary-component': SummaryComponent,
    'media-items': async () =>
      await import('@/components/installment/media-items.vue'),
    'radio-banks': async () =>
      await import('@/components/installment/radio-banks.vue'),
  },
  data() {
    return {
      isLoading: true,
      isInstallment: false,
      isShowPrivacy: false,
      consentStatus: false,
      isInstallmentOption: false,
      isMonthInstallmentEmpty: false,
      // hasInstallment: false,
      isCreditCardPromotionOnTop: false,
      hasActiveInstallment: false,
      hasAmountInstallment: false,
      isDisableButton: false,
      installment: {
        installment_option: '',
        selectedMonth: '',
        cardNo: '',
        cardName: '',
        cardExpYear: '',
        cardExpMonth: '',
        cardCVV: '',
        orderId: '',
        user_open_id: '',
        code: '',
        period: '',
        periods: [],
        agentCode: '',
        language: this.$i18n.locale,
        plan_id: '',
        payment_bank_installment_id: '',
      },
      payment: {
        cardNo: '',
        cardName: '',
        cardExpYear: '',
        cardExpMonth: '',
        cardCVV: '',
        isSaveCard: true,
        encCardData: '',
        maskedCardNo: '',
        agentCode: '',
        language: this.$i18n.locale,
      },
      orderDocument: {},
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        voucher_no: '',
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
        is_use_coupon: false,
        used_voucher_type: '',
      },
      remember: false,
      modal: {
        warning: false,
      },
      warningMessages: [],
      payments: [],
      periods: [],
      period_months: [],
      payment_type: '',
      error_messange: {
        en: '',
        th: '',
      },
      is_messange: false,
      creditCard: {
        promotions: [],
        isShow: false,
        promotion_id: '',
        promotion_option: {},
      },
      // installment
      installment_type: '',
      installment_data: [],
      paymentsCart: [],
      paymentsSku: [],
      periodsInstallment: [],
      isInstallmentOptionCart: false,
      bankInstallmentOptions: [],
      selectedPlan: {},
      isShowInActive: false,
      inActiveItems: [],
      warningMessage: {},
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType', 'userInfo']),
    ...mapGetters('authen', ['openId']),
    isAdmin() {
      if (this.profileType === 'eprocurement') {
        if (this.userProfile.roles !== null && this.userProfile.roles.length) {
          return this.userProfile.roles.some(
            (q) => q.role_id === 1 || q.role_id === 2
          )
        } else return false
      } else return false
    },
    securePayUrl() {
      return process.env.NUXT_ENV_2C2P_SECURE_PAY_URL
    },

    isCreditCardPromotionOption() {
      return this.$utils.anyArrayObjectValid(this.creditCard.promotions)
    },
    // isCreditCardPromotionOption() {
    //   return this.orderDocument.credit_card_method === 'Normal' &&
    //   this.orderDocument.payment_code == PaymentCode.Cash.ToString() &&
    // 	this.orderDocument.payment_type == PaymentType.Payment2C2PCreditCardOnline.GetEnumDisplayName() &&
    // 	this.$utils.isValid(this.orderDocument.credit_card_no) &&
    // 	this.$utils.isValid(this.orderDocument.payment_disc_code) &&
    // 	this.$utils.isValid(this.orderDocument.credit_card_method) &&
    //   this.$utils.isValid(this.creditCard.promotion_id)
    // },
  },
  async created() {
    this.$profileProvider
      .getUserConsetStatus(this.openId, this.userInfo.email)
      .then((result) => {
        if (result.status === 200) {
          this.isShowPrivacy =
            result.data.consent_marketing_status === true ? false : true
        } else this.isShowPrivacy = true
      })
  },
  watch: {
    installment_type: {
      async handler(val) {
        console.log('installment_type', val)
        this.clearInstallment()
      },
    },
    'payment.cardNo': {
      async handler(val) {
        if (
          this.$utils.isObjectValid(this.orderDocument) &&
          this.$utils.isValid(this.orderDocument.payment_type) &&
          this.orderDocument.payment_type === '2C2P Credit Card Online' &&
          this.$utils.anyArrayObjectValid(this.creditCard.promotions) &&
          this.$utils.isValid(val) &&
          val.length === 16
        ) {
          console.log('watch payment', val)
          await this.generateActiveCreditCardPromotions()
        } else {
          this.resetEnableCreditCardPromotion()
        }

        // this.originalPayment = Object.assign({}, val)
      },
    },
    // isCreditCardPromotionOnTop(val) {
    //   return
    // },
  },
  async mounted() {
    if (process.client && this.$route.params.id !== undefined) {
      await this.getOrderDocument()
    } else {
      //TODO
    }
    // draft creditCardPromotion
    // this.creditCardPromotion = [
    //   {
    //     id: 1,
    //     img: require(`~/assets/images/payment/THE1CARD-MC-BLACK.png`),
    //     card_name: {
    //       th: 'Central The 1 BLACK',
    //       en: 'Central The 1 BLACK',
    //     },
    //     promotion_detail: {
    //       th: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //       en: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //     },
    //     promotion_remark: {
    //       th: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //       en: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //     },
    //     is_enable: true,
    //   },
    //   {
    //     id: 2,
    //     img: require(`~/assets/images/payment/THE1CARD-MC-LUXE.png`),
    //     card_name: {
    //       th: 'Central The 1 LUXE',
    //       en: 'Central The 1 LUXE',
    //     },
    //     promotion_detail: {
    //       th: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //       en: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //     },
    //     promotion_remark: {
    //       th: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //       en: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //     },
    //     is_enable: true,
    //   },
    //   {
    //     id: 3,
    //     img: require(`~/assets/images/payment/THE1CARD-MC-REDZ.png`),
    //     card_name: {
    //       th: 'Central The 1 REDZ',
    //       en: 'Central The 1 REDZ',
    //     },
    //     promotion_detail: {
    //       th: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //       en: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //     },
    //     promotion_remark: {
    //       th: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //       en: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //     },
    //     is_enable: true,
    //   },
    //   {
    //     id: 4,
    //     img: require(`~/assets/images/payment/THE1CARD-MC-BLACK.png`),
    //     card_name: {
    //       th: 'Central The 1 BLACK',
    //       en: 'Central The 1 BLACK',
    //     },
    //     promotion_detail: {
    //       th: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //       en: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //     },
    //     promotion_remark: {
    //       th: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //       en: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //     },
    //     is_enable: false,
    //   },
    //   {
    //     id: 5,
    //     img: require(`~/assets/images/payment/THE1CARD-MC-LUXE.png`),
    //     card_name: {
    //       th: 'Central The 1 LUXE',
    //       en: 'Central The 1 LUXE',
    //     },
    //     promotion_detail: {
    //       th: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //       en: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //     },
    //     promotion_remark: {
    //       th: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //       en: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //     },
    //     is_enable: false,
    //   },
    //   {
    //     id: 6,
    //     img: require(`~/assets/images/payment/THE1CARD-MC-REDZ.png`),
    //     card_name: {
    //       th: 'Central The 1 REDZ',
    //       en: 'Central The 1 REDZ',
    //     },
    //     promotion_detail: {
    //       th: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //       en: 'ลดเพิ่ม 5% ไม่มีขั้นต่ำ + รับคะแนน The1 x 5',
    //     },
    //     promotion_remark: {
    //       th: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //       en: 'เฉพาะสินค้าราคาปกติที่ร่วมรายการบัตรเครดิต',
    //     },
    //     is_enable: false,
    //   },
    // ]
  },
  methods: {
    ...mapActions('order', ['setDisplay2C2PPaymentStep']),
    ...mapActions('user', ['setUserInfo']),
    onSubmitPayment() {
      this.$refs['2c2p-payment-form'].validate().then((result) => {
        if (result) {
          this.validateCreditCard()
          if (this.isShowPrivacy) {
            this.createConsentStatus()
          }
        }
      })
    },
    onSubmitInstallment() {
      console.log('ok')
      this.isMonthInstallmentEmpty = !this.$utils.isValid(
        this.installment.selectedMonth
      )
      if (this.installment.installment_option)
        this.$refs['2c2p-installment-payment-form']
          .validate()
          .then((result) => {
            if (
              result &&
              this.$utils.isValid(this.installment.selectedMonth) &&
              this.installment.cardNo
            ) {
              this.validateCreditCard()
            }
          })
    },
    async getOrderDocument() {
      await this.$orderProvider
        .getRePaymentCreditCardOrderDocument(
          this.$route.params.id,
          this.openId,
          this.userProfile.id,
          this.isAdmin
        )
        .then(async (result) => {
          if (result.status === 200) {
            this.isLoading = false
            this.orderDocument = result.data
            console.warn('this.orderDocument :>> ', this.orderDocument)
            this.summary = {
              net_amt: this.orderDocument.net_amt,
              net_deliveryfee_excvat: this.orderDocument.net_deliveryfee_excvat,
              nonvat_prod_net_amt: this.orderDocument.nonvat_prod_net_amt,
              vat_amt: this.orderDocument.vat_amt,
              vat_prod_net_amt: this.orderDocument.vat_prod_net_amt,
              voucher_disc_amt_excvat:
                this.orderDocument.voucher_disc_amt_excvat,
              voucher_no: this.orderDocument.voucher_no,
              other_disc_amt_incvat: this.orderDocument.other_disc_amt_incvat,
              other_disc_amt: this.orderDocument.other_disc_amt_excvat,
              payment_disc_amt_excvat:
                this.orderDocument.payment_disc_amt_excvat,
              payment_disc_code: this.orderDocument.payment_disc_code,
              is_use_coupon: this.orderDocument.is_use_coupon,
              used_voucher_type: this.orderDocument.used_voucher_type,
              promo_disc_amt_incvat: this.orderDocument.promo_disc_amt_incvat,
              promo_disc_amt_excvat: this.orderDocument.promo_disc_amt_excvat,
            }
            if (this.orderDocument.credit_card_method === 'Normal') {
              this.isInstallment = false
              await this.generateCreditCardPromotions()
              //  this.orderDocument.credit_card_no
            } else {
              this.isInstallment = true
              await this.getInstallment2c2p()
            }
          }
        })
    },
    async generateCreditCardPromotions(cardNo = '') {
      this.creditCard.promotions = []
      let result = await this.getPaymentCreditCardPromotions(cardNo)
      console.log('result', result)
      if (this.$utils.anyArrayObjectValid(result)) {
        result.forEach((value) => {
          this.creditCard.promotions.push({
            id: value.id,
            img: value.payment_bank_credit_card.card_image.url, //require(`~/assets/images/payment/THE1CARD-MC-BLACK.png`),
            bank_name: {
              th: value.payment_bank_master.name.th,
              en: value.payment_bank_master.name.en,
            },
            credit_card: {
              id: value.payment_bank_credit_card.id,
              th: value.payment_bank_credit_card.name.th,
              en: value.payment_bank_credit_card.name.en,
            },
            promotion_remark: {
              th: value.description.th,
              en: value.description.en,
            },
            is_enable: false,
          })
        })
      }
    },
    async getPaymentCreditCardPromotions(cardNo = '') {
      this.loading = true
      const params = new URLSearchParams()
      if (this.$utils.isValid(cardNo)) params.append('card_no', cardNo)
      let result =
        await this.$shoppingCartProvider.getPaymentCreditCardPromotionsOrderDocument(
          this.$route.params.id,
          params
        )
      this.loading = false
      if (result.status === 200) return result.data
      return null
    },
    async validateCreditCard() {
      let validateFlag = false
      let encryptedCardInfo = ''
      let errorDescription = ''
      let maskedCardInfo = ''
      // let expMonthCardInfo = ''
      // let expYearCardInfo = ''
      // eslint-disable-next-line no-undef
      let payment_form = this.isInstallment // this.hasInstallment
        ? '2c2p-installment-payment-form'
        : '2c2p-payment-form'
      My2c2p.getEncrypted(
        payment_form,
        function (encryptedData, errCode, errDesc) {
          console.log('encryptedData', encryptedData)
          if (errCode != 0) {
            // alert(errDesc + ' (' + errCode + ')')
            errorDescription = errCode
          } else {
            console.log('encryptedData', encryptedData)
            encryptedCardInfo = encryptedData.encryptedCardInfo
            maskedCardInfo = encryptedData.maskedCardInfo
            validateFlag = true
            // document.paymentRequestForm.submit()
          }
        }
      )
      if (
        (payment_form === '2c2p-payment-form' &&
          this.payment.cardExpMonth.length === 1) ||
        (payment_form === '2c2p-installment-payment-form' &&
          this.installment.cardExpMonth.length === 1)
      ) {
        errorDescription = 6
        validateFlag = false
      }

      if (
        (payment_form === '2c2p-payment-form' &&
          this.payment.cardExpMonth === '00') ||
        (payment_form === '2c2p-installment-payment-form' &&
          this.installment.cardExpMonth === '00')
      ) {
        errorDescription = 8
        validateFlag = false
      }
      if (
        payment_form === '2c2p-installment-payment-form' &&
        this.installment.cardNo.length < 16
      ) {
        errorDescription = 2
        validateFlag = false
      }

      if (validateFlag) {
        this.payment.encCardData = encryptedCardInfo
        // this.payment.maskedCardNo = maskedCardInfo
        this.payment.maskedCardNo = this.payment.cardNo
        await this.updateOrderPaymentDetail()
      } else {
        if (errorDescription !== '') {
          console.log('errorDescription')
          this.warningMessages = []
          this.warningMessages.push({
            message: this.changeLanguage(errorDescription),
          })
          this.forceRedirect = true
          this.modal.warning = true
        }
      }
    },
    changeLanguage(errorDescription) {
      this.isDisableButton = false
      let messageDescription = {}

      // if (this.$i18n.locale === 'th') {
      switch (errorDescription) {
        case 6:
          // messageDescription = 'กรุณากรอก ปีที่บัตรหมดอายุ เป็นปี ค.ศ. 4 หลัก'
          messageDescription = {
            th: 'กรุณากรอก เดือนที่บัตรหมดอายุ เป็นตัวเลข 2 หลัก',
            en: 'the length of expiry month field does not match',
          }
          break
        case 2:
          messageDescription = {
            th: 'หมายเลขบัตร หรือ จำนวนหลักที่ระบุไม่ถูกต้อง โปรดตรวจสอบและระบุใหม่อีกครั้งด้วยตัวเลข 16 หลัก',
            en: 'card number is invalid ',
          }
          break
        case 10:
          messageDescription = {
            th: 'หมายเลข CVV ไม่ถูกต้อง โปรดตรวจสอบและระบุใหม่อีกครั้งด้วยตัวเลข 3 หลัก',
            en: 'invalid CVV ',
          }
          break
        case 8:
        case 9:
          messageDescription = {
            th: 'เดือนที่บัตรหมดอายุไม่ถูกต้อง โปรดตรวจสอบและระบุใหม่อีกครั้ง',
            en: 'invalid card expiry month',
          }
          break
        case 7:
          messageDescription = {
            th: 'บัตรเครดิตที่คุณระบุหมดอายุแล้ว โปรดตรวจสอบปีที่บัตรหมดอายุที่ระบุหรือเปลี่ยนบัตรใบใหม่',
            en: 'card already expired(year)',
          }
          break
        default:
          messageDescription
          break
      }
      return messageDescription
      // } else {
      //   return messageDescription
      // }
    },
    async updateOrderPaymentDetail() {
      let objData = {
        credit_card_method: this.orderDocument.credit_card_method,
        // credit_card_no: this.payment.maskedCardNo,
        credit_card_no: this.$encrypt.generateKeysAndEncrypt(
          this.payment.cardNo
        ),
        payment_bank_installment_id:
          this.installment.payment_bank_installment_id,
      }

      if (this.isInstallment) {
        // this.hasInstallment
        objData.credit_card_name = this.orderDocument.credit_card_name
      }

      if (this.$utils.isValid(this.creditCard.promotion_id)) {
        objData.credit_card_promotion_id = this.creditCard.promotion_id
      }

      let apiData = JSON.stringify(objData)
      const result = await this.$orderProvider.updateOrderPaymentDetail(
        this.orderDocument.id,
        apiData
      )
      if (result.status === 204) {
        this.submitTo2c2pGateway(
          this.orderDocument.payment_transaction_ref_no,
          this.orderDocument.id
        )
      } else if (result.status === 412) {
        if (this.$utils.isValid(this.creditCard.promotion_id)) {
          await this.resetSelectedCreditCardPromotionOnTop()
          this.resetDefaultSummary()
        }
      }
    },
    async submitTo2c2pGateway(orderNo, orderId) {
      console.warn('object :>> ', this.orderDocument)
      if (this.isInstallment) {
        // this.hasInstallment
        this.installment.orderId = orderId
        this.installment.user_open_id = this.openId
        this.installment.code = 'IPP'
        this.installment.period = this.installment.selectedMonth
        this.installment.periods = this.period_months
        console.warn('this.installment :>> ', this.installment)
      } else {
        this.payment.orderId = orderId
        this.payment.user_open_id = this.openId
      }
      console.log('object :>> ', this.isInstallment)

      let dataPayment = this.isInstallment ? this.installment : this.payment
      delete dataPayment['encCardData']
      let body = {
        data_encrypt: this.$encrypt.generateKeysAndEncrypt(
          JSON.stringify(dataPayment)
        ),
      }
      // await this.$productProvider
      //   .create2c2pPayment(this.isInstallment ? this.installment : this.payment)
      await this.$orderProvider.create2c2pPayment(body).then((result) => {
        let payload = {
          respCode: result.data.respCode,
          respDesc: result.data.respDesc,
          statusCode: result.data.statusCode,
        }
        console.warn('payload :>> ', payload)

        console.log('result :>> ', result)
        if (result.status === 201) {
          console.warn('object :>> ', result.data)
          this.setDisplay2C2PPaymentStep(payload)
          if (result.data.respCode === '1001') {
            window.location.href = result.data.url
          } else {
            this.$router.push({
              path: this.$i18n.path(`checkout/callback/${orderId}`),
            })
          }
        }
      })
      //#region  2c2p_old
      // let CryptoJS = require('crypto-js')
      // let merchantID = process.env.NUXT_ENV_2C2P_MERCHANT_ID //Get MerchantID when opening account with 2C2P
      // let secretKey = process.env.NUXT_ENV_2C2P_SECRET_KEY //Get SecretKey from 2C2P PGW Dashboard

      // //Transaction Information
      // let desc = 'order e-Procurement'
      // let uniqueTransactionCode = orderNo
      // let currencyCode = '764'
      // let totalAmt = this.summary.net_amt + this.summary.vat_amt
      // console.log('totalAmt1', totalAmt)
      // totalAmt = this.$utils.formatPrice(totalAmt, 2, true)
      // console.log('totalAmt2', totalAmt)
      // totalAmt = totalAmt.toString().replace('.', '')
      // console.log('totalAmt3', totalAmt)
      // let totalDigit = 12
      // while (totalAmt.length < totalDigit) totalAmt = '0' + totalAmt
      // console.log('totalAmt4', totalAmt)
      // let amt = totalAmt
      // let panCountry = 'TH'
      // let request3DS = 'Y'
      // //Customer Information
      // let cardholderName = this.payment.cardName

      // //Encrypted card data
      // let encCardData = this.payment.encCardData

      // //Payment Options
      // let storeCard = 'N' //Enable / Disable Tokenization
      // let returnUrl = `${process.env.NUXT_ENV_HOST_URL}checkout/callback/${orderId}?redirectUrl=account/order`
      // let backendUrl = process.env.NUXT_ENV_2C2P_BACKEND_URL
      // //Request Information
      // let version = '9.9'
      // let processType = 'I'

      // //Construct payment request message
      // let xml = `<PaymentRequest>
      //   <merchantID>${merchantID}</merchantID>
      //   <processType>${processType}</processType>
      //   <uniqueTransactionCode>${uniqueTransactionCode}</uniqueTransactionCode>
      //   <desc>${desc}</desc>
      //   <amt>${amt}</amt>
      //   <currencyCode>${currencyCode}</currencyCode>
      //   <panCountry>${panCountry}</panCountry>
      //   <cardholderName>${cardholderName}</cardholderName>
      //   <storeCard>${storeCard}</storeCard>
      //   <encCardData>${encCardData}</encCardData>
      //   <request3DS>${request3DS}</request3DS>
      //   <returnUrl>${returnUrl}</returnUrl>
      //   <backendUrl>${backendUrl}</backendUrl>
      //   </PaymentRequest>`

      // let paymentPayload = btoa(xml)
      // let signature = CryptoJS.HmacSHA256(paymentPayload, secretKey)
      // signature = signature.toString().toUpperCase()
      // // console.log('signature', signature)
      // let payloadXML = `<PaymentRequest>
      //       <version>${version}</version>
      //       <payload>${paymentPayload}</payload>
      //       <signature>${signature}</signature>
      //       </PaymentRequest>`
      // // let payload = base64_encode(payloadXML) //encode with base64
      // // console.log('payloadXML', payloadXML)
      // let data = btoa(payloadXML) //Convert payload to base64
      // // console.log('data', data)
      // // let payload = encodeURI(data)

      // // let paymentRequest = document.getElementById('paymentRequest')
      // this.$refs['paymentRequest'].value = data
      // this.$refs.paymentRequestForm.submit()
      //#endregion
    },
    async onSelectCreditCardPromotion(promotion) {
      console.log('reCalulateOrderTemporary', promotion)
      if (this.$utils.isValid(promotion)) {
        this.creditCard.promotion_id = promotion.id
        let params = new URLSearchParams()
        params.append('credit_card_promotion_id', promotion.id)
        params.append('credit_card_id', promotion.credit_card.id)
        params.append('card_no', this.payment.maskedCardNo)
        let result =
          await this.$orderProvider.reCalulateOrderDocumentHeadCreditCard(
            this.$route.params.id,
            params
          )
        if (result.status === 200) {
          console.log('reCalulateOrderTemporary success')
          let _orderDocumemt = result.data
          this.summary = {
            net_amt: _orderDocumemt.net_amt,
            net_deliveryfee_excvat: _orderDocumemt.net_deliveryfee_excvat,
            nonvat_prod_net_amt: _orderDocumemt.nonvat_prod_net_amt,
            vat_amt: _orderDocumemt.vat_amt,
            vat_prod_net_amt: _orderDocumemt.vat_prod_net_amt,
            voucher_disc_amt_excvat: _orderDocumemt.voucher_disc_amt_excvat,
            voucher_no: _orderDocumemt.voucher_no,
            other_disc_amt_incvat: _orderDocumemt.other_disc_amt_incvat,
            other_disc_amt: _orderDocumemt.other_disc_amt,
            payment_disc_amt_excvat: _orderDocumemt.payment_disc_amt_excvat,
            payment_disc_code: _orderDocumemt.payment_disc_code,
            is_use_coupon: _orderDocumemt.is_use_coupon,
            used_voucher_type: _orderDocumemt.used_voucher_type,
            promo_disc_amt_incvat: _orderDocumemt.promo_disc_amt_incvat,
            promo_disc_amt_excvat: _orderDocumemt.promo_disc_amt_excvat,
          }
        } else {
          console.log('reCalulateOrderTemporary error')
          await this.resetSelectedCreditCardPromotionOnTop()
        }
      }
    },
    createConsentStatus() {
      let payload = JSON.stringify({
        email: this.userInfo.email,
        user_open_id: this.openId,
        consent_status: this.consentStatus,
      })
      this.$profileProvider.createUserConsentStatus(payload)
      let tempUserData = this.userInfo
      tempUserData.consent.consent_marketing_status = this.consentStatus
      console.log('tempUserData', tempUserData)
      this.setUserInfo({ payload: tempUserData })
    },
    async onConfirmModalWarning() {},
    // installment skus
    async getInstallment2c2p() {
      let params = new URLSearchParams()
      params.append('user_open_id', this.openId)
      params.append('order_id', this.$route.params.id)
      params.append('is_repayment', true)
      const result = await this.$productProvider.paymentnstallment2c2p(params)
      if (result.status === 200) {
        let data = result.data
        this.installment_data = data
        console.warn('installment_data :>> ', this.installment_data)
        console.warn('payments :>> ', this.payments)
        this.paymentsCart = []
        this.paymentsSku = []
        this.periodsInstallment = []

        // cart
        if (
          data.installment_cart?.is_active &&
          data.installment_cart?.installment_banks.length
        ) {
          data.installment_cart.installment_banks.forEach((a) => {
            console.log('data.installment', a)
            // let paymentName = a.plan_name[this.$i18n.lang]
            let paymentImage =
              this.$utils.isValid(a.bank_images) &&
              a.bank_images.some((a) => a.type === 'image')
                ? a.bank_images.find((a) => a.type === 'image')?.cdn_url ?? ''
                : ''
            this.paymentsCart.push({
              bank_sequence: a.bank_sequence,
              bank_id: a.bank_id,
              bank_name: a.bank_name[this.$i18n.locale],
              name: a.title[this.$i18n.locale],
              image: paymentImage,
              is_active: a.is_active,
              description: a.description[this.$i18n.locale],
              installment_plan: a.installment_plan,
              warning_message: a.warning_message,
            })
          })
        }

        // sku
        if (
          data.installment_sku?.is_active &&
          data.installment_sku?.installment_banks.length
        ) {
          data.installment_sku.installment_banks.forEach((a) => {
            console.log('data.installment', a)
            // let paymentName = a.plan_name[this.$i18n.lang]
            let paymentImage =
              this.$utils.isValid(a.bank_images) &&
              a.bank_images.some((a) => a.type === 'image')
                ? a.bank_images.find((a) => a.type === 'image')?.cdn_url ?? ''
                : ''
            this.paymentsSku.push({
              bank_sequence: a.bank_sequence,
              bank_id: a.bank_id,
              bank_name: a.bank_name[this.$i18n.locale],
              name: a.title[this.$i18n.locale],
              image: paymentImage,
              is_active: a.is_active,
              description: a.description[this.$i18n.locale],
              installment_plan: a.installment_plan,
              warning_message: a.warning_message,
            })
          })
        }
        console.log('paymentsSku', this.paymentsSku)
        this.hasActiveInstallment = data.is_active
        this.hasAmountInstallment =
          data.is_active &&
          ((this.$utils.anyArrayObjectValid(this.paymentsCart) &&
            this.paymentsCart.some((q) => q.is_active)) ||
            (this.$utils.anyArrayObjectValid(this.paymentsSku) &&
              this.paymentsSku.some((q) => q.is_active)))
        this.isDisableButton =
          !this.hasActiveInstallment || !this.hasAmountInstallment

        if (
          this.installment_data?.installment_sku?.is_active === false &&
          this.installment_data?.installment_cart?.is_active === true
        ) {
          this.installment_type = 'installment_cart'
        } else if (
          this.installment_data?.installment_sku?.is_active === true &&
          this.installment_data?.installment_cart?.is_active === false
        ) {
          this.installment_type = 'installment_sku'
        } else if (
          this.installment_data?.installment_sku?.is_active === true &&
          this.installment_data?.installment_cart?.is_active === true
        ) {
          this.installment_type = 'installment_sku'
        }
      }
    },
    // async getInstallment2c2p() {
    //   let params = new URLSearchParams()
    //   params.append('user_open_id', this.openId)
    //   params.append('order_id', this.$route.params.id)
    //   params.append('is_repayment', true)
    //   const result = await this.$productProvider.paymentnstallment2c2p(params)
    //   if (result.status === 200) {
    //     console.warn('object :>> ', result.data)
    //     let data = result.data
    //     this.payment_type = data.payment_type
    //     // this.hasInstallment = data.payment_type === 'Installment' ? true : false
    //     this.payments = []
    //     this.periodsInstallment = []
    //     // this.isInstallment = data.isInstallment ? false : true
    //     if (data.isInstallment) {
    //       data.installment.forEach((a) => {
    //         let paymentName = a.name
    //         let paymentImage =
    //           this.$utils.isValid(a.bank_images) &&
    //           a.bank_images.some((a) => a.type === 'image')
    //             ? a.bank_images.find((a) => a.type === 'image')?.url ?? ''
    //             : ''
    //         let paymentPrice = 0

    //         if (this.$utils.isObjectValid(a.period_top)) {
    //           let period_top = a.period_top
    //           paymentName = period_top.price
    //           paymentPrice = this.$utils.isValid(period_top.price_top)
    //             ? period_top.price_top
    //             : 0
    //         }

    //         this.payments.push({
    //           bank_id: a.bank_id,
    //           bank_name: a.bank_name,
    //           name: paymentName,
    //           image: paymentImage,
    //           is_enable: a.is_enable,
    //           price: paymentPrice,
    //         })
    //         a.periods
    //           .sort(function (a, b) {
    //             return a.month - b.month
    //           })
    //           .forEach((b) => {
    //             this.periodsInstallment.push(b)
    //           })
    //       })
    //     }

    //     console.log('periodsInstallment :>> ', this.periodsInstallment)
    //     this.hasActiveInstallment = data.isInstallment
    //     this.hasAmountInstallment =
    //       data.isInstallment &&
    //       this.$utils.anyArrayObjectValid(this.payments) &&
    //       this.payments.some((q) => q.is_enable)
    //     this.isDisableButton =
    //       !this.hasActiveInstallment || !this.hasAmountInstallment
    //   }
    // },
    async checkValidateCardNo() {
      this.error_messange = ''
      let params = new URLSearchParams()
      params.append('user_open_id', this.openId)
      params.append('bank_id', this.installment.installment_option)
      // params.append('bin', this.installment.cardNo)
      params.append(
        'bin',
        this.$encrypt.generateKeysAndEncrypt(this.installment.cardNo)
      )
      const result = await this.$productProvider.paymentnstallment2c2pValidate(
        params
      )
      if (result.status === 200) {
        this.is_messange = false
        this.error_messange = ''
      } else {
        this.is_messange = true
        this.error_messange = {
          en: 'Invalid card',
          th: 'เลขบัตรผิด',
        }
      }
    },
    async generateActiveCreditCardPromotions() {
      this.resetEnableCreditCardPromotion()
      // this.payment.maskedCardNo = this.maskedCardInfo(
      //   this.payment.cardNo,
      //   6,
      //   'XXXXXX'
      // )
      this.payment.maskedCardNo = this.$encrypt.generateKeysAndEncrypt(
        this.payment.cardNo
      )
      let result = await this.getPaymentCreditCardPromotions(
        this.payment.maskedCardNo
      )

      if (this.$utils.anyArrayObjectValid(result)) {
        let creditCardPromotionIds = result.map((q) => q.id)
        let mapCreditCardPromotionId = this.creditCard.promotions.map((x) => {
          x.is_enable = creditCardPromotionIds.includes(x.id)
          return x
        })
        this.creditCard.promotions.length = 0
        this.creditCard.promotions.push(...mapCreditCardPromotionId)

        this.creditCard.promotions.sort((x, y) => y.is_enable - x.is_enable)
        // console.log('this.creditCard.promotions', this.creditCard.promotions)
        return true
      }
      // }
      return false
    },
    async resetSelectedCreditCardPromotionOnTop() {
      this.creditCard.promotion_option = {}
      this.creditCard.promotion_id = ''
      await this.generateCreditCardPromotions()
      await this.generateActiveCreditCardPromotions()
    },
    resetEnableCreditCardPromotion() {
      this.creditCard.promotion_option = {}
      this.creditCard.promotion_id = ''
      this.resetDefaultSummary()

      let mapCreditCardPromotionId = this.creditCard.promotions.map((x) => {
        x.is_enable = false
        return x
      })
      this.creditCard.promotions.length = 0
      this.creditCard.promotions.push(...mapCreditCardPromotionId)
    },
    resetDefaultSummary() {
      this.summary = {
        net_amt: this.orderDocument.net_amt,
        net_deliveryfee_excvat: this.orderDocument.net_deliveryfee_excvat,
        nonvat_prod_net_amt: this.orderDocument.nonvat_prod_net_amt,
        vat_amt: this.orderDocument.vat_amt,
        vat_prod_net_amt: this.orderDocument.vat_prod_net_amt,
        voucher_disc_amt_excvat: this.orderDocument.voucher_disc_amt_excvat,
        voucher_no: this.orderDocument.voucher_no,
        other_disc_amt_incvat: this.orderDocument.other_disc_amt_incvat,
        other_disc_amt: this.orderDocument.other_disc_amt_excvat,
        payment_disc_amt_excvat: this.orderDocument.payment_disc_amt_excvat,
        payment_disc_code: this.orderDocument.payment_disc_code,
        is_use_coupon: this.orderDocument.is_use_coupon,
        used_voucher_type: this.orderDocument.used_voucher_type,
        promo_disc_amt_incvat: this.orderDocument.promo_disc_amt_incvat,
        promo_disc_amt_excvat: this.orderDocument.promo_disc_amt_excvat,
      }
    },
    maskedCardInfo(text, index, replacement) {
      return (
        text.substring(0, index) +
        replacement +
        text.substring(index + replacement.length)
      )
    },
    // onSelectInstallment(event) {
    //   this.isInstallmentOption = true
    //   this.installment.selectedMonth = ''
    //   this.installment.agentCode = this.installment.installment_option
    //   this.$nextTick(() => {
    //     this.periods = this.periodsInstallment.filter(
    //       (a) =>
    //         a.bank_id === this.installment.installment_option && a.is_status
    //     )
    //     this.periods.forEach((a) => {
    //       this.period_months.push(a.month)
    //     })
    //   })
    // },
    onSelectInstallmentCart(data) {
      console.log('onSelectInstallmentCart', data)
      this.installment.selectedMonth = ''
      this.isShowInActive = false
      this.inActiveItems = []
      this.selectedPlan = {}
      this.installment.plan_id = ''
      this.installment.period = ''
      this.isInstallmentOptionCart = true
      this.bankInstallmentOptions = data?.installment_plan
      this.isMonthInstallmentEmpty = false
      console.log(
        'installment_option :>> ',
        this.installment.installment_option
      )
      this.installment.agentCode = this.installment.installment_option

      this.$nextTick(() => {
        this.periods = this.periodsInstallment.filter(
          (a) =>
            a.bank_id === this.installment.installment_option && a.is_status
        )
        this.periods.forEach((a) => {
          this.period_months.push(a.month)
        })
        // this.bank_cardName = this.installment_data.installment.find(
        //   (a) => a.bank_id === this.orderTemp.installment_option
        // )?.bank_name.en

        this.bank_cardName = data?.bank_name
        console.warn('cardName :>> ', data)
      })
    },
    async clearInstallment() {
      this.installment.selectedMonth = ''
      this.installment.cardNo = ''
      this.installment.cardName = ''
      this.installment.cardExpYear = ''
      this.installment.cardExpMonth = ''
      this.installment.cardCVV = ''
      this.installment.installment_option = ''
      this.installment.payment_bank_installment_id = ''

      this.bankInstallmentOptions = []
      this.isInstallmentOptionCart = false
      this.selectedPlan = {}
      this.isShowInActive = false
      this.inActiveItems = []
    },
    onSetInstallment(data) {
      this.selectedPlan = this.bankInstallmentOptions.find(
        (option) => option.plan_id === data
      )
      this.installment.payment_bank_installment_id = data
      this.installment.selectedMonth = this.selectedPlan?.period
      this.installment.period = this.selectedPlan?.period
      console.log('selectedPlan', this.installment)
    },
    async onShowInActive(data) {
      this.installment.agentCode = ''
      this.installment.installment_option = ''
      // this.inActiveItems = data.installment_plan
      this.isShowInActive = true
      this.warningMessage = data.warning_message
      this.inActiveItems = await this.mergeItemsInstallment(
        data.installment_plan
      )
      console.log(data)
    },
    mergeItemsInstallment(items) {
      // const allInactiveSkus = items.flatMap((item) =>
      //   item.inactive_skus.map((inactiveSku) => ({ ...inactiveSku }))
      // )
      // return allInactiveSkus

      // const uniqueInactiveSkus = []
      // const skuCodesSet = new Set()

      // items.forEach((item) => {
      //   item.inactive_skus.forEach((inactiveSku) => {
      //     if (!skuCodesSet.has(inactiveSku.sku_code)) {
      //       skuCodesSet.add(inactiveSku.sku_code)
      //       uniqueInactiveSkus.push({ ...inactiveSku })
      //     }
      //   })
      // })
      // return uniqueInactiveSkus

      const allInactiveSkus = items
        .flatMap((item) =>
          item.inactive_skus.map((inactiveSku) => ({ ...inactiveSku }))
        )
        .reduce((acc, curr) => {
          const existingSku = acc.find((sku) => sku.sku_code === curr.sku_code)
          if (existingSku) {
            Object.assign(existingSku, curr)
          } else {
            acc.push(curr)
          }
          return acc
        }, [])

      return allInactiveSkus
    },
  },
  head: {
    script: [
      {
        src: process.env.NUXT_ENV_2C2P_JS_LIBRARY_URL,
        type: 'text/javascript',
      },
    ],
  },
}
