
// import asideComponent from '@/components/template/aside-user.vue'
import OrderItems from '@/components/order/order-items.vue'
import ApproverDropdown from '@/components/checkout/approver-dropdown.vue'
import ListOrderProcess from '@/components/order/list-process.vue'
import SummaryComponent from '@/components/checkout/summary-component.vue'
import ApproveModal from '@/components/modal/confirmApproveOrder.vue'
// import ModalWarning from '@/components/modal/modalWarning.vue'
// import notfound from '@/components/share/notfound.vue'
import jwtDecode from 'jwt-decode'
import { mapGetters } from 'vuex'
import { exportOrderDocument } from '@/plugins/export-pdf.js'
import {
  exportExcelOrderDocument,
  exportCSVOrderDocument,
} from '@/plugins/export-excel.js'
// import { set } from 'js-cookie'

const Cookie = process.client ? require('js-cookie') : undefined
export default {
  layout: 'blank-nav',
  name: 'ApproveOrder',
  components: {
    // 'aside-component': asideComponent,
    'order-item': OrderItems,
    ApproverDropdown,
    ListOrderProcess,
    'summary-component': SummaryComponent,
    ApproveModal,
    // ModalWarning,
    notfound: async () => await import('@/components/share/notfound.vue'),
    OtpVerify: async () => await import('@/components/template/otp-verify.vue'),
    ModalWarning: async () =>
      await import('@/components/modal/modalWarning.vue'),
  },
  data() {
    return {
      modalTitle: '',
      showApproveModal: false,
      isDocument: true,
      isProcess: true,
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        voucher_no: '',
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
        is_use_coupon: false,
        used_voucher_type: '',
      },
      orderDocument: {},
      productGroups: [],
      approve: {
        remark: '',
        officemate: '',
        fileName: '',
        sentEmail: '',
      },
      budget: {
        id: '',
        period: {
          used_amt: 0,
          budget_amt: 0,
          remain_amt: 0,
          original_amt: 0,
        },
        sum_order_total_amt: 0,
        control_level: '',
        period_type: '',
        variant: 'primary',
      },
      items: [],
      approval: [],
      approverLevel: 1,
      activityLogs: [],
      isGR: false,
      warningMessages: [],
      modalWarning: false,
      approverAction: '',
      // approve otp
      isLoading: true,
      isApproveWaiting: false,
      isVerifyOTP: false,
      getOTPFromTemp: '',
      getOTPFrom: '',
      selectOTPFromTemp: '',
      selectOTPFrom: '',
      otpLength: 6,
      optNum: {},
      disabledOTP: true,
      dataForOTP: {
        otp: '',
        openId: '',
      },
      resRequestOTP: {
        create_on: '',
        expiry_date: '',
        number_request_limit: '',
        otp_id: '',
        ref_code: '',
        phone: '',
        email: '',
        verify_mode: '',
        continue_next_date: '',
      },
      countDownOTP: 0,
      countDownResendOTP: 0,
      textCountDownOTP: '00:00',
      nextDateToSendOTP: Date.now(),
      nowDateTrigger: Date.now(),
      setTimeoutOTP: '',
      setTimeoutReOTP: '',
      warningMessagesOTP: {
        th: '',
        en: '',
      },
      jwt: {},
      isNotFound: false,
      isNotFound403: false,
      isShowOTP: false,
      isOrderExpire: false,
      isTokenExpire: false,
      channels: [],
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    ...mapGetters('authen', ['openId']),
    isEprocuerment() {
      if (Object.keys(this.orderDocument).length)
        return this.orderDocument.account_channel === 'eprocurement'
      else return false
    },
    isUseBudget() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.account_channel === 'eprocurement'
      )
        return (
          this.orderDocument.approval_information
            .companies_budget_control_id !== null
        )
      else return false
    },
    isAdmin() {
      if (this.profileType === 'eprocurement') {
        if (this.userProfile.roles !== null && this.userProfile.roles.length) {
          return this.userProfile.roles.some(
            (q) => q.role_id === 1 || q.role_id === 2
          )
        } else return false
      } else return false
    },
    isAccountTypePersonal() {
      return (
        this.profileType === 'standard' && this.userProfile.type === 'Personal'
      )
    },
    hasApprovePermission() {
      if (!Object.keys(this.orderDocument).length) {
        return false
      }
      if (this.orderDocument.status !== 'Waiting') {
        return false
      } else if (this.orderDocument.account_channel === 'eprocurement') {
        if (
          this.orderDocument.approval_status === 'Waiting' &&
          this.orderDocument.approval_information !== null
        ) {
          let currentLevel =
            this.orderDocument.approval_information.current_level
          let approvalCurrentLevel =
            this.orderDocument.approval_information.approval.find(
              (q) => q.level === currentLevel
            )
          if (approvalCurrentLevel !== undefined) {
            console.log('approvalCurrentLevel', approvalCurrentLevel)
            return approvalCurrentLevel.approver.some(
              (q) =>
                q.user_open_id === this.jwt.openid &&
                q.approval_status !== 'Approve'
            )
          } else return false
        } else if (this.orderDocument.approval_status === 'WaitingAdmin') {
          return true
        } else return false
      } else {
        return this.orderDocument.approval_status === 'Waiting'
      }
      // if (
      //   Object.keys(this.orderDocument).length &&
      //   this.orderDocument.account_channel === 'eprocurement'
      // )
      //   if (
      //     this.orderDocument.approval_status === 'WaitingAdmin' &&
      //     this.isAdmin
      //   )
      //     return true
      //   else if (this.orderDocument.approval_status === 'Waiting') {
      //     if (this.orderDocument.approval_information !== null) {
      //       let currentLevel = this.orderDocument.approval_information
      //         .current_level
      //       let approvalCurrentLevel = this.orderDocument.approval_information.approval.find(
      //         (q) => q.level === currentLevel
      //       )
      //       if (approvalCurrentLevel !== undefined) {
      //         return approvalCurrentLevel.approver.some(
      //           (q) =>
      //             q.user_open_id === this.openId &&
      //             q.approval_status !== 'Approve'
      //         )
      //       } else return false
      //     } else return false
      //   } else return false
    },
    hasCancelPermission() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.status === 'Waiting'
      ) {
        return this.orderDocument.payment_status !== 'Complete'
      } else return false
    },
  },
  watch: {},
  beforeRouteLeave(to, from, next) {
    // remove otp verify when leave
    sessionStorage.removeItem('session_otp')
    next()
  },
  async mounted() {
    Cookie.remove('previousPage')
    await this.DecodeJWT()
    await this.getChannel()
    if (!this.isNotFound403) {
      if (
        !this.$utils.isValid(this.$route.params.id) ||
        !this.$utils.isValid(this.$route.query.verify_key)
      ) {
        this.isDocument = false
      } else if (
        Cookie.get('OTP_TMP_otp_prove_token_' + this.jwt.orderId) !== undefined
      ) {
        this.isVerifyOTP = true
        await this.getOrderDocument()
        this.isLoading = false

        this.isApproveWaiting = true
      } else {
        await this.CheckOrderStatus()
      }
    }
  },
  methods: {
    onApproverAction(action) {
      this.approverAction = action
      this.showApproveModal = true
    },
    displayApproveBadge(status, approval) {
      return status === approval.level_status
    },
    async CheckOrderStatus() {
      const apiData = {
        profile_id: this.userProfile.id,
        order_id: this.$route.params.id,
      }
      await this.$orderProvider
        .getVerifyOrderStatusFromEmail(this.$route.query.verify_key, apiData)
        .then(
          (result) => {
            console.log('resul', result, this.jwt.openid)
            if (result.status === 200) {
              if (result.data.is_user_valid && result.data.is_approve_valid) {
                this.viewOrderDetail(this.jwt.orderId)
              } else if (result.data.is_order_expire_valid) {
                this.isOrderExpire = true
                this.isVerifyOTP = true
              } else if (
                result.data.is_user_valid &&
                !result.data.is_approve_valid
              ) {
                this.isVerifyOTP = true
                this.isLoading = false
              } else if (
                !result.data.is_user_valid &&
                !result.data.is_approve_valid
              ) {
                this.isVerifyOTP = true
                this.isLoading = false
              } else if (
                !result.data.is_user_valid &&
                result.data.is_approve_valid
              ) {
                this.isApproveWaiting = true
                this.isShowOTP = true
                this.isLoading = false
              }
            } else if (result.status === 401) {
              if (
                result.data.code === 'C000' &&
                result.data.message.en === 'Token has expired'
              ) {
                console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
                this.isVerifyOTP = true
                this.isTokenExpire = true
                this.isOrderExpire = false
              } else {
                this.isLoading = false
                this.isNotFound = true
              }
            } else {
              this.isLoading = false
              this.isNotFound = true
            }
          },
          (error) => {
            console.log('error', error.responsed)
            this.isLoading = false
            this.isNotFound = true
          }
        )
        .catch((error) => {
          console.log('error', error.responsed)
          this.isLoading = false
          this.isNotFound = true
        })
    },
    viewOrderDetail(orderId) {
      console.log('viewOrderDetail', orderId)
      setTimeout(() => {
        this.$router.push({
          path: this.$i18n.path('account/order'),
          name:
            this.$i18n.locale === 'th'
              ? 'account-order-id'
              : 'lang-account-order-id',
          params: { id: orderId },
          query: { mode: 'view' },
        })
      }, 1000)
    },
    async getChannel() {
      let params = new URLSearchParams()
      params.append('user_open_id', this.jwt.openid)
      const response = await this.$authenProvider.getOTPByChannel(params)
      if (response.status === 200) {
        console.log('test', response.data)
        this.channels = response.data.channels
        console.log('channels', this.channels)
      } else if (response.status === 403) {
        this.isLoading = false
        this.isNotFound403 = true
      } else {
        this.isLoading = false
        this.isNotFound = true
      }
    },
    async DecodeJWT() {
      if (
        this.$route.query.verify_key !== '' &&
        this.$route.query.verify_key !== undefined &&
        this.$route.query.verify_key !== null
      ) {
        try {
          const decoded = jwtDecode(this.$route.query.verify_key)
          console.log('decoded', decoded)
          this.jwt = decoded
          // this.getOTPFromTemp =
          //   this.jwt.mail !== '' && this.jwt.mail !== null ? 'email' : 'sms'
          // this.selectOTPFromTemp =
          //   this.getOTPFromTemp !== '' && this.getOTPFromTemp === 'email'
          //     ? this.jwt.mail
          //     : this.jwt.mobile
        } catch (err) {
          this.isLoading = false
          this.isNotFound = true
        }
      }
    },
    async getOrderDocument() {
      const otp_prove_token = Cookie.get(
        'OTP_TMP_otp_prove_token_' + this.jwt.orderId
      )
      const otp_channel = Cookie.get('OTP_TMP_channel' + this.jwt.orderId)
      console.log('getOrderDocument()', otp_channel)
      await this.$orderProvider
        .getOrderDocumentFromEmailNew(
          this.$route.params.id,
          this.$route.query.verify_key,
          false,
          otp_prove_token ?? '',
          otp_channel ?? ''
        )
        .then(
          (result) => {
            console.log('getOrderDocument', result)
            if (result.status === 200) {
              this.orderDocument = result.data
              this.getOrderActivityLogs(otp_channel)
              this.summary = {
                net_amt: this.orderDocument.net_amt,
                net_deliveryfee_excvat:
                  this.orderDocument.net_deliveryfee_excvat,
                nonvat_prod_net_amt: this.orderDocument.nonvat_prod_net_amt,
                vat_amt: this.orderDocument.vat_amt,
                vat_prod_net_amt: this.orderDocument.vat_prod_net_amt,
                voucher_disc_amt_excvat:
                  this.orderDocument.voucher_disc_amt_excvat,
                voucher_no: this.orderDocument.voucher_no,
                other_disc_amt_incvat: this.orderDocument.other_disc_amt_incvat,
                other_disc_amt: this.orderDocument.other_disc_amt_excvat,
                payment_disc_amt_excvat:
                  this.orderDocument.payment_disc_amt_excvat,
                payment_disc_code: this.orderDocument.payment_disc_code,
                is_use_coupon: this.orderDocument.is_use_coupon,
                used_voucher_type: this.orderDocument.used_voucher_type,
              }
              if (this.isEprocuerment) {
                // this.budget = this.orderDocument.budget
                if (this.orderDocument.status === 'Waiting')
                  this.getOrderBudget(this.orderDocument)
                this.approval = this.orderDocument.approval_information.approval
                this.approverLevel =
                  this.orderDocument.approval_information.current_level
              }
              this.productGroups = []
              this.orderDocument.sub_orders.forEach((subOrder) => {
                this.productGroups.push({
                  soldBy:
                    subOrder.vendor_type === 'OFMStock' ||
                    subOrder.vendor_type.includes('DropShip')
                      ? 'OfficeMate'
                      : `${
                          this.$i18n.locale === 'th'
                            ? subOrder.vendor_name_th
                            : subOrder.vendor_name_en
                        }`,
                  FulfilledBy:
                    subOrder.vendor_type === 'OFMStock'
                      ? 'OfficeMate'
                      : `${
                          this.$i18n.locale === 'th'
                            ? subOrder.vendor_name_th
                            : subOrder.vendor_name_en
                        }`,
                  items: subOrder.items,
                  delivery_lead_time: this.$utils.formatDateExcludeTime(
                    subOrder.delivery_lead_time
                  ),
                  premiums: [],
                  // shipping
                  shippingOption: [
                    {
                      type: 'OFFICEMATE SHIPPING',
                      description:
                        'Delivery to your preferred address in 2-5 working days',
                      cost: 0,
                    },
                    {
                      type: 'BANGKOK SAMEDAY',
                      description:
                        'Delivery to your preferred address in 2-5 working days',
                      cost: 80,
                    },
                    {
                      type: 'EXPRESS DELEVERY',
                      description:
                        'Delivery to your preferred address in 2-5 working days',
                      cost: 200,
                    },
                  ],
                })
              })
              this.productGroups.map((group) => {
                group.premiums = group.items.filter((q) => q.is_free)
                group.items = group.items.filter((q) => !q.is_free)
                group.items.map((item) => {
                  item.display_price_exclude_vat = item.price_disc_excvat
                  item.product_name = {
                    th: item.product_name_th,
                    en: item.product_name_en,
                  }
                })
                if (group.premiums.length) {
                  group.premiums.map((item) => {
                    item.display_price_exclude_vat = item.price_disc_excvat
                    item.product_name = {
                      th: item.product_name_th,
                      en: item.product_name_en,
                    }
                  })
                }
              })
              this.isDocument = true
            } else {
              this.isLoading = false
              this.isNotFound = true
              this.isDocument = false
              this.isShowOTP = false
              this.isVerifyOTP = false
            }
          },
          (error) => {
            console.log('error แนกำ', error.response)
            this.isLoading = false
            this.isNotFound = error.response.status === 404
            this.isNotFound403 = error.response.status === 403
            this.isShowOTP = false
            this.isVerifyOTP = false
            Cookie.remove('OTP_TMP_otp_prove_token_' + this.jwt.orderId)
            Cookie.remove('OTP_TMP_channel' + this.jwt.orderId)
            Cookie.remove('OTP_TMP_verify_prove_key_uri')
            Cookie.remove('OTP_TMP_email')
            Cookie.remove('OTP_TMP_phone')
            Cookie.remove('OTP_TMP_Email')
            Cookie.remove('OTP_TMP_SMS')
            Cookie.remove('OTP_TMP_OFMApps')
            Cookie.remove('OTP_TMP_QRCode')
          }
        )
      // .catch((error) => {
      //   console.log('error then', error)
      //   this.isLoading = false
      //   this.isNotFound = true
      //   this.isShowOTP = false
      //   this.isVerifyOTP = false
      //   Cookie.remove('OTP_TMP_otp_prove_token_' + this.jwt.orderId)
      //   Cookie.remove('OTP_TMP_channel' + this.jwt.orderId)
      //   Cookie.remove('OTP_TMP_verify_prove_key_uri')
      //   Cookie.remove('OTP_TMP_email')
      //   Cookie.remove('OTP_TMP_phone')
      //   Cookie.remove('OTP_TMP_Email')
      //   Cookie.remove('OTP_TMP_SMS')
      //   Cookie.remove('OTP_TMP_OFMApps')
      //   Cookie.remove('OTP_TMP_QRCode')
      // })
    },
    async getOrderActivityLogs(otp_channel) {
      const otp_prove_token = Cookie.get(
        'OTP_TMP_otp_prove_token_' + this.jwt.orderId
      )
      await this.$orderProvider
        .getOrderActivityFromMailLogs(
          this.$route.params.id,
          this.$route.query.verify_key,
          otp_prove_token,
          otp_channel
        )
        .then((result) => {
          if (result.status === 200) {
            this.activityLogs = result.data
          } else this.activityLogs = []
        })
    },
    async getOrderBudget(order) {
      const otp_prove_token = Cookie.get(
        'OTP_TMP_otp_prove_token_' + this.jwt.orderId
      )
      if (order.approval_information.companies_budget_control_id) {
        const result = await this.$orderProvider.getOrderBudgetFromMail(
          order.approval_information.companies_budget_control_id,
          order.company_id,
          this.$route.query.verify_key,
          otp_prove_token
        )
        if (result.status === 200) {
          this.budget = result.data
        }
      }
    },
    onSelectApproverLevel(value) {
      this.approverLevel = value
      console.log(this.approverLevel)
    },
    onClickViewPOFile(url) {
      if (this.$utils.isValid(url)) {
        window.open(url, '_blank')
      }
    },
    onConfirmApprove(payload) {
      console.log(payload)
      this.approveOrder(payload.action, payload.remark)
    },
    async approveOrder(action, remark) {
      const otp_prove_token = Cookie.get(
        'OTP_TMP_otp_prove_token_' + this.jwt.orderId
      )
      const otp_channel = Cookie.get('OTP_TMP_channel' + this.jwt.orderId)
      let apiData = JSON.stringify({
        documents: [
          {
            order_object_id: this.orderDocument.id,
            order_id: this.orderDocument.order_id,
            action: action,
          },
        ],
        user_id: this.jwt.openid,
        remark: remark,
        verify_key: this.$route.query.verify_key,
        is_otp_proved: otp_channel !== 'QRCode' ? true : false,
      })
      console.log('approveOrder', apiData)
      const result = await this.$orderProvider.approveOrderFromMail(
        otp_prove_token,
        apiData
      )
      console.log('result', result)
      if (result.status === 200) {
        if (!result.data.warning_messages.length) {
          this.isProcess = false
          Cookie.remove('OTP_TMP_otp_prove_token_' + this.jwt.orderId)
          Cookie.remove('OTP_TMP_channel' + this.jwt.orderId)
          Cookie.remove('OTP_TMP_verify_prove_key_uri')
          Cookie.remove('OTP_TMP_email')
          Cookie.remove('OTP_TMP_phone')
          Cookie.remove('OTP_TMP_Email')
          Cookie.remove('OTP_TMP_SMS')
          Cookie.remove('OTP_TMP_OFMApps')
          Cookie.remove('OTP_TMP_QRCode')
        } else {
          this.warningMessages = result.data.warning_messages
          this.modalWarning = true
        }
      }
    },
    orderDocumentStatus() {
      switch (this.orderDocument.status) {
        case 'Deliverd':
          return 'round-icon-waiting'
        case 'Waiting':
          return 'round-icon-waiting'
        case 'Complete':
          return 'round-icon-primary'
        default:
          return 'round-icon-primary'
      }
    },
    async exportPDF(type, language) {
      const otp_prove_token = Cookie.get(
        'OTP_TMP_otp_prove_token_' + this.jwt.orderId
      )
      const otp_channel = Cookie.get('OTP_TMP_channel' + this.jwt.orderId)
      await this.$orderProvider
        .getOrderDocumentFromEmailNew(
          this.$route.params.id,
          this.$route.query.verify_key,
          type === 'purchase',
          otp_prove_token,
          otp_channel ?? ''
        )
        .then((result) => {
          if (result.status === 200) {
            if (
              result.data !== null &&
              result.data !== undefined &&
              Object.keys(result.data).length
            )
              exportOrderDocument(result.data, language, type)
          }
        })
    },
    async exportExcel() {
      exportExcelOrderDocument(this.orderDocument)
    },
    async exportCSV() {
      exportCSVOrderDocument(this.orderDocument)
    },
  },
}
