import { render, staticRenderFns } from "./_orderId.vue?vue&type=template&id=1781b03d"
import script from "./_orderId.vue?vue&type=script&lang=js"
export * from "./_orderId.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ApproverDropdown: require('/usr/src/nuxt-app/components/checkout/approver-dropdown.vue').default,DeliveryItems: require('/usr/src/nuxt-app/components/checkout/delivery-items.vue').default,SummaryComponent: require('/usr/src/nuxt-app/components/checkout/summary-component.vue').default})
