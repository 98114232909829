
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'CatalogItems',
  props: {
    catalog: {
      type: Object,
      default: () => {},
    },
    catalogid: {
      type: String,
      default: '',
    },
    userid: {
      type: String,
      default: '',
    },
    profiletype: {
      type: String,
      default: '',
    },
    openid: {
      type: String,
      default: '',
    },
    noitems: {
      type: Array,
      default: Array,
    },
    isclear: {
      type: Boolean,
      default: false,
    },
    temp_catalog: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    console.log('cart-item : ', this.noitems)
    return {
      input: {
        sku_code: '',
        quantity: 1,
      },
      items: [],
      isEditName: false,
      editName: {
        name: '',
      },
      catId: '',
      products: [],
      skus: [],
      is_success: true,
      warningMessages: [],
      showSuccess: false,
      productMessages: '',
      isEmpty: false,
      imageTemp: '',
      sku_code: '',
    }
  },
  computed: {
    ...mapState('cart', ['cartId']),
    ...mapGetters('user', ['userProfile', 'profileType']),
    isSelectedAll() {
      let checkItem = this.catalog.items.filter((q) => q.isChecked)
      console.log('checkItem', checkItem)
      return checkItem.length === this.catalog.items.length ? true : false
    },
  },
  watch: {
    catalog: function () {
      this.isEditName = false
    },
    isEditName: {
      handler: function (val) {
        this.editName.name = val ? this.catalog.catName : ''
      },
      deep: true,
    },
    isclear: {
      handler: function (val) {
        if (val) {
          this.input.sku_code = ''
          this.warningMessages = []
          this.isEmpty = val
          this.showSuccess = false
        }
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('cart', ['addCart', 'setCartCount']),
    onSelectAll(event) {
      console.log('event', event)
      this.catalog.items.forEach((item) => {
        item.isChecked = event
      })
    },
    classDisableMaterial(obj) {
      let _class = ''
      let _value = obj.type === 'database' ? obj.valueId : obj.valueName
      if (this.selectedMaterial[obj.id] === _value) _class += ' active'
      if (this.disableMaterial(obj)) _class += ' btn-line-disable'

      return _class
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    async onMoveToCart(openid, userid, profiletype) {
      this.skus = []
      console.log('object', this.catalog.items)
      if (this.catalog.items !== undefined) {
        this.catalog.items.forEach((e) => {
          if (e.qty > 0 && e.isChecked) {
            console.log(e)
            e.isChecked = false
            this.skus.push({
              sku: e.sku_code,
              qty: e.qty,
            })
          }
        })
        console.log('cart', this.skus)
        let apiData = JSON.stringify({
          user_open_id: openid,
          profile_id: userid,
          account_channel: profiletype,
          skus: this.skus,
        })
        const result = await this.$shoppingCartProvider.addCart(apiData)
        if (result.status === 201) {
          // gtm
          if (result.data.success_items.length) {
            this.$bus.$emit('addToCart')
            this.$gtm.push({ ecommerce: null })
            this.$gtm.push({
              event: 'addToCart',
              ecommerce: {
                add: {
                  products: result.data.success_items.length
                    ? result.data.success_items.map((item) => {
                        let catLv1 = ''
                        let catLv2 = ''
                        let catLv3 = ''
                        let catIdLv1 = ''
                        let catIdLv2 = ''
                        let catIdLv3 = ''
                        if (this.$utils.isObjectKeyValid(item, 'categories')) {
                          if (item.categories.find((key) => key.level === 1)) {
                            catLv1 = item.categories.find(
                              (key) => key.level === 1
                            ).display_name['en']
                            catIdLv1 = item.categories.find(
                              (key) => key.level === 1
                            ).id
                          }
                          if (item.categories.find((key) => key.level === 2)) {
                            catLv2 = item.categories.find(
                              (key) => key.level === 2
                            ).display_name['en']
                            catIdLv2 = item.categories.find(
                              (key) => key.level === 2
                            ).id
                          }
                          if (item.categories.find((key) => key.level === 3)) {
                            catLv3 = item.categories.find(
                              (key) => key.level === 3
                            ).display_name['en']
                            catIdLv3 = item.categories.find(
                              (key) => key.level === 3
                            ).id
                          }
                        }
                        return {
                          id: item.sku_code,
                          price: this.$utils.formatPrice(
                            item.price_include_vat,
                            2,
                            true
                          ),
                          categoryLv1: catLv1,
                          categoryLv2: catLv2,
                          categoryLv3: catLv3,
                          categoryIdLv1: catIdLv1,
                          categoryIdLv2: catIdLv2,
                          categoryIdLv3: catIdLv3,
                          quantity: item.qty,
                        }
                      })
                    : [],
                },
              },
            })

            // gtm GA4
            this.$gtm.push({ ecommerce: null })
            this.$gtm.push({
              event: 'add_to_cart',
              ecommerce: {
                user: this.$store.getters['user/userInfoTrack'],
                currency: 'THB',
                item_list_id: '',
                item_list_name: '',
                items: result.data.success_items.map((item) => {
                  const sku = this.catalog.items.find(
                    (el) => el.sku_code === item.sku_code
                  )
                  return {
                    item_id: sku.sku_code,
                    item_name: sku.product_names[1]?.display_name.th,
                    item_brand: sku.brand?.display_name.en,
                    ...this.$utils.productCategories(sku.categories),
                    quantity: sku.qty,
                    price: sku.price_include_vat,
                    discount:
                      sku.retail_price.include_vat - sku.price_include_vat,
                    ...this.$store.getters['user/userPromotionTrack'],
                    item_list_id:
                      (sku.is_flash_sale && 'flash_sale') ||
                      (sku.is_promotion && 'promotions') ||
                      '',
                    item_list_name:
                      (sku.is_flash_sale && 'flash sale') ||
                      (sku.is_promotion && 'Promotions') ||
                      '',
                  }
                }),
              },
            })

            this.catalog = JSON.parse(JSON.stringify(this.temp_catalog))
          }
          if (
            result.data.warning_messages.length &&
            result.data.warning_messages.filter(
              (item) => item.key === 'is_sku_limit'
            ).length
          ) {
            const sku_limit = result.data.warning_messages.filter(
              (item) => item.key === 'is_sku_limit'
            )
            this.$bus.$emit('addToCart', {
              message: {
                title: sku_limit[0].message_title,
                description: sku_limit[0].message_description,
              },
              isWarning: true,
            })
          }
          const cartResponsed = await this.$shoppingCartProvider.getCartCount(
            this.cartId,
            userid
          )
          console.log('object', cartResponsed)
          if (cartResponsed.status === 200)
            this.setCartCount(cartResponsed.data)
        }
      }
    },
    async onRemoveItem(catalogid, productId) {
      this.removeText()
      console.log(productId)
      await this.$productProvider
        .deleteProductCatalogById(catalogid, productId)
        .then((result) => {
          if (result.status === 200) {
            this.$emit('onProductCatalogs', catalogid)
          }
        })
        .catch((error) => {
          console.log('onRemoveItem', error.responsed)
        })
    },
    async onChangeCatalog(catalogid) {
      await this.$productProvider
        .updateCatalogById(catalogid, this.editName)
        .then((result) => {
          if (result.status === 204) {
            this.$emit('onCatalogs')
            this.isEditName = false
          }
        })
        .catch((error) => {
          console.log('onChangeCatalog', error.responsed)
        })
    },
    async validateAddCatalog() {
      this.$refs['input-sku'].validate().then(async (result) => {
        if (result.valid) {
          this.sku_code = this.input.sku_code
          this.products = []
          this.products.push(this.input)
          await this.checkProductCatalogs()
        } else {
          this.isEmpty = false
          this.$emit('onchangecat', false)
        }
      })
    },
    async createProductCatalogs() {
      await this.$productProvider
        .createProductCatalogsData(this.catalogid, this.products)
        .then((result) => {
          if (result.status === 201) {
            let data = result.data
            console.log('object', data)
            if (data.warning_messages.length > 0) {
              this.warningMessages = data.warning_messages
              this.showSuccess = false
            } else {
              this.showSuccess = true
              this.warningMessages = []
              this.removeText()
              this.$emit('onProductCatalogs', this.catalogid)
              this.$emit('onchangecat', false)
            }
          }
        })
        .catch((error) => {
          console.log('createProductCatalogs', error.responsed)
        })
    },
    async checkProductCatalogs() {
      if (this.catalog.allitems !== undefined) {
        if (
          this.catalog.allitems.filter(
            (a) => a.sku_code === this.input.sku_code
          ).length > 0
        ) {
          this.removeText()
          this.warningMessages = []
          this.is_success = false
          this.warningMessages.push(
            'มีรหัสสินค้า ' + this.sku_code + ' อยู่แล้วค่ะ'
          )
          this.$emit('onProductCatalogs', this.catalogid)
          this.$emit('onchangecat', false)
        } else {
          this.is_success = true
        }
      }
      if (this.is_success || this.catalog.allitems === undefined) {
        this.warningMessages = []
        this.createProductCatalogs()
      }
    },
    async updateProductCatalogs(catalog_id) {
      this.$emit('onchangecat', false)
      this.removeText()
      this.products = []
      this.catalog.items.forEach((element) => {
        this.products.push({
          sku_code: element.sku_code,
          quantity: parseInt(element.qty),
        })
      })
      await this.$productProvider
        .updateProductCatalogsData(catalog_id, this.products)
        .then((result) => {
          if (result.status === 204) {
            this.$emit('onProductCatalogs', catalog_id)
            this.showSuccess = true
          }
        })
        .catch((error) => {
          console.log('updateProductCatalogs', error.responsed)
        })
    },
    uploadCSV() {
      this.$refs['upload-csv'].click()
      this.removeText()
      this.$emit('onchangecat', false)
    },
    async selected({ target: { files } }) {
      console.log('catalogid', this.catalogid)
      console.log('files', files)
      if (files.length) {
        await this.$refs['input-file']
          .validate(files[0])
          .then(async (result) => {
            console.log('result', result)
            if (result.errors !== undefined && result.errors.length > 0) {
              this.warningMessages = []
              this.is_success = false
              this.warningMessages.push(
                'นามสกุลไฟล์ไม่ถูกต้อง [ ' + files[0]?.name + ' ] ค่ะ'
              )
            }
            if (result.valid) {
              console.log('valid')
              console.log('file', files[0])
              this.importFile(files[0])
            } else console.log('not valid')
          })
      }
    },
    async importFile(file) {
      this.products = []
      let fileName = file.name.split('.')
      console.log('fileName[1]', fileName[1])
      if (fileName[1] === 'csv') {
        await this.$utils.importCSV(file).then(
          async (result) => {
            // console.log('result ', result)
            if (result.length) {
              result.forEach((item) => {
                if (this.$utils.isValid(item['product code'])) {
                  let quantity = parseInt(item.quantity)
                  this.products.push({
                    quantity: !isNaN(quantity) ? quantity : 1,
                    sku_code: item['product code'],
                  })
                }
              })
              this.checkProductCatalogs()
            }
          },
          (error) => {
            console.log('error', error)
          }
        )
      } else if (fileName[1] === 'xlsx' || fileName[1] === 'xls') {
        const result = await this.$utils.importExcel(file)
        // console.log('import result', result)
        result.body.forEach((item) => {
          // console.log('item', item)
          this.products.push({
            quantity: item.quantity,
            sku_code: item.productcode,
          })
        })
        this.checkProductCatalogs()
        // console.log('skuList', skuList)
      }
      this.$refs['upload-csv'].value = null
    },
    async removeText() {
      this.input.sku_code = ''
      this.isEmpty = true
      if (
        this.warningMessages !== undefined &&
        this.warningMessages.length > 0
      ) {
        this.warningMessages = []
      }
      if (this.showSuccess) {
        this.showSuccess = false
      }
    },
    imageNormalShowcase(sku) {
      if (sku.images !== undefined && sku.images.length > 0) {
        return (
          sku.images.some((q) => q.size === 'large')
            ? sku.images.find((q) => q.size === 'large').url
            : this.imageTemp
        ).replace('/large', '/original')
      } else {
        return this.imageTemp.replace('/large', '/original')
      }
    },
    // onRoute(product) {
    //   // let path = product.product_names.find((a) => a.type === 'Online')
    //   //   .display_name[this.$i18n.locale]
    //   // let productName = product.product_names.find((q) => q.type.toLowerCase() === 'online')
    //   // let path = productName.display_name
    //   let skuSEOName = product.meta.seo_name[this.$i18n.locale]
    //   this.$router.push({
    //     path: this.$i18n.path(`product/${product.meta.seo_name[this.$i18n.locale]}-p.${product.sku_code}`),
    //     query: { queryIdEventAlgolia: '' },
    //   })
    // },
    stockAvailable(product) {
      return product.is_available_stock
        ? product.onhand -
            (product.reserve -
              (this.isReserveStock ? product.reserve_quantity : 0))
        : 9999
    },
    downloadExampleFile() {
      let element = document.createElement('a')
      element.setAttribute(
        'href',
        'https://ofm-cdn0.ofm.co.th/documents/example-products.csv'
      )
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
  },
}
