
import asideComponent from '@/components/template/aside-user.vue'
import OrderItems from '@/components/order/order-items.vue'
import ApproverList from '@/components/checkout/approver-list-gr.vue'
import ListOrderProcess from '@/components/order/list-process.vue'
import SummaryComponent from '@/components/checkout/summary-component.vue'
import ApproveModal from '@/components/modal/confirmApproveOrder.vue'
// import GoodsRecieveModal from '@/components/modal/confirmGoodsRecieve.vue'
import ModalWarning from '@/components/modal/modalWarning.vue'

import { mapGetters, mapActions } from 'vuex'
// import { exportOrderDocument } from '@/plugins/export-pdf.js'
// import {
//   exportExcelOrderDocument,
//   exportCSVOrderDocument,
// } from '@/plugins/export-excel.js'
export default {
  layout: 'main',
  name: 'OrderDetail',
  components: {
    'aside-component': asideComponent,
    'order-item': OrderItems,
    ApproverList,
    ListOrderProcess,
    'summary-component': SummaryComponent,
    ApproveModal,
    ModalWarning,
    // GoodsRecieveModal,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'other',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      modalTitle: '',
      isApproved: true,
      isRejected: false,
      isWaiting: false,
      showApproveModal: false,
      // showGoodsRecieveModal: false,
      goodsReceiveEvent: {
        action: '',
        subOrderId: '',
      },
      input: {
        sku: '',
      },
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        voucher_no: '',
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
        is_use_coupon: false,
        used_voucher_type: '',
      },
      orderDocument: {},
      orderTemp: {},
      productGroups: [],
      approve: {
        remark: '',
        officemate: '',
        fileName: '',
        sentEmail: '',
      },
      budget: {
        id: '',
        period: {
          used_amt: 0,
          budget_amt: 0,
          remain_amt: 0,
          original_amt: 0,
        },
        sum_order_total_amt: 0,
        control_level: '',
        period_type: '',
        variant: 'primary',
      },
      items: [],
      approval: [],
      approverLevel: 1,
      currentLevel: 1,
      approverAction: '',
      activityLogs: [],
      warningMessages: [],
      warningMessagesInfo: [],
      modalWarning: false,
      removeItemFlag: false,
      isEditPONo: false,
      editPONo: '',
      isEditPOFile: false,
      uploadingPOFlag: false,
      isUploadPOFail: false,
      isUpdatePONoFail: false,
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType', 'userInfo']),
    ...mapGetters('authen', ['openId']),
    isEprocuerment() {
      if (Object.keys(this.orderDocument).length)
        return this.orderDocument.account_channel === 'eprocurement'
          ? true
          : false
      else return false
    },
    isAdmin() {
      if (this.profileType === 'eprocurement') {
        if (this.userProfile.roles !== null && this.userProfile.roles.length) {
          return this.userProfile.roles.some(
            (q) => q.role_id === 1 || q.role_id === 2
          )
        } else return false
      } else return false
    },
    isOrderStatus() {
      return this.orderDocument.goods_received_status === 'Waiting'
        ? true
        : false
    },
    mode() {
      return this.$route.query !== null &&
        this.$utils.isValid(this.$route.query.mode)
        ? this.$route.query.mode
        : 'view'
    },
    isReserveStock() {
      if (Object.keys(this.orderDocument).length) {
        return this.orderDocument.reserve_status === 'Active' ? true : false
      } else return false
    },
  },
  // watch: {
  //   async mounted() {
  //     //if (this.$utils.isValid(this.$route.params.id)) {
  //     await this.getOrderDocument()
  //     //} else this.$router.push({ path: this.$i18n.path('') })
  //   },
  // },
  watch: {},
  async mounted() {
    if (this.$utils.isValid(this.$route.params.id)) {
      await this.getOrderDocument()
    } else this.$router.push({ path: this.$i18n.path('') })
  },
  methods: {
    ...mapActions('cart', ['setCartCount']),
    getFile() {
      this.approve.fileName = ''
      document.getElementById('file').value = ''
      document.getElementById('file').click()
    },
    onApproverAction(action) {
      if (action === 'None') {
        // this.approveOrder(action, '')
      } else {
        this.approverAction = action
        this.showApproveModal = true
      }
    },
    // displayApproveBadge(status, approval) {
    //   return status === approval.level_status ? true : false
    // },
    async getOrderDocument() {
      let params = new URLSearchParams()
      params.append('is_role', true)
      await this.$orderProvider
        .getOrderDocumentGRFromEmail(this.$route.params.id, params)
        .then((result) => {
          if (result.status === 200) {
            this.orderDocument = result.data
            this.getOrderActivityGRLogs()
            if (this.mode === 'view') {
              this.summary = {
                net_amt: this.orderDocument.net_amt,
                net_deliveryfee_excvat:
                  this.orderDocument.net_deliveryfee_excvat,
                nonvat_prod_net_amt: this.orderDocument.nonvat_prod_net_amt,
                vat_amt: this.orderDocument.vat_amt,
                vat_prod_net_amt: this.orderDocument.vat_prod_net_amt,
                voucher_disc_amt_excvat:
                  this.orderDocument.voucher_disc_amt_excvat,
                voucher_no: this.orderDocument.voucher_no,
                payment_disc_amt_excvat:
                  this.orderDocument.payment_disc_amt_excvat,
                payment_disc_code: this.orderDocument.payment_disc_code,
                is_use_coupon: this.orderDocument.is_use_coupon,
                used_voucher_type: this.orderDocument.used_voucher_type,
              }
            }
            // if (this.isEprocuerment) {
            //   // this.budget = this.orderDocument.budget
            //   if (this.orderDocument.status === 'Waiting' && this.isUseBudget)
            //     this.getOrderBudget(this.orderDocument)
            //   this.approval = this.orderDocument.approval_information.approval
            //   this.approverLevel = this.orderDocument.approval_information.current_level
            //   this.currentLevel = this.orderDocument.approval_information.current_level
            // }

            if (this.mode === 'view') {
              this.productGroups = []
              this.productGroups.push({
                items: this.orderDocument.items,
              })
              this.productGroups.map((group) => {
                group.premiums = group.items.filter((q) => q.is_free)
                group.items = group.items.filter((q) => !q.is_free)
                group.items.map((item) => {
                  item.display_price_exclude_vat = item.price_disc_excvat
                  item.product_name = {
                    th: item.product_name_th,
                    en: item.product_name_en,
                  }
                })
                if (group.premiums.length) {
                  group.premiums.map((item) => {
                    item.display_price_exclude_vat = item.price_disc_excvat
                    item.product_name = {
                      th: item.product_name_th,
                      en: item.product_name_en,
                    }
                  })
                }
              })
            }
          }
        })
    },
    async getOrderActivityGRLogs() {
      await this.$orderProvider
        .getOrderActivityGRLogs(this.$route.params.id)
        .then((result) => {
          if (result.status === 200) {
            this.activityLogs = result.data
          } else this.activityLogs = []
        })
    },
    async approveOrder(action, remark) {
      let params = {
        verify_key: this.$route.query.verify_key,
        user_open_id: this.openId,
        remark: remark,
        goods_received_id: this.orderDocument.id,
        is_admin: true,
      }
      const result = await this.$orderProvider.updateGoodsReceivedApproveOrder(
        params
      )
      console.log(result)
      if (result.status === 204) {
        this.getOrderDocument()
        this.getOrderActivityLogs()
      }
    },
    // onSelectApproverLevel(value) {
    //   this.approverLevel = value
    //   console.log(this.approverLevel)
    // },
    onClickViewPOFile(url) {
      if (this.$utils.isValid(url)) {
        window.open(url, '_blank')
      }
    },
    onConfirmApprove(payload) {
      console.log(payload)
      this.approveOrder(payload.action, payload.remark)
    },
    orderDocumentStatus() {
      switch (this.orderDocument.goods_received_status) {
        case 'Deliverd':
          return 'round-icon-waiting'
        case 'Waiting':
          return 'round-icon-waiting'
        case 'Complete':
          return 'round-icon-primary'
        default:
          return 'round-icon-primary'
      }
    },
  },
}
