
import InputQty from '@/components/share/input-qty.vue'
import EmptyItems from '@/components/cart/cart-items-empty.vue'
import countdown from '@/components/flashsale/countdown.vue'

import { mapGetters } from 'vuex'
export default {
  name: 'CartItems',
  components: {
    'input-qty': InputQty,
    'empty-items': EmptyItems,
    countdown,
    TooltipInstallmentDetail: async () =>
      await import('@/components/installment/tooltip-detail.vue'),
  },
  props: {
    warningMessages: {
      type: Array,
      default: Array,
    },
    addProductWarningMessages: {
      type: Array,
      default: Array,
    },
    productGroups: {
      type: Array,
      default: Array,
    },
    promotion_discounts: {
      type: Array,
      default: Array,
    },
    items: {
      type: Array,
      default: Array,
    },
    noItems: {
      type: Array,
      default: () => {
        return [{}]
      },
    },
    favoriteList: {
      type: Array,
      default: Array,
    },
    openCat: { type: Boolean, default: false },
    onCheckout: { type: Boolean, default: false },
    showSuccess: { type: Boolean, default: false },
    end: { type: String, default: '' },
    currentDate: { type: String, default: '' },
    isResetName: { type: Boolean, default: false },
  },
  data() {
    return {
      groups: [],
      selectedShipping: {
        option: [],
      },
      selectedItems: [],
      checkoutItem: [],
      is_selected_all: false,
      // Catalog
      createFavorite: {
        name: '',
      },
      isCreateFavorite: false,
      selectedFavorite: '',
      mergedOrders: [],
      selectInstallmentTooltip: '',
    }
  },
  computed: {
    ...mapGetters('authen', ['isAuthenticated']),
    isAuthen() {
      return this.isAuthenticated !== null && this.isAuthenticated !== undefined
        ? this.isAuthenticated
        : false
    },
    isSelectedAll() {
      if (this.items.length) {
        return this.items.filter((q) => q.is_selected).length ===
          this.items.length
          ? true
          : false
      } else return false
    },
    unSuccessItemText() {
      let msg = this.$t('cart.cartItem.labelItemUnsuccess')
      return msg.replace('{{ noItems }}', this.noItems.length.toString())
    },
    isFlashSale() {
      return this.$store.state.user.profileType !== 'eprocurement'
    },
  },
  watch: {
    productGroups: {
      handler: function () {
        this.groups = this.productGroups
        // this.groups = this.Object.assign([], this.productGroups)
        console.log('groups', this.groups)
        console.log('promotion_discounts', this.promotion_discounts)
      },
      deep: false,
    },
    isResetName: {
      handler: function (val) {
        if (val) {
          this.createFavorite.name = ''
          this.isCreateFavorite = false
        }
      },
      deep: true,
    },
    onCheckout: function (val) {
      if (val) {
        let combined = []
        this.groups.forEach((g) => {
          combined.push(
            ...g.items.filter(({ productId }) =>
              new Set(this.selectedItems.map(({ productId }) => productId)).has(
                productId
              )
            )
          )
        })
        this.$emit('checkOutItem', combined)
      }
    },
  },
  methods: {
    warningMinimumQty(minimum_quantity) {
      let msg = this.$t('cart.cartItem.warningMinimumQty')
      return msg.replace(
        '{product.minimum_quantity}',
        minimum_quantity.toString()
      )
    },
    warningMaximumQty(maximum_quantity) {
      let msg = this.$t('cart.cartItem.warningMaximumQty')
      return msg.replace(
        '{product.maximum_quantity}',
        maximum_quantity.toString()
      )
    },
    warningStockItemTex(product) {
      let msg = this.$t('cart.cartItem.warningStock')
      return msg.replace('{items}', product.onhand - product.reserve)
    },
    hasItemInFavoriteList(sku_code) {
      if (this.favoriteList.length) {
        let isMatch = false
        this.favoriteList.forEach((favorite) => {
          if (favorite.items.length) {
            if (
              favorite.items.find((q) => q.sku_code === sku_code) !== undefined
            )
              isMatch = true
          }
        })
        return isMatch
      } else return false
    },
    hasItemInFavorite(favorite, sku_code) {
      if (favorite.items.length) {
        return favorite.items.find((q) => q.sku_code === sku_code) !== undefined
          ? true
          : false
      } else return false
    },
    onClickFavorite() {
      this.selectedFavorite = ''
      this.isCreateFavorite = false
      this.createFavorite.name = ''
    },
    onAddFavorite(favorite, sku_code, quantity) {
      let id = favorite.id
      // let qty = 1
      // quantity = favorite.items.some((q) => q.sku_code) ? 0 : quantity
      this.$emit('addToFavorite', { id, sku_code, quantity: 1 })
    },
    onNewFavorite() {
      this.$emit('createNewFavorite', this.createFavorite.name)
    },
    displayProductImage(productImageList) {
      let img = productImageList.images
      let isImgErr = false

      if (img.length) {
        const imgCheck = new Image()
        imgCheck.onerror = function () {
          isImgErr = true
        }
        imgCheck.src = img.find((q) => q.size === 'thumbnail').url
      }

      let errImg =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      let timeStamp = this.$utils.isObjectKeyValid(
        productImageList.update_info,
        'timestamp'
      )
        ? /*? '?v=' +
          this.$utils.getDateString(productImageList.update_info.timestamp)
        : ''*/
          ''
        : ''

      if (img.length && !isImgErr) {
        return img.some((q) => q.size === 'thumbnail')
          ? img.find((q) => q.size === 'thumbnail').url + timeStamp
          : errImg
      } else {
        return errImg
      }
    },
    onRemoveItem(payload) {
      this.$emit('removeItem', payload)
    },
    scrollToRef(refName) {
      let top = this.$refs[refName].offsetTop + 220
      window.scroll({
        behavior: 'smooth',
        top: top,
      })
    },
    onSelectAll(event) {
      this.$emit(
        'changeSelectedCheckout',
        event,
        this.items.map((q) => q.sku_code)
      )
    },
    async onSelectSoldBy(event, group) {
      let skuList = group.items.map((q) => q.sku_code)
      this.$emit('changeSelectedCheckout', event, skuList)
    },
    onSelectItems(event, sku_code) {
      let skuList = [sku_code]
      this.$emit('changeSelectedCheckout', event, skuList)
    },
    imgError($event) {
      $event.target.src =
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg'
      $event.target.srcset = this.$utils.genImgSrcSet(
        'https://ofm-cdn0.ofm.co.th/images/image-product-default-thumbnail.jpg',
        [
          { w: 115, h: 115, q: 90, ws: '115w' },
          { w: 150, h: 150, q: 90, ws: '150w' },
        ]
      )
    },
    calculatePromotionQuantityDiscount(
      originalPrice,
      discountPrice,
      campaignType,
      discountType,
      discountAmt
    ) {
      return campaignType === 'FixedPrice'
        ? `${this.$t('cart.cartItem.labelSave')} ${Math.round(
            ((originalPrice - discountPrice) / originalPrice) * 100
          )}%`
        : discountType === 'Baht'
        ? `${this.$t('cart.cartItem.lableQuantityDiscount')} ฿ ${discountAmt}`
        : `${this.$t('cart.cartItem.lableQuantityDiscount')} ${discountAmt}%`
    },
    highlightActiveTier(activeId, pro) {
      return pro.id === activeId ? true : false
    },
    // onRoute(product) {
    //   this.$nextTick(() => {
    //     this.$router.push({
    //       path: this.$i18n.path(
    //         `product/${product.meta.seo_name[this.$store.state.locale]}-p.${
    //           product.sku_code
    //         }`
    //       ),
    //       query: { queryIdEventAlgolia: '' },
    //     })
    //   })
    // },
    // onFlashsaleTimeout(data) {
    // console.log('onFlashsaleTimeout', data)
    // },
    stockAvailable(product) {
      let available = product.is_available_stock
        ? product.onhand - product.reserve
        : 9999
      console.log('available', available)
      return available
    },
    // add code from b2s
    mergeNewGroup(data) {
      console.log('mergeNewGroup', data)
      console.log('promotion_discounts', this.promotion_discounts)
      const items = data
      const promotion_discounts = this.promotion_discounts

      const withoutAddOn = items.filter(
        (item) =>
          !promotion_discounts.some((addon) =>
            addon.items.some(({ sku_code }) => sku_code === item.sku_code)
          )
      )

      const result = promotion_discounts.map((addon) => {
        const matchedItems = items.filter((item) =>
          addon.items.some(({ sku_code }) => sku_code === item.sku_code)
        )
        return {
          promotion_discounts: addon,
          items: matchedItems,
        }
      })

      const mergedResult = [
        ...result.filter((item) => item.items.length > 0),
        { promotion_discounts: '', items: withoutAddOn },
      ]

      const mergedOrders = mergedResult.sort((a, b) => {
        const promotionA = a.promotion_discounts.id || ''
        const promotionB = b.promotion_discounts.id || ''
        return promotionA.localeCompare(promotionB)
      })
      console.log('mergedOrders', mergedOrders)
      return mergedOrders
    },
    onCloseDropdown() {
      // this.selectInstallmentTooltip = ''
    },
  },
}
