
import DeliveryItems from '@/components/checkout/delivery-items.vue'
import ApproverDropdown from '@/components/checkout/approver-dropdown.vue'
import SummaryComponent from '@/components/checkout/summary-component.vue'
import { mapGetters, mapActions } from 'vuex'
import { exportOrderDocument } from '@/plugins/export-pdf.js'
import { exportExcelOrderDocument } from '@/plugins/export-excel.js'

export default {
  layout: 'checkout-nav',
  name: 'CheckoutComplete',
  components: {
    'delivery-items': DeliveryItems,
    // 'input-text': InputText,
    ApproverDropdown,
    'summary-component': SummaryComponent,
  },
  middleware(ctx) {
    ctx.$gtm.push({
      event: 'pageInfo',
      page: {
        type: 'purchase',
        path: ctx.route.path,
        title: ctx.app.head.title,
      },
      session: {
        isLoggedIn: ctx.store.getters['authen/isAuthenticated'] ? 'Yes' : 'No',
      },
      user: ctx.store.getters['user/userInfoTrack'],
    })
  },
  data() {
    return {
      isApproved: true,
      isRejected: false,
      isWaiting: false,
      summary: {
        net_amt: 0,
        net_deliveryfee_excvat: 0,
        nonvat_prod_net_amt: 0,
        vat_amt: 0,
        vat_prod_net_amt: 0,
        voucher_disc_amt_excvat: 0,
        voucher_no: '',
        payment_disc_amt_excvat: 0,
        payment_disc_code: '',
        is_use_coupon: false,
        used_voucher_type: '',
      },
      orderDocument: {},
      productGroups: [],
      switchLayout: false,
      approve: {
        remark: '',
        officemate: '',
        fileName: '',
        sentEmail: '',
      },
      budget: {
        id: '',
        used_amt: 0,
        budget_amt: 0,
        remain_amt: 0,
        variant: 'primary',
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
        },
      },
      totalExceptVat: 0,
      totalExcludedVat: 0,
      vat: 0,
      items: [],
      approval: [],
      approverLevel: 1,
      sameInvoice: false,
      contactBack: false,
      sameApprover: 'confirm',
      activityLogs: [],
      itemsInsider: [],
      insiderPageType: 'Other',
    }
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'profileType']),
    ...mapGetters('authen', ['openId']),
    ...mapGetters('order', ['currentTransactionId']),
    isEprocuerment() {
      if (Object.keys(this.orderDocument).length)
        return this.orderDocument.account_channel === 'eprocurement'
          ? true
          : false
      else return false
    },
    isAdmin() {
      if (this.profileType === 'eprocurement') {
        if (this.userProfile.roles !== null && this.userProfile.roles.length) {
          return this.userProfile.roles.some(
            (q) => q.role_id === 1 || q.role_id === 2
          )
        } else return false
      } else return false
    },
    isUseBudget() {
      if (
        Object.keys(this.orderDocument).length &&
        this.orderDocument.account_channel === 'eprocurement'
      )
        return this.orderDocument.approval_information
          .companies_budget_control_id !== null
          ? true
          : false
      else return false
    },
    isAccountTypePersonal() {
      return this.profileType === 'standard' &&
        this.userProfile.type === 'Personal'
        ? true
        : false
    },
  },
  async mounted() {
    if (this.$utils.isValid(this.$route.params.orderId)) {
      await this.getOrderDocument()
    }

    // else this.$router.push({ path: this.$i18n.path('') })
  },
  async updated() {
    await this.productSkuBySEO()
    // this.$_insiderAPI()
    this.$_insiderTransaction({
      page: {
        type: this.insiderPageType,
        custom: {
          category_name: null,
        },
      },
      transaction: {
        order_id: this.orderDocument.order_id,
        currency: 'THB',
        total: this.orderDocument.grand_total_amt,
        payment_type: this.orderDocument.payment_name_th,
        vouchers: [this.orderDocument.voucher_no],
        voucher_discount: this.orderDocument.voucher_disc_amt_excvat,
        shipping_cost: this.orderDocument.net_deliveryfee_excvat,
        delivery: {
          country: 'TH',
          city: this.orderDocument.ship_province_th,
          district: this.orderDocument.ship_subdistrict_th,
        },
        line_items: this.itemsInsider, //this.orderDocument.sub_orders,
      },
    })
  },
  methods: {
    ...mapActions('order', ['setCurrentTransactionId']),
    displayApproveBadge(status, approval) {
      return status === approval.level_status ? true : false
    },
    async getOrderDocument() {
      await this.$orderProvider
        .getOrderDocument(
          this.$route.params.orderId,
          this.openId,
          this.userProfile.id,
          this.isAdmin
        )
        .then((result) => {
          if (result.status === 200) {
            this.orderDocument = result.data
            console.log('GetDataOrder', this.orderDocument)
            this.getOrderActivityLogs()
            this.summary = {
              net_amt: this.orderDocument.net_amt,
              net_deliveryfee_excvat: this.orderDocument.net_deliveryfee_excvat,
              nonvat_prod_net_amt: this.orderDocument.nonvat_prod_net_amt,
              vat_amt: this.orderDocument.vat_amt,
              vat_prod_net_amt: this.orderDocument.vat_prod_net_amt,
              voucher_disc_amt_excvat:
                this.orderDocument.voucher_disc_amt_excvat,
              voucher_no: this.orderDocument.voucher_no,
              other_disc_amt_incvat: this.orderDocument.other_disc_amt_incvat,
              other_disc_amt: this.orderDocument.other_disc_amt_excvat,
              payment_disc_amt_excvat:
                this.orderDocument.payment_disc_amt_excvat,
              payment_disc_code: this.orderDocument.payment_disc_code,
              is_use_coupon: this.orderDocument.is_use_coupon,
              used_voucher_type: this.orderDocument.used_voucher_type,
              promo_disc_amt_incvat: this.orderDocument.promo_disc_amt_incvat,
              promo_disc_amt_excvat: this.orderDocument.promo_disc_amt_excvat,
            }
            if (this.isEprocuerment) {
              if (this.isUseBudget)
                this.budget = this.orderDocument.budget.period
              this.approval = this.orderDocument.approval_information.approval
            }

            this.orderDocument.sub_orders.forEach((subOrder) => {
              this.productGroups.push({
                soldBy:
                  subOrder.vendor_type === 'OFMStock' ||
                  subOrder.vendor_type.includes('DropShip')
                    ? 'OfficeMate'
                    : `${
                        this.$i18n.locale === 'th'
                          ? subOrder.vendor_name_th
                          : subOrder.vendor_name_en
                      }`,
                FulfilledBy:
                  subOrder.vendor_type === 'OFMStock'
                    ? 'OfficeMate'
                    : `${
                        this.$i18n.locale === 'th'
                          ? subOrder.vendor_name_th
                          : subOrder.vendor_name_en
                      }`,
                items: subOrder.items,
                delivery_lead_time: this.$utils.formatDateExcludeTime(
                  subOrder.delivery_lead_time
                ),
                premiums: [],
                // shipping
                shippingOption: [
                  {
                    type: 'OFFICEMATE SHIPPING',
                    description:
                      'Delivery to your preferred address in 2-5 working days',
                    cost: 0,
                  },
                  {
                    type: 'BANGKOK SAMEDAY',
                    description:
                      'Delivery to your preferred address in 2-5 working days',
                    cost: 80,
                  },
                  {
                    type: 'EXPRESS DELEVERY',
                    description:
                      'Delivery to your preferred address in 2-5 working days',
                    cost: 200,
                  },
                ],
              })
            })
            this.productGroups.map((group) => {
              group.premiums = group.items.filter((q) => q.is_free)
              group.items = group.items.filter((q) => !q.is_free)
              group.items.map((item) => {
                item.display_price_exclude_vat = item.price_disc_excvat
                item.product_name = {
                  th: item.product_name_th,
                  en: item.product_name_en,
                }
              })
              if (group.premiums.length) {
                group.premiums.map((item) => {
                  item.display_price_exclude_vat = item.price_disc_excvat
                  item.product_name = {
                    th: item.product_name_th,
                    en: item.product_name_en,
                  }
                })
              }
            })

            const skus = []
            for (const order of this.orderDocument.sub_orders) {
              for (const sku of order.items) {
                skus.push(sku)
              }
            }
            const productItems = skus.map((sku) => {
              return {
                item_id: sku.sku_code,
                item_name: sku.product_name_th,
                item_brand: sku.brand_name_en,
                item_category: sku.categories[2]?.display_name.en,
                item_category2: sku.categories[1]?.display_name.en,
                item_category3: sku.categories[0]?.display_name.en,
                quantity: sku.quantity,
                price: sku.price_incvat,
                discount: sku.price_disc_incvat - sku.price_incvat,
                ...this.$store.getters['user/userPromotionTrack'],
                item_list_id:
                  (sku.is_flash_sale && 'flash_sale') ||
                  (sku.is_promotion && 'promotions') ||
                  '',
                item_list_name:
                  (sku.is_flash_sale && 'flash sale') ||
                  (sku.is_promotion && 'Promotions') ||
                  '',
              }
            })

            if (
              !this.$utils.isValid(this.currentTransactionId) ||
              this.currentTransactionId !== this.orderDocument.order_id
            ) {
              let productList = []
              this.orderDocument.sub_orders.forEach((subOrder) => {
                subOrder.items.forEach((item) => {
                  console.log('items', item)
                  if (productList.length < 150) {
                    let catLv1 = ''
                    let catLv2 = ''
                    let catLv3 = ''
                    let catIdLv1 = ''
                    let catIdLv2 = ''
                    let catIdLv3 = ''
                    if (this.$utils.isObjectKeyValid(item, 'categories')) {
                      if (item.categories.find((key) => key.level === 1)) {
                        catLv1 = item.categories.find((key) => key.level === 1)
                          .display_name['en']
                        catIdLv1 = item.categories.find(
                          (key) => key.level === 1
                        ).id
                      }
                      if (item.categories.find((key) => key.level === 2)) {
                        catLv2 = item.categories.find((key) => key.level === 2)
                          .display_name['en']
                        catIdLv2 = item.categories.find(
                          (key) => key.level === 2
                        ).id
                      }
                      if (item.categories.find((key) => key.level === 3)) {
                        catLv3 = item.categories.find((key) => key.level === 3)
                          .display_name['en']
                        catIdLv3 = item.categories.find(
                          (key) => key.level === 3
                        ).id
                      }
                    }
                    productList.push({
                      id: item.sku_code,
                      price: this.$utils.formatPrice(
                        item.price_incvat,
                        2,
                        true
                      ),
                      categoryLv1: catLv1,
                      categoryLv2: catLv2,
                      categoryLv3: catLv3,
                      categoryIdLv1: catIdLv1,
                      categoryIdLv2: catIdLv2,
                      categoryIdLv3: catIdLv3,
                      quantity: item.quantity,
                    })
                  }
                })
              })
              //gtm
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'purchase',
                ecommerce: {
                  purchase: {
                    actionField: {
                      id: this.orderDocument.order_id,
                      revenue: this.$utils.formatPrice(
                        this.orderDocument.grand_total_amt,
                        2,
                        true
                      ),
                      tax: this.$utils.formatPrice(
                        this.orderDocument.vat_amt,
                        2,
                        true
                      ),
                      shipping: this.$utils.formatPrice(
                        this.orderDocument.net_deliveryfee,
                        2,
                        true
                      ),
                      coupon: this.orderDocument.voucher_no,
                    },
                    products: productList,
                  },

                  // gtm GA4
                  user: this.$store.getters['user/userInfoTrack'],
                  actionField: {
                    id: this.orderDocument.order_id,
                    payment_option: this.orderDocument.payment_name_th,
                    coupon_discount: this.summary.voucher_disc_amt_excvat,
                    total_price_no_tax: this.summary.nonvat_prod_net_amt,
                    total_price_before_tax: this.summary.vat_prod_net_amt,
                    shipping: this.summary.net_deliveryfee_excvat || 0.0,
                    tax: this.summary.vat_amt,
                    revenue: this.orderDocument.grand_total_amt,
                    total_discount: this.summary.other_disc_amt_incvat,
                    order_status: this.orderDocument.status,
                  },
                  order: {
                    shipping_info: {
                      recipient_name: this.orderDocument.ship_contact,
                      address: `${this.orderDocument.ship_address_1} ${this.orderDocument.ship_address_2} ${this.orderDocument.ship_address_3} ${this.orderDocument.ship_address_4}`,
                      phone: this.orderDocument.ship_phone,
                      mobile: this.orderDocument.ship_mobile,
                    },
                    tax_invoice: {
                      tax_invoice_name: this.orderDocument.invoice_address_1,
                      address: `${this.orderDocument.invoice_address_2} ${this.orderDocument.invoice_address_3} ${this.orderDocument.invoice_address_4}`,
                      tax_id_number: this.orderDocument.account_tax_id,
                      the1_number: this.orderDocument.t1c_no,
                      po_number: this.orderDocument.po_no,
                    },
                  },
                  currency: 'THB',
                  transaction_id: this.orderDocument.order_id,
                  value: this.summary.net_amt + this.summary.vat_amt,
                  coupon: this.orderDocument.voucher_no,
                  shipping: this.orderDocument.net_deliveryfee,
                  tax: this.orderDocument.vat_amt,
                  items: productItems,
                },
              })
              // gtm GA4

              this.setCurrentTransactionId(this.orderDocument.order_id)
              // set insider page
              this.insiderPageType = 'Confirmation'
            } else {
              // set insider page
              this.insiderPageType = 'Other'
              // gtm GA4
              this.$gtm.push({ ecommerce: null })
              this.$gtm.push({
                event: 'purchase',
                ecommerce: {
                  user: this.$store.getters['user/userInfoTrack'],
                  currency: 'THB',
                  value: this.summary.net_amt + this.summary.vat_amt,
                  coupon: this.orderDocument.voucher_no,
                  actionField: {
                    id: this.orderDocument.order_id,
                    payment_option: '',
                    coupon_discount: this.summary.voucher_disc_amt_excvat,
                    total_price_no_tax: this.summary.nonvat_prod_net_amt,
                    total_price_before_tax: this.summary.vat_prod_net_amt,
                    shipping: this.summary.net_deliveryfee_excvat || 0.0,
                    tax: this.summary.vat_amt,
                    revenue: this.orderDocument.grand_total_amt,
                    total_discount: this.summary.other_disc_amt_incvat,
                    order_status: this.orderDocument.status,
                  },
                  order: {
                    customerName: this.orderDocument.contact_name_th,
                    Telephone:
                      this.orderDocument.contact_phone_extension !== ''
                        ? `${this.orderDocument.contact_phone}#${this.orderDocument.contact_phone_extension}`
                        : this.orderDocument.contact_phone,
                    Mobile: this.orderDocument.contact_mobile,
                    OrgName: `[${this.orderDocument.company_code}] ${this.orderDocument.company_name_th}`,
                    Department: this.orderDocument.department_code
                      ? `[${this.orderDocument.department_code}] ${this.orderDocument.department_name_th}`
                      : '-',
                    ShippingAddress: `${this.orderDocument.ship_contact} ${this.orderDocument.ship_address_1} ${this.orderDocument.ship_address_2} ${this.orderDocument.ship_address_3} ${this.orderDocument.ship_address_4} เบอร์โทร ${this.orderDocument.ship_mobile} ${this.orderDocument.ship_phone}`,
                    BillingAddress: `${this.orderDocument.invoice_address_1} ${this.orderDocument.invoice_address_2} ${this.orderDocument.invoice_address_3} ${this.orderDocument.invoice_address_4} เลขประจำตัวผู้เสียภาษี : ${this.orderDocument.account_tax_id}`,
                    PaymentMethod: this.orderDocument.payment_name_th,
                    TransactionID: this.orderDocument.order_id,
                    TransactionDate: this.orderDocument.order_date,
                    ApproveStatus: this.$t(
                      this.$utils.displayOrderStatus(this.orderDocument)[0]
                    ),
                  },
                  items: productItems,
                },
              })
            }
          }
        })
    },
    async getOrderActivityLogs() {
      await this.$orderProvider
        .getOrderActivityLogs(this.$route.params.orderId)
        .then((result) => {
          if (result.status === 200) {
            this.activityLogs = result.data
          } else this.activityLogs = []
        })
    },
    getFile() {
      this.approve.fileName = ''
      document.getElementById('file').value = ''
      document.getElementById('file').click()
    },
    async importFile(e) {
      this.approve.fileName = e.target.files[0].name
      console.log(e.target.files[0])
    },
    onSelectApproverLevel(value) {
      this.approverLevel = value
      if (value === 'level2') {
        this.approverLists = [
          // {
          //   text: 'Nuttapong Kornlerdwanich',
          //   value: 'NuttapongKornlerdwanich',
          // },
          // { text: 'Surapon Kunsri', value: 'SuraponKunsri' },
          // { text: 'Duangnapa Pawale', value: 'DuangnapaPawale' },
          // { text: 'Pragaydaw Chalermboon', value: 'PragaydawChalermboon' },
          // {
          //   text: 'Nhungaruthai Khewsataphon',
          //   value: 'NhungaruthaiKhewsataphon',
          // },
          // { text: 'Ployphailin Wongyala', value: 'PloyphailinWongyala' },
          // { text: 'Palagon Foifu', value: 'PalagonFoifu' },
        ]
      } else {
        this.approverLists = [
          // { text: 'Duangnapa Pawale', value: 'DuangnapaPawale' },
          // { text: 'Pragaydaw Chalermboon', value: 'PragaydawChalermboon' },
          // {
          //   text: 'Nhungaruthai Khewsataphon',
          //   value: 'NhungaruthaiKhewsataphon',
          // },
          // { text: 'Ployphailin Wongyala', value: 'PloyphailinWongyala' },
          // { text: 'Palagon Foifu', value: 'PalagonFoifu' },
          // {
          //   text: 'Nuttapong Kornlerdwanich',
          //   value: 'NuttapongKornlerdwanich',
          // },
          // { text: 'Surapon Kunsri', value: 'SuraponKunsri' },
        ]
      }
      console.log(this.approverLevel)
    },
    onSelectApprover(value) {
      console.log(value)
    },
    onClickViewPOFile(url) {
      if (this.$utils.isValid(url)) {
        window.open(url, '_blank')
      }
    },
    orderDocumentStatus() {
      switch (this.orderDocument.status) {
        case 'Deliverd':
          return 'round-icon-waiting'
        case 'Waiting':
          return 'round-icon-waiting'
        case 'Complete':
          return 'round-icon-primary'
        default:
          return 'round-icon-primary'
      }
    },
    viewOrderDetail(orderId) {
      this.$router.push({
        path: this.$i18n.path('account/order'),
        name:
          this.$i18n.locale === 'th'
            ? 'account-order-id'
            : 'lang-account-order-id',
        params: { id: orderId },
        query: { mode: 'view' },
      })
    },
    async exportPDF(type, language) {
      await this.$orderProvider
        .getOrderDocument(
          this.$route.params.orderId,
          this.openId,
          this.userProfile.id,
          this.isAdmin,
          type === 'purchase' ? true : false
        )
        .then((result) => {
          if (result.status === 200) {
            if (
              result.data !== null &&
              result.data !== undefined &&
              Object.keys(result.data).length
            )
              exportOrderDocument(result.data, language, type)
          }
        })
    },
    async exportExcel() {
      exportExcelOrderDocument(this.orderDocument)
    },
    async productSkuBySEO() {
      let products = []
      this.orderDocument.sub_orders.forEach((orderItems) => {
        orderItems.items.forEach((items) => {
          products.push(items)
        })
      })
      this.itemsInsider = products
      // let skuQty = []
      // let skuCode = []
      // this.orderDocument.sub_orders.forEach((subOrder) => {
      //   subOrder.items.forEach((item) => {
      //     skuCode.push(item.sku_code)
      //     skuQty.push({
      //       sku_code: item.sku_code,
      //       qty: item.quantity,
      //     })
      //   })
      // })

      // let params = {
      //   skus: skuCode,
      //   is_admin_only: true,
      // }
      // await this.$productProvider.productSkusbulkSearch(params).then(
      //   (result) => {
      //     if (result.status === 201) {
      //       console.log('result.data', this.orderDocument.sub_orders)
      //       result.data.skus.forEach((sku) => {
      //         sku.qty =
      //           skuQty[skuQty.findIndex((q) => q.sku_code === sku.sku_code)].qty
      //       })
      //       this.itemsInsider = result.data.skus
      //     }
      //   },
      //   (error) => {
      //     console.log('Error ProductSkuBySEO:', error)
      //   }
      // )
    },
  },
}
