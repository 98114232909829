
import InputText from '@/components/share/input-text.vue'
import modalWarning from '@/components/modal/modalWarning.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  layout: 'blank-nav',
  name: 'Register',
  components: {
    'input-text': InputText,
    'modal-warning': modalWarning,
  },
  data() {
    return {
      reloadVerify: true,
      dataForOTP: {
        otp: '',
        openId: '',
      },
      countDownOTP: 60,
      modalWarning: false,
      warningMessages: [],
    }
  },
  computed: {
    ...mapGetters('authen', [
      'accessToken',
      'openId',
      'isAuthenticated',
      'isGuest',
    ]),
    ...mapGetters(['routeHistory']),
    getEmail() {
      let msg = this.$t('verify.label2')
      if (this.$utils.isValid(this.accessToken)) {
        return msg.replace('{email}', this.accessToken.email)
      } else return msg.replace('{email}', '')
    },
    isUser() {
      return this.isAuthenticated && !this.isGuest
    },
  },
  watch: {
    countDownOTP: function (val) {
      if (val > 0) {
        setTimeout(() => {
          this.countDownOTP -= 1
        }, 1000)
      } else {
        console.log('time out')
      }
    },
  },
  async mounted() {
    console.log(this.$route.query.code)
    if (
      this.$utils.isValid(this.$route.query) &&
      this.$utils.isValid(this.$route.query.code)
    ) {
      // await this.verifyUser(this.$route.query.code)
      let sp = this.$route.query.code.split('_')

      this.dataForOTP.openId = sp[0]
      this.dataForOTP.otp = sp[1]
    }
  },
  methods: {
    ...mapActions('authen', ['setAuthToken', 'signout']),
    ...mapActions('cart', ['setCartId']),
    ...mapActions('user', [
      'setUserProfile',
      'setUserInfo',
      'removeUserProfile',
    ]),
    async onSubmit() {
      this.$refs.otpVerifyForm.validate().then(async (result) => {
        if (result)
          await this.verifyUser(this.dataForOTP.openId, this.dataForOTP.otp)
      })
    },
    async verifyUser(open_id, code) {
      const result = await this.$authenProvider.verify(open_id, code)
      if (result.status === 204) {
        if (this.isUser) {
          this.getUserData()
        } else {
          this.signout()
          this.removeUserProfile()
          this.$router.push({ path: this.$i18n.path('login') })
        }
      } else if (result.status === 404) {
        this.warningMessages = []
        this.warningMessages.push({
          message: {
            th: `ไม่พบ OTP Code: ${this.dataForOTP.otp}`,
            en: `OTP Code: ${this.dataForOTP.otp} Not Found`,
          },
        })
        this.modalWarning = true
      } else {
        this.warningMessages = []
        this.warningMessages.push({
          message: {
            th: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
            en: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
          },
        })
        this.modalWarning = true
      }
    },
    async resendOTP() {
      let apiData = JSON.stringify({
        data_value: this.accessToken.email,
        data_type: 'email',
        chanel: 'Email',
      })
      const result = await this.$authenProvider.requestOneTimePasswordVerify(
        apiData
      )
      if (result.status === 204) {
        this.$bvToast.toast('กรุณาตรวจสอบอีเมลของคุณ', {
          title: `Resend OTP Success`,
          variant: 'success',
          solid: true,
        })
      }
    },
    async getUserData() {
      await this.$profileProvider
        .getUserDataById(this.openId)
        .then((result) => {
          console.log('result', result)
          if (result.status === 200) {
            let userData = result.data
            // console.log('this.userProfile', this.userProfile)
            if (userData.accounts.length || userData.companies.length) {
              this.$bvToast.hide()
              if (
                userData.accounts.length === 1 &&
                userData.companies.length === 0
              ) {
                // use account
                this.setUserProfile({
                  payload: userData.accounts[0],
                  profileType: 'standard',
                })
                this.updateLastActive(
                  this.openId,
                  userData.accounts[0].id,
                  'standard'
                )

                let tempUserData = Object.assign({}, result.data)
                delete tempUserData['accounts']
                delete tempUserData['companies']
                delete tempUserData['create_info']
                delete tempUserData['update_info']
                this.setUserInfo({ payload: tempUserData })
                // braze
                this.$setBrazeUser(
                  this.$store.getters['user/userInfo'],
                  this.$store.getters['user/profileType'],
                  this.$store.getters['user/userProfile']
                )
                if (this.$utils.isValid(this.routeHistory)) {
                  this.$router.push({
                    path: this.routeHistory,
                  })
                } else {
                  this.$router.push({ path: this.$i18n.path('') })
                  // this.$router.push({
                  //   path: `${
                  //     userData.default_language === 'th' ? '/' : '/en/'
                  //   }`,
                  // })
                }
              } else {
                //select account || company
                this.$router.push({
                  path: `${
                    userData.default_language === 'th'
                      ? '/account/select-company'
                      : '/en/account/select-company'
                  }`,
                })
              }
              console.log(
                'userInfoTrack',
                this.$store.getters['user/userInfoTrack']
              )
              console.log('userInfo', this.$store.getters['user/userInfo'])
              // gtm register
              this.$gtm.push({
                event: 'register',
                ...this.$store.getters['user/userInfoTrack'],
              })
            }
          } else {
            this.warningMessages = []
            this.warningMessages.push({
              message: {
                th: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
                en: 'ระบบเกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่',
              },
            })
            this.modalWarning = true
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    async updateLastActive(openId, profileId, accountChannel) {
      let apiData = JSON.stringify({
        profile_id: profileId,
        account_channel: accountChannel,
      })
      await this.$profileProvider.updateUserLastActive(openId, apiData)
    },
    backToHome() {
      this.signout()
      this.removeUserProfile()
      // this.$router.push({ path: this.$i18n.path('biz') })
      this.$router.push(this.$i18n.path(''))
    },
  },
}
