import { render, staticRenderFns } from "./index.vue?vue&type=template&id=01b958e6"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SwiperImage: require('/usr/src/nuxt-app/components/share/swiper-image.vue').default,Countdown: require('/usr/src/nuxt-app/components/flashsale/countdown.vue').default,FilterPanel: require('/usr/src/nuxt-app/components/template/filter-panel.vue').default,FilterController: require('/usr/src/nuxt-app/components/share/filter-controller.vue').default,ProductResult: require('/usr/src/nuxt-app/components/skeleton/product-result.vue').default,ProductPagination: require('/usr/src/nuxt-app/components/template/product-pagination.vue').default,SwiperItem: require('/usr/src/nuxt-app/components/share/swiper-item.vue').default,Notfound: require('/usr/src/nuxt-app/components/share/notfound.vue').default})
